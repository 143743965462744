import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as THREE from "three"

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';





import {
  CSS2DRenderer,
  CSS2DObject
} from 'three/examples/jsm/renderers/CSS2DRenderer'

import { ModalController } from '@ionic/angular';
import { IFoto } from '../blueprint/blueprint.component';


interface Annotation {
  title: string
  description: string
  descriptionDomElement?: HTMLElement
}

@Component({
  selector: 'app-blueprint-3d',
  templateUrl: './blueprint-3d.component.html',
  styleUrls: ['./blueprint-3d.component.scss']
})
export class Blueprint3dComponent implements OnInit, AfterViewInit {

  pointerDown = false

  modalOpen = false
  modalData


  @Input() public objUrl: string = ''
  @Input() public showCustomGrid = false
  @Input() public showGrid = false
  @Input() public showAxes = false

  @Input() public damages: IDamage3d[]

  //@Output() damagesChange: EventEmitter<any> = new EventEmitter();

  loading = 0
  rendered = false

  annotationCounter = 0
  annotations: { [key: string]: Annotation } = {}
  annotationMarkers: THREE.Sprite[] = []

  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();

  item: IDamage3d = {
    severity: 'G',
    type: '',
    x: 0, y: 0,
    intersect: { face: '', point: '' },
    objectName: ''
  }


  @Input() public cameraZ: number = 0
  @Input() public fieldOfView: number = 10

  @Input('nearClipping') public nearClippingPlane: number = 1
  @Input('farClipping') public farClippingPlane: number = 65000



  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();

  labelRenderer = new CSS2DRenderer()


  circleTexture_g = new THREE.TextureLoader().load('./assets/imgs/circle_g.png')
  circleTexture_y = new THREE.TextureLoader().load('./assets/imgs/circle_y.png')
  circleTexture_r = new THREE.TextureLoader().load('./assets/imgs/circle_r.png')
  circleTexture_s = new THREE.TextureLoader().load('./assets/imgs/circle_s.png')

  camera!: THREE.PerspectiveCamera

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement
  }

  public object!: THREE.Group | THREE.Object3D
  private renderer!: THREE.WebGLRenderer

  public scene!: THREE.Scene

  rootObjectNames = [

    'FANALI_ANT',
    'FANALI_POST',
    'CERCHI',
    'CARROZZERIA',
    'CRISTALLI',
    'SPECCHI_ESTERNI',
    'ELEMENTI_PLASTICI',
    'COVER_MONTANTI',
    'ALTRO',
    'SOTTOSCOCCA',
    'ELEMENTI_CROMATI',
    'CONRICI_CRISTALLI',
    //'_5_shield_rearbump',
    'GRIGLIA_ANTERIORE',
    'CARENE',
    'TARGA',
    'DISCHI_FRENO',
    'PNEUMATICI',

  ]

  @ViewChild('canvas', { static: false }) private canvasRef!: ElementRef;

  constructor(private modalController: ModalController) {



  }

  ngOnInit(): void {
  }

  addGrid() {
    if (!this.showGrid) return
    //return
    const gridHelper = new THREE.GridHelper(1000, 100, 0xff0000, 0x0000ff);
    this.scene.add(gridHelper);
  }

  addLights() {

    let ambient = new THREE.AmbientLight(0x999999, 1);
    this.scene.add(ambient);

    var directionalLight = new THREE.DirectionalLight(0xffeedd, 1);
    directionalLight.position.set(1, 1, 1).normalize();
    this.scene.add(directionalLight);

  }

  private createScene() {
    this.scene = new THREE.Scene()
    this.scene.background = null

    this.addLights()
    this.addGrid()

    this.load()

    //this.loadMTL()

    let aspectRatio = this.getAspectRatio()

    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    )

  }

  load() {
    this.loadGLTF()
  }


  getObj(obj) {
    //console.clear()

    obj.children = obj.children.filter(e => e.type == 'Object3D' || e.type == 'Mesh')

    console.log('getObj', obj.name)

    let b: boolean = obj.children.length > 1 || obj.children.length == 0

    if (b) {
      return obj
    }
    else
      return this.getObj(obj.children[0])

  }

  loadGLTF() {

    const loader = new GLTFLoader()

    loader.load(this.objUrl, (gltf) => {

      //console.clear()
      let tmp = gltf.scene.children[0]
      this.object = this.getObj(tmp)

      console.log(this.object)

      //this.object.scale.set(300, 300, 300)

      //this.object.rotateX(180)
      this.scene.add(this.object)

      this.setObjectDefaults()
      this.setCameraDefaults()

      this.setCustomGrid()
      this.setAxis()

      this.loadData()

      console.log('added')
      this.rendered = true

    },
      (xhr) => {
        this.loading = (xhr.loaded / xhr.total) * 100
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
        console.log(error)
      });
  }

  setOrbitalControls() {

    if (!this.object) {
      setTimeout(() => {
        this.setOrbitalControls()
      }, 300);
      return
    }


    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.addEventListener('change', (res) => {
     // console.log('object', this.object)
     // console.log('camera', this.camera)
    })

  }

  setLabelRenderer() {

    this.labelRenderer.setSize(this.canvas.width, this.canvas.height)
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = this.canvas.offsetTop + 'px'
    this.labelRenderer.domElement.style.left = this.canvas.offsetLeft + 'px'
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    //this.labelRenderer.domElement.style.backgroundColor = '#ff000077'

    document.body.appendChild(this.labelRenderer.domElement)
  }

  setObjectDefaults() {

    let def = getDefault(this.objUrl).object

    this.object.rotateX(def.rotateX)
    this.object.rotateY(def.rotateY)
    this.object.rotateZ(def.rotateZ)

    this.object.position.x -= def.positionX
    this.object.position.y -= def.positionY
    this.object.position.y -= def.positionZ


    this.setReflection()

  }


  setCameraDefaults() {

    let def = getDefault(this.objUrl).camera

    this.camera.position.x = def.positionX
    this.camera.position.y = def.positionY + 300
    this.camera.position.z = def.positionZ

    let pos = this.object.position.clone()


    this.camera.lookAt(pos)

  }


  setCustomGrid() {

    if (!this.showCustomGrid) return

    //console.clear()
    let bbox = new THREE.Box3().setFromObject(this.object);


    console.log(bbox)

    let stepx = (bbox.max.x - bbox.min.x) / 3
    let stepy = (bbox.max.y - bbox.min.y) / 2
    let stepz = (bbox.max.z - bbox.min.z) / 4

    for (let x = bbox.min.x; x <= bbox.max.x; x += stepx) {

      for (let y = bbox.min.y; y <= bbox.max.y; y += stepy) {

        for (let z = bbox.min.z; z <= bbox.max.z; z += stepz) {

          const geometry = new THREE.BoxGeometry(stepx, stepy, stepz,);
          const edges = new THREE.EdgesGeometry(geometry)
          const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0xffffff }));


          line.position.x = x
          line.position.y = y
          line.position.z = z

          this.scene.add(line);



        }
      }
    }



  }



  setAxis() {

    if (!this.showAxes) return

    const axesHelper = new THREE.AxesHelper(150);
    this.scene.add(axesHelper);

  }

  getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    //console.clear()
    //console.log('resize')
    this.camera.aspect = this.getAspectRatio()
    //this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
    this.renderer.render(this.scene, this.camera)
    this.setLabelRenderer()
  }


  private startRenderingLoop() {
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true })
    this.renderer.setPixelRatio(devicePixelRatio)
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
    this.renderer.outputEncoding = THREE.sRGBEncoding;


    this.setLabelRenderer()
    this.setOrbitalControls()

    const render = () => {
      requestAnimationFrame(render);
      //component.animateCube()
      this.renderer.render(this.scene, this.camera)
      this.labelRenderer.render(this.scene, this.camera)

    }

    console.log(this.scene)

    this.scene.traverse((object) => {

      console.log(object)

    });

    render()


  }

  ngAfterViewInit(): void {


    //console.clear()
    console.log('ngAfterViewInit')

    setTimeout(() => {

      this.createScene()
      this.startRenderingLoop()

    }, 1000);

  }

  loadData() {

    this.loadMarkers()
  }


  loadMarkers() {
    this.damages.forEach(d => {
      this.addMarker(d)
    });
  }

  damageID
  async openDialog(intersect, damageID?: number) {

    let d

    if (damageID != undefined)
      d = this.damages[damageID]

    console.log(damageID, d)
    this.modalData = {
      //object: damageID != undefined ? d.object : object,
      intersect: damageID != undefined ? d.intersect : intersect,
      damage: damageID != undefined ? d : undefined
    }
    this.damageID = damageID
    this.modalOpen = true
    if (this.modalData.damage)
      this.item = this.modalData.damage
    else {
      this.item = {
        severity: 'G',
        type: '',
        x: 0, y: 0,
        intersect: { face: this.modalData.intersect.face, point: this.modalData.intersect.point },
        //object: this.modalData.object,
        //objectName: this.modalData.object.name
      }
    }
    this.presentModal()

  }

  async presentModal() {
    //console.clear()
    console.log(this.item)
    const modal = await this.modalController.create({
      component: Blueprint3dModalPage,
      cssClass: 'my-custom-class',
      componentProps: { item: this.item }

    });

    modal.onDidDismiss().then(d => { if (d) this.ok(d) })

    return await modal.present();
  }

  ok(d) {
    if (!this.item) return

    console.log(`Dialog result`, d);

    this.lastObjHoverId = -1
    this.lastObjName = ''
    if (this.damageID) {
      //this.damages[this.damageID] = d
    } else {
      this.addMarker(this.item)
      //this.damages.push(this.item)
    }


    this.onNew.emit(this.item)
    this.modalOpen = false

  }

  close() {
    this.modalOpen = false
  }


  //// DL CLICK EMULATION
  ts = 0
  waitingDC = false
  onPointerDown(event) {


    this.pointerDown = true

    let ts = new Date().getMilliseconds();


    if (ts - this.ts < 180 && this.waitingDC) {

      this.waitingDC = false
      this.onDoubleClick(event)

    } else {

      this.waitingDC = true

      setTimeout(() => {
        if (this.waitingDC) {
          this.onClick(event)
          this.waitingDC = false
        }
      }, 200);

    }


    this.ts = ts

  }

  onDoubleClick(evt: MouseEvent) {

    console.log('onDoubleClick')

    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((evt.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    this.mouse.y = - ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObject(this.object)


    if (intersects.length > 0) {

      //intersects[0].object.materials[0].color.setHex(Math.random() * 0xffffff);

      //let uuid = intersects[0].object.uuid
      //console.log('uuid', uuid)

      let id = this.getGroup(intersects[0].object.id)


      //let name = this.scene.getObjectById(id)!.name;
      //let name = intersects[0].object.name

      let object = this.scene.getObjectById(id);

      // console.clear()
      console.log(object)

      object = this.scene.getObjectByName(object!.name);

      let name = object!.name
      console.log('name', name)
      console.log('uuid', object)

      //const material = new THREE.MeshStandardMaterial({ color: 0xff0000 });
      this.openDialog(intersects[0])






    } else {
      console.log('no intersects')
    }


  }

  clearMarkers() {
    console.clear()

    this.scene.traverse((object) => {

      if (object instanceof THREE.Sprite) {
        console.log(object)
        setTimeout(() => {
          object.removeFromParent()
        }, 10);
      }

    });

    setTimeout(() => {
      this.loadMarkers()
    }, 100);



  }

  addMarker(d: IDamage3d) {

    const n = new THREE.Vector3()
    n.copy((d.intersect.face as THREE.Face).normal)
    n.transformDirection(this.object.matrixWorld)

    const aId = this.annotationMarkers.length + 1

    let circleTexture
    switch (d.severity) {
      case 'L': circleTexture = this.circleTexture_g; break
      case 'M': circleTexture = this.circleTexture_y; break
      case 'G': circleTexture = this.circleTexture_r; break
      case 'S': circleTexture = this.circleTexture_s; break
    }

    const annotationSpriteMaterial = new THREE.SpriteMaterial({
      map: circleTexture,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false
    })
    const annotationSprite = new THREE.Sprite(
      annotationSpriteMaterial
    )

    annotationSprite.userData.id = aId
    this.annotationMarkers.push(annotationSprite)

    annotationSprite.scale.set(0.01, 0.01, 0.01)
    annotationSprite.lookAt(n)
    annotationSprite.position.copy(d.intersect.point)

    this.scene.add(annotationSprite)


    const annotationDiv = document.createElement('div')
    annotationDiv.className = 'annotationLabel'
    annotationDiv.innerHTML = '' + aId

    const annotationLabel = new CSS2DObject(annotationDiv)
    annotationLabel.scale.set(0.03, 0.03, 0.03)
    annotationLabel.lookAt(n)
    annotationLabel.position.copy(d.intersect.point)
    // this.scene.add(annotationLabel)

    let annotation: Annotation = { title: 'Danno ' + aId, description: 'Descrizione' }
    const annotationTextDiv = document.createElement('div')
    annotationTextDiv.className = 'annotationDescription'
    annotationTextDiv.innerHTML = annotation.title
    annotationTextDiv.innerHTML += `
    
    <p>Entità: <span class="${d.severity}">${d.severity}</span></p>
    <p>Tipo: ${d.type}</p>    
    `
    annotationDiv.appendChild(annotationTextDiv)
    this.annotations[aId] = annotation
    this.annotations[aId].descriptionDomElement = annotationTextDiv




  }






  lastObjHoverId = -1
  lastMaterial
  lastObjName = ''

  onMouseMove(evt: MouseEvent) {




  }

  onClick(evt: MouseEvent) {

    console.log('onClick')



    //this.mouse.x = (evt.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
    //this.mouse.y = - (evt.clientY / this.renderer.domElement.clientHeight) * 2 + 1;

    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((evt.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    this.mouse.y = - ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;



    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.annotationMarkers)



    this.hideAllAnnotations()
    if (intersects.length > 0 && intersects[0].object.userData && intersects[0].object.userData.id) {

      let i = +intersects[0].object.userData.id - 1

      let d = this.damages[i]

      console.log(d, i)

      this.openDialog(d.intersect, i)
      ///this.showAnnotation(intersects[0].object.userData.id)
    }



  }

  onPointerUp(event) {
    this.pointerDown = false
  }

  showAnnotation(id: any) {
    if (this.annotations[id].descriptionDomElement)
      (this.annotations[id].descriptionDomElement as HTMLDivElement).style.display = 'block'
  }

  hideAllAnnotations() {
    Object.keys(this.annotations).forEach((annotation) => {
      if (this.annotations[annotation].descriptionDomElement)
        (this.annotations[annotation].descriptionDomElement as HTMLDivElement).style.display = 'none'
    })
  }



  getGroup(id) {
    return id
  }

  setReflection() {


    let material = new THREE.MeshPhongMaterial({
      color: 0x909090,
      //roughness: 0.1,
      //metalness: 0.9,

      emissive: 0x000000,
      specular: 0x111111,
      shininess: 100,

      reflectivity: 1,
      refractionRatio: 0.98,

      side: THREE.FrontSide,
    })


    this.object.material = material



  }



  undo() {
    this.clearMarkers()
    this.delete(this.damages.length - 1)
  }

  delete(i) {
    if (!confirm('Sei sicuro di voler eliminare il danno?')) return
    this.onDelete.emit(i)

    this.damages.splice(i)

  }



}



const getDamageTypes = [
  {
    "code": "M",
    "zone": "Esterni",
    "description": "Danneggiato"
  },
  {
    "code": "R",
    "zone": "Esterni",
    "description": "Rotto"
  },
]



export interface IDamage3d {
  x?: number;
  y?: number;

  intersect?: { face: any, point: any }
  //object?: any
  objectName?: string

  severity: string;
  type: string;

  img1?: IFoto;
  img2?: IFoto;
  img_rip?: IFoto;

  color?: string;
  //part?: any;

  cost?: any

  extra?: IExtra[]
}

export interface IExtra {

  descr: string
  img1?: IFoto;
  img2?: IFoto;
  img_rip?: IFoto;

}

@Component({
  selector: 'blueprint-3d-modal-page',
  template: `<div>
<br>
  <h3 style="text-align:center">Inserimento danno</h3>
  <ion-card-title *ngIf="modalData?.object">Parte danneggiata: {{modalData.object.name}}</ion-card-title>

  <ion-card-content>

    <div class="row"  *ngIf="false">
      <div class="col">
        <p>Seleziona la gravità del danno</p>
      </div>
    </div>

    <div class="row full" *ngIf="false">
      <div class="col-6" *ngFor="let e of severities" style="margin-bottom:8px;">

        <button class="full" type="button" mat-button (click)="item.severity = e.code"
          [ngStyle]="{ 'background-color': e.color, 'border': (item.severity == e.code ? '1px solid black' : ''), 'font-weight': (item.severity == e.code ? 'bold' : '') }">

          {{e.description}}

        </button>

      </div>
    </div>



    <div class="row">
      <div class="col">
        <p>&nbsp;</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>Seleziona il tipo di danno</p>
      </div>
    </div>

    <div class="row full">
      <div class="col-6" *ngFor="let e of types" style="margin-bottom:8px;">
        <button class="full" type="button" mat-button (click)="item.type = e.code"
          [ngStyle]="{'border': (item.type == e.code ? '1px solid black' : ''), 'font-weight': (item.type == e.code ? 'bold' : '') }">{{e.description}}</button>
      </div>
    </div>


    <div >
      <button style="margin:10px" type="button" mat-flat-button color="primary"
        [disabled]=" item.type==''" (click)="ok()">Ok</button>
      <button style="margin:10px" type="button" mat-flat-button color="warn" (click)="close()">Annulla</button>
    </div>

  </ion-card-content>


</div>`,
  styles: [`button {
  width:100%;
  height:100%;
  overflow:hidden;
  padding: 10px;
  border-radius: 10px;
}
`]
})
export class Blueprint3dModalPage {



  @Input() item;
  modalData
  types = getDamageTypes.filter(e => e.zone == 'Esterni')
  severities = [
    { code: 'L', description: 'Lieve', color: '#aaffaa' },
    { code: 'M', description: 'Medio', color: '#ffffaa' },
    { code: 'G', description: 'Grave', color: '#ffaaaa' },
    { code: 'S', description: 'Sostituzione', color: '#aaaaff' },
  ]

  constructor(private modalController: ModalController) {

  }

  ok() {
    this.modalController.dismiss(this.item)
  }

  close() {
    this.modalController.dismiss()
  }
}


const getDefault = (objUrl) => {
  let tmp = DEFAULTS.find(e => e.objUrl == objUrl)

  console.log('DEFAULTS',tmp)

  return tmp ? tmp : DEFAULTS[0]
}

const _1DEG = 0.0174533
const _1RAD = 57.29578
const STEP = 45
const G = 9.81

const DEFAULTS = [
  {
    objUrl: '',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    }
  },


  {
    objUrl: '/assets/models/cars/audi.glb',
    object: {
      rotateX: 0.1776256486,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0.1776256486,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: -1,
      positionZ: 63,

    }
  },


  {
    objUrl: '/assets/models/boats/boat1.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: -30 * _1DEG + 0,

      positionX: 0,
      positionY: 100,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: -40,
      rotateZ: 0,

      positionX: 0,
      positionY: 400,
      positionZ: 3600,
    }
  },
  {
    objUrl: '/assets/models/boats/boat2.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: -40,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 60,
    }
  },
  {
    objUrl: '/assets/models/boats/boat3.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: _1DEG * -30 + 0,

      positionX: 0,
      positionY: 500,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 150,
      positionZ: 4000,
    }
  },
  {
    objUrl: '/assets/models/boats/boat4.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: _1DEG * 90 + 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 200,
      positionZ: 4000,
    }
  },


  {
    objUrl: '/assets/models/boats/boat5.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    }
  },

  {
    objUrl: '/assets/models/boats/boat6.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: _1DEG * 90,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 100,
      positionZ: 4000,
    }
  },

  {
    objUrl: '/assets/models/boats/boat7.glb',
    object: {
      rotateX: 0,
      rotateY: _1DEG * 5 + 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 0,
      positionZ: 1200,

      rotationAxe: 'y',
      sign: -1
    }
  },
]
