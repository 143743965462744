import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginRouteGuard, SelectorGuard } from './route-guard';
import { AngularFireModule, FirebaseOptions } from '@angular/fire';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreModule } from 'angularfire2/firestore';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { PreviewService } from './services/preview.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { MysqlService } from './services/mysql.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared.module';
import { Stripe } from '@ionic-native/stripe/ngx'
import { DurationPipe } from './pipes/duration.pipe';
import { DannoCodeModal } from './components/blueprint/blueprint.component';

import { IonicStorageModule } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
//import { Facebook } from '@ionic-native/facebook/ngx';

import { FacebookModule } from 'ngx-facebook';
import { Globalization } from '@ionic-native/globalization/ngx';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

const config: FirebaseOptions = {
  apiKey: "AIzaSyDjuqb4XGcUGakiq9K_ck1gUX1Rc3ga9WE",
  authDomain: "verycar-4e643.firebaseapp.com",
  databaseURL: "https://verycar-4e643.firebaseio.com",
  projectId: "verycar-4e643",
  storageBucket: "verycar-4e643.appspot.com",
  messagingSenderId: "774903361677",

  ignoreUndefinedProperties: true
};



@NgModule({
  declarations: [
    AppComponent, DurationPipe,
    DannoCodeModal,
  ],
  entryComponents: [DannoCodeModal],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    IonicStorageModule.forRoot({
      driverOrder: ['sqlite', 'indexeddb', 'websql'],
    }),
    FacebookModule.forRoot(),
    // MatTabsModule,
    AngularFireModule.initializeApp(config),
    AngularFireFunctionsModule, 
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    SharedModule,
 
    

  ],
  providers: [
    StatusBar,
    Stripe,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoginRouteGuard,
    SelectorGuard,
    PreviewService,
    DannoCodeModal,
    ScreenOrientation, MysqlService, Geolocation,
    NativeGeocoder,
    Globalization,
    AngularFireStorage,
    SplashScreen

  ],
  exports: [
    DurationPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private fb: AngularFirestore) {

  }

}
