import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Blueprint3dComponent } from './components/blueprint-3d/blueprint-3d.component';
import { BlueprintHomeComponent } from './components/blueprint_home/blueprint.component';
import { MapComponent } from './components/map/map.component';
import { ReactionsComponent } from './components/reactions/reactions.component';
import { ShieldResultComponent } from './components/shield-result/shield-result.component';
import { ImageZoomPage, SliedeshowComponent } from './components/sliedeshow/sliedeshow.component';
import { StripeAbbonamentoModal } from './pages/profile/profile.page';
import { PopupQuestions, SegnalazioneAnniuncioModalComponent } from './pages/_automotive/detail/detail.page';
import { FirbaseToDatePipe } from './pipes/firebaseToDate.pipe';
import { PresentImagePipe } from './pipes/present-image.pipe';
import { TranslationPipe } from './services/locales.service';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
    ],
    declarations: [
        FirbaseToDatePipe,
        TranslationPipe,
        ShieldResultComponent,
        StripeAbbonamentoModal,
        ReactionsComponent,
        PresentImagePipe,
        SliedeshowComponent,
        PopupQuestions,
        SegnalazioneAnniuncioModalComponent,
        BlueprintHomeComponent,
        Blueprint3dComponent,
        ImageZoomPage,
        MapComponent,

    ],
    exports: [
        CommonModule,
        FormsModule,
        DatePipe,
        FirbaseToDatePipe,
        TranslationPipe,
        ShieldResultComponent,
        PresentImagePipe,
        SliedeshowComponent,
        BlueprintHomeComponent,
        Blueprint3dComponent,
        PopupQuestions,
        SegnalazioneAnniuncioModalComponent,
        ReactionsComponent,
        ImageZoomPage,
        MapComponent,
    ],
    entryComponents:[
        SegnalazioneAnniuncioModalComponent
    ]
})

export class SharedModule { }