import { IDamage } from './components/blueprint/blueprint.component';
import { ITiresData } from './components/tires/tires.component';

export interface IQuestion {
  sectionCode: string;
  sectionDescription: string;
  questionId: number;
  questionName: string;
  questionDescr: string;
  Visiva?: string;
  Funzionale?: string;
  Strumentale?: string;
  Elettronica?: string;
  Economics0?: any;
  Economics25?: any;
  Bloccante?: string;
  Guasti12mesi?: any;
  Guasti24mesi?: any;
  val5?: number;
  val4?: number;
  val3?: number;
  val2?: number;
  val1?: number;
  text5?: string;
  text4?: string;
  text3?: string;
  text2?: string;
  text1?: string;

  note_self?: string;
  note_inspect?: string;
  note_int?: string;

  nonApplicabile?: boolean;
  page?: number;
  blueprint?: boolean;
  blueprint_pag?: number;
  tires?: boolean;
  tiresData?: ITiresData[]

  damages?: IDamage[];

  response?: number;
  userNote?: string;

  guasti12?: number;
  guasti24?: number;
  Cristalli?: number;
  Kasko?: number;

  Order?: number;

  photoRequired?: boolean;

  deleted?: boolean;

  hint?: string

}


export const verycar_prices = {
  full: 160,
  inspect: 80,
  self: 5,
  doc: 3.99
  //DA MODIFICARE
}

interface IHint {
  questionId: number;
  hint: string;
}






export const getElements = () => {
  let tmp = [];

  ZONE_ELEMENTS.forEach(e => {
    if (e.description)
      tmp.push({ code: e.code, description: e.description })
  });

  return tmp;
}

export const ZONE_ELEMENTS = [

  ///// SUV
  {
    "code": "1",
    "description": "Paraurti ANT",
    "vehType": "suv",
    "sostituzione": "674.1866667",
    "lieve": "38.43333333",
    "medio": "65.93333333",
    "grave": "93.93333333",
    "#3828db": "1"
  },
  {
    "code": "2",
    "description": "Cofano",
    "vehType": "suv",
    "sostituzione": "713.0433333",
    "lieve": "63.5",
    "medio": "135.3",
    "grave": "138.5333333",
    "#3828db": "1"
  },
  {
    "code": "3",
    "description": "Parafango ANT SX",
    "vehType": "suv",
    "sostituzione": "454.8533333",
    "lieve": "65.86666667",
    "medio": "140.4333333",
    "grave": "196.9",
    "#ee1313": "1"
  },
  {
    "code": "4",
    "description": "Parafango ANT DX",
    "vehType": "suv",
    "sostituzione": "454.8533333",
    "lieve": "65.86666667",
    "medio": "140.4333333",
    "grave": "196.9",
    "#26f719": "1"
  },
  {
    "code": "5",
    "description": "Tetto",
    "vehType": "suv",
    "sostituzione": "1456.17",
    "lieve": "125.5666667",
    "medio": "297.0333333",
    "grave": "497.1666667",
    "#b1a558": "1"
  },
  {
    "code": "6",
    "description": "Porta ANT SX",
    "vehType": "suv",
    "sostituzione": "955.1933333",
    "lieve": "81.63333333",
    "medio": "184.3666667",
    "grave": "266.0666667",
    "#db6928": "1"
  },
  {
    "code": "7",
    "description": "Porta ANT DX",
    "vehType": "suv",
    "sostituzione": "955.1933333",
    "lieve": "81.63333333",
    "medio": "184.3666667",
    "grave": "266.0666667",
    "#3e6928": "1"
  },
  {
    "code": "8",
    "description": "Porta POST SX",
    "vehType": "suv",
    "sostituzione": "1011.72",
    "lieve": "77.8",
    "medio": "174",
    "grave": "250.8333333",
    "#db6928": "1"
  },
  {
    "code": "9",
    "description": "Porta POST DX",
    "vehType": "suv",
    "sostituzione": "1011.72",
    "lieve": "77.8",
    "medio": "174",
    "grave": "250.8333333",
    "#3e6928": "1"
  },
  {
    "code": "10",
    "description": "Lunotto",
    "vehType": "suv",
    "sostituzione": "482.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "11",
    "description": "Parafango POST SX",
    "vehType": "suv",
    "sostituzione": "1045.423333",
    "lieve": "104.5333333",
    "medio": "244.8666667",
    "grave": "414.5666667",
    "#af28db": "1"
  },
  {
    "code": "12",
    "description": "Parafango POST DX",
    "vehType": "suv",
    "sostituzione": "1045.423333",
    "lieve": "104.5333333",
    "medio": "244.8666667",
    "grave": "414.5666667",
    "#87a5f0": "1"
  },
  {
    "code": "1010",
    "description": "Portellone POST",
    "vehType": "suv",
    "sostituzione": "1313.45",
    "lieve": "75.73333333",
    "medio": "168.4333333",
    "grave": "239.4666667",
    "#eaf910": "1"
  },
  {
    "code": "13",
    "description": "Paraurti POST",
    "vehType": "suv",
    "sostituzione": "599.87",
    "lieve": "18.1",
    "medio": "32.9",
    "grave": "50.56666667",
    "#eaf910": "1"
  },
  {
    "code": "14",
    "description": "Parabrezza",
    "vehType": "suv",
    "sostituzione": "562.4826667",
    "lieve": "",
    "medio": "58",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "16",
    "description": "Porta POST laterale DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "31",
    "description": "Cerchione ANT SX in ferro",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "36",
    "description": "Pannelli porte (SX o DX)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "1320",
    "description": "Paraurti centrale posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1L",
    "description": "Paraurti ANT (lato sx)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1L01",
    "description": "Minigonna ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1R",
    "description": "Paraurti ANT (lato dx)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1R01",
    "description": "Minigonna ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "3WA",
    "description": "Arco passaruota ANT SX",
    "vehType": "suv",
    "sostituzione": "105.3233333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "4WA",
    "description": "Arco passaruota ANT DX",
    "vehType": "suv",
    "sostituzione": "105.18",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "78",
    "description": "Coppa ruota",
    "vehType": "suv",
    "sostituzione": "65.05",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "80",
    "description": "Coprimozzo ",
    "vehType": "suv",
    "sostituzione": "20.58666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "84",
    "description": "Portatarga",
    "vehType": "suv",
    "sostituzione": "38.31",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1",
    "#eaf910": "1"
  },
  {
    "code": "100",
    "description": "Proiettore ANT DX",
    "vehType": "suv",
    "sostituzione": "1365.58",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "101",
    "description": "Griglia paraurti ANT",
    "vehType": "suv",
    "sostituzione": "219.72",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "102",
    "description": "Spoiler paraurti ANT",
    "vehType": "suv",
    "sostituzione": "98.705",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "103",
    "description": "Proiettore ANT SX",
    "vehType": "suv",
    "sostituzione": "1365.58",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "104",
    "description": "Targa ANT",
    "vehType": "suv",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "105",
    "description": "Griglia ANT (nel cofano o separata)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "106",
    "description": "Stemma ANT",
    "vehType": "suv",
    "sostituzione": "39.58666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "107",
    "description": "Freccia ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "108",
    "description": "Faro antinebbia SX",
    "vehType": "suv",
    "sostituzione": "257.36",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "109",
    "description": "Modanatura paraurti ANT (DX o SX)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "110",
    "description": "Freccia ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "111",
    "description": "Faro antinebbia DX",
    "vehType": "suv",
    "sostituzione": "254.26",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "118",
    "description": "Coppa ruota",
    "vehType": "suv",
    "sostituzione": "65.05",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "126",
    "description": "Interruttore accensione luci",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "130",
    "description": "Luce diurna ANT DX Posizione",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "138",
    "description": "Spazzola tergicristallo posteriore",
    "vehType": "suv",
    "sostituzione": "66.06",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "140",
    "description": "Luce diurna ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "141",
    "description": "Volante",
    "vehType": "suv",
    "sostituzione": "1873.71",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "301",
    "description": "Indicatore di direzione SX (laterale parafango)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "306",
    "description": "Pneumatico ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "307",
    "description": "Cerchio in acciaio ANT SX",
    "vehType": "suv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "308",
    "description": "Cerchio in lega ANT SX",
    "vehType": "suv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "401",
    "description": "Indicatore di direzione DX (laterale parafango)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "406",
    "description": "Pneumatico ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "407",
    "description": "Cerchio in acciaio ANT DX",
    "vehType": "suv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "408",
    "description": "Cerchio in lega ANT DX",
    "vehType": "suv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "501",
    "description": "Antenna",
    "vehType": "suv",
    "sostituzione": "65.14333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "502",
    "description": "Barre mancorrenti",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "503",
    "description": "Capote COMPLETA",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "504",
    "description": "Tetto apribile (lamierato)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "505",
    "description": "Copertura capote solo tela",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "506",
    "description": "Spoiler portello",
    "vehType": "suv",
    "sostituzione": "570.36",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "510",
    "description": "Rivestimento anteriore esterno",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "511",
    "description": "Tetto retraibile (tettuccio senza meccanismo)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "560",
    "description": "Barra tetto SX Modan",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "570",
    "description": "Barra tetto DX Modan",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "601",
    "description": "Sottoporta SX",
    "vehType": "suv",
    "sostituzione": "605.105",
    "lieve": "50",
    "medio": "85.8",
    "grave": "140.7",
    "#db6928": "1"
  },
  {
    "code": "602",
    "description": "Modanatura porta ANT SX",
    "vehType": "suv",
    "sostituzione": "160.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "603",
    "description": "Serie cilindretti serrature",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "604",
    "description": "Maniglia porta esterna ANT SX",
    "vehType": "suv",
    "sostituzione": "111.6166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "605",
    "description": "Vetro scendente ANT SX",
    "vehType": "suv",
    "sostituzione": "169.92",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "606",
    "description": "Calotta retrovisore SX",
    "vehType": "suv",
    "sostituzione": "93.98333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "607",
    "description": "Retrovisore lato guida",
    "vehType": "suv",
    "sostituzione": "440.21",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "609",
    "description": "Vetro fisso ANT SX",
    "vehType": "suv",
    "sostituzione": "122.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "612",
    "description": "Cristallo retrovisore SX",
    "vehType": "suv",
    "sostituzione": "105.2166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "701",
    "description": "Sottoporta DX",
    "vehType": "suv",
    "sostituzione": "605.105",
    "lieve": "50",
    "medio": "85.8",
    "grave": "140.7",
    "#3e6928": "1"
  },
  {
    "code": "702",
    "description": "Modanatura porta ANT DX",
    "vehType": "suv",
    "sostituzione": "160.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "703",
    "description": "Cilindretto serratura porta ant.",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "704",
    "description": "Maniglia porta esterna ANT DX",
    "vehType": "suv",
    "sostituzione": "111.6166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "705",
    "description": "Vetro scendente ANT DX",
    "vehType": "suv",
    "sostituzione": "169.92",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "706",
    "description": "Calotta retrovisore DX",
    "vehType": "suv",
    "sostituzione": "93.98333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "707",
    "description": "Retrovisore lato passggero",
    "vehType": "suv",
    "sostituzione": "440.21",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "712",
    "description": "Cristallo retrovisore DX",
    "vehType": "suv",
    "sostituzione": "105.2166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "713",
    "description": "Vetro fisso ANT DX",
    "vehType": "suv",
    "sostituzione": "122.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "804",
    "description": "Maniglia porta esterna POST SX",
    "vehType": "suv",
    "sostituzione": "106.1333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "805",
    "description": "Vetro scendente porta POST SX",
    "vehType": "suv",
    "sostituzione": "134.04",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "806",
    "description": "Vetro fisso POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "807",
    "description": "Guarnizione porta POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "811",
    "description": "Pannello porta POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "904",
    "description": "Maniglia porta esterna POST DX",
    "vehType": "suv",
    "sostituzione": "106.1333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "905",
    "description": "Vetro scendenteporta POST DX",
    "vehType": "suv",
    "sostituzione": "134.04",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "906",
    "description": "Vetro fisso POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "907",
    "description": "Guarnizione porta POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "908",
    "description": "Modanatura paraurti POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1001",
    "description": "Spazzole tergicristallo POST",
    "vehType": "suv",
    "sostituzione": "55.07",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1002",
    "description": "Braccio tergicristallo POST",
    "vehType": "suv",
    "sostituzione": "49.505",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1003",
    "description": "Luce terzo stop",
    "vehType": "suv",
    "sostituzione": "100.9",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1011",
    "description": "Maniglia apertura portello post",
    "vehType": "suv",
    "sostituzione": "285.755",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1013",
    "description": "Meccanismo apertura/chiusura capote",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1014",
    "description": "Terminale di scarico (silenz di scarico)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1101",
    "description": "Tappo carburante lato SX",
    "vehType": "suv",
    "sostituzione": "84.91333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1102",
    "description": "Sportello carburante lato SX",
    "vehType": "suv",
    "sostituzione": "52.65333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1103",
    "description": "Modanatura parafango ANT ",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "1106",
    "description": "Pneumatico POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1107",
    "description": "Cerchio in acciaio POST SX",
    "vehType": "suv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1108",
    "description": "Cerchio in lega POST SX",
    "vehType": "suv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1112",
    "description": "Sostegno/supporto (ruota scorta)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1114",
    "description": "Cerchione POST SX (ferro)",
    "vehType": "suv",
    "sostituzione": "98.23",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1201",
    "description": "Tappo carburante lato DX",
    "vehType": "suv",
    "sostituzione": "84.91333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1202",
    "description": "Sportello carburante lato DX",
    "vehType": "suv",
    "sostituzione": "52.65333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1203",
    "description": "Errato rifornimento",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1204",
    "description": "Modanatura parafango POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1206",
    "description": "Pneumatico POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1207",
    "description": "Cerchio in acciaio POST DX",
    "vehType": "suv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1208",
    "description": "Cerchio in lega POST",
    "vehType": "suv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1222",
    "description": "Cerchione POST DX (ferro)",
    "vehType": "suv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1223",
    "description": "Arco passaruota POST DX",
    "vehType": "suv",
    "sostituzione": "131.72",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1225",
    "description": "Sostegno/supporto",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1301",
    "description": "Griglia paraurti POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1302",
    "description": "Spoiler paraurti POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1303",
    "description": "Luce POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1304",
    "description": "Targa POST",
    "vehType": "suv",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1305",
    "description": "Griglia paraurti ANT inf.",
    "vehType": "suv",
    "sostituzione": "200.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1306",
    "description": "Stemma POST",
    "vehType": "suv",
    "sostituzione": "44.24333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1307",
    "description": "Freccia POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1308",
    "description": "Luce retronebbia posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1309",
    "description": "Gancio di traino smontabile J",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1310",
    "description": "Luce POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1311",
    "description": "Fanale POST SX",
    "vehType": "suv",
    "sostituzione": "304.0766667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1312",
    "description": "Fanale POST DX",
    "vehType": "suv",
    "sostituzione": "281.61",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1313",
    "description": "Freccia POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1314",
    "description": "Luce targa singola",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1317",
    "description": "Gancio di traino",
    "vehType": "suv",
    "sostituzione": "364.38",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1322",
    "description": "Serratura portello posteriore",
    "vehType": "suv",
    "sostituzione": "143.0833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1325",
    "description": "Luce di posizione POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1326",
    "description": "Luce di posizione POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1401",
    "description": "Spazzole tergicristallo ANT",
    "vehType": "suv",
    "sostituzione": "60.36333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1402",
    "description": "Braccio tergicristallo ANT",
    "vehType": "suv",
    "sostituzione": "75.36333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1403",
    "description": "Braccio tergicristallo ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1404",
    "description": "Braccio tergicristallo ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1405",
    "description": "Spazzola tergicristallo ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1406",
    "description": "Spazzola tergicristallo ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1407",
    "description": "Spazzole tergicristallo",
    "vehType": "suv",
    "sostituzione": "55.07",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1505",
    "description": "Tappo gancio traino paraurti (ANT e POST)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1508",
    "description": "Fisso parafango ant. SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1509",
    "description": "Fisso parafango ant. DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1510",
    "description": "Luce di posizione ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1511",
    "description": "Luce di posizione ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1512",
    "description": "Monobraccio tergicristallo anteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1700",
    "description": "Chiave (seconda chiave)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1702",
    "description": "Manuale d'uso e manutenzione",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1703",
    "description": "Radio (vedi allestimento)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1704",
    "description": "Accendisigari",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1705",
    "description": "Posacenere",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1706",
    "description": "Leva cambio (pomello)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1708",
    "description": "Retrovisore interno",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1709",
    "description": "Comando regolazione retrovisore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1710",
    "description": "Cruscotto (plancia)",
    "vehType": "suv",
    "sostituzione": "2240.596667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1713",
    "description": "Poggiatesta sedile singolo",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1720",
    "description": "Cappelliera post.",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1721",
    "description": "Triangolo di emergenza",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1722",
    "description": "Chiave smontaruota",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1723",
    "description": "Crick ruota scorta (martinetto)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1725",
    "description": "Altoparlanti tweeter",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1726",
    "description": "Altoparlante posteriore/sub",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1727",
    "description": "Compressore d'aria",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1728",
    "description": "Maniglia porta ANT interna (SX o DX)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1729",
    "description": "Manovella alzacristalli",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1730",
    "description": "Estintore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1731",
    "description": "Rivestimento vano bagagli LAT",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1732",
    "description": "Ripiano interno copribagagliaio",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1733",
    "description": "Telecomando",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1734",
    "description": "Caricatore CD",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1735",
    "description": "Portabollo/assicurazione",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1736",
    "description": "Moquette",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1737",
    "description": "Tappetini",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1738",
    "description": "Cinture di Sicurezza ",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1739",
    "description": "Rivestimento interno tetto (cielo)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1740",
    "description": "Kit prontosoccorso",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1741",
    "description": "Bullone antifurto",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1742",
    "description": "Plafoniera interna",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1743",
    "description": "Rete fermabagagli",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1745",
    "description": "Cassetto portaoggetti (sportello)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1747",
    "description": "Riparo inferiore CARTER MOTORE",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1748",
    "description": "Airbag passeggero",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1749",
    "description": "Airbag lato guida",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1750",
    "description": "Pulizia interni extra",
    "vehType": "suv",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1751",
    "description": "Poggiabraccia ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1752",
    "description": "Poggiabraccia POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1754",
    "description": "Tunnel centrale completo",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1755",
    "description": "Moquette ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1756",
    "description": "Moquette ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1757",
    "description": "Moquette POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1758",
    "description": "Moquette POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1759",
    "description": "Quadro strumenti",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1760",
    "description": "Pannello porta ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1761",
    "description": "Poggiatesta ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1763",
    "description": "Schienale sedile ANT SX (solo fodera)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1764",
    "description": "Seduta ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1765",
    "description": "Comando alzavetro ANT DX",
    "vehType": "suv",
    "sostituzione": "313.1066667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1766",
    "description": "Comandoalzavetro ANT SX",
    "vehType": "suv",
    "sostituzione": "313.1066667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1767",
    "description": "Comandoalzavetro POST DX",
    "vehType": "suv",
    "sostituzione": "398.66",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1768",
    "description": "Comando alzavetro POST SX",
    "vehType": "suv",
    "sostituzione": "398.66",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1770",
    "description": "Pannello porta ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1771",
    "description": "Poggiatesta ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1772",
    "description": "Schienale sedile ANT DX (solo fodera)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1773",
    "description": "Seduta ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1774",
    "description": "Sedile passeggero COMPLETO",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1776",
    "description": "Poggiatesta POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1777",
    "description": "Schienale sedile POST DX (solo fodera)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1778",
    "description": "Seduta sedile POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1779",
    "description": "Schienale sedile POST SX (solo fodera)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1780",
    "description": "Pannello interno portello posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1781",
    "description": "Seduta sedile POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1783",
    "description": "Cintura di sicurezza POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1784",
    "description": "Altoparlante ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1785",
    "description": "Altoparlante POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1786",
    "description": "Altoparlante ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1787",
    "description": "Altoparlante POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1788",
    "description": "Aletta parasole ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1790",
    "description": "Pannello porta POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1791",
    "description": "Impianto di navigazione (solo display)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1792",
    "description": "Poggiabraccia POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1793",
    "description": "Poggiabraccia ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1794",
    "description": "Airbag laterale SX tendina",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1795",
    "description": "Airbag laterale DX tendinta",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1796",
    "description": "Poggiatesta POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1797",
    "description": "Cintura di sicurezza POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1804",
    "description": "Coprimozzo ",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1811",
    "description": "Disco GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1812",
    "description": "Kit attrezzi",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1813",
    "description": "Giubbino di emergenza",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4001",
    "description": "Cerchione ANT DX in ferro",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "6061",
    "description": "Freccia retrovisore SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "6071",
    "description": "Freccia retrovisore DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "7301",
    "description": "Ruota di scorta in ferro ruotino",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "7302",
    "description": "Ruota di scorta in lega",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11D",
    "description": "Modanatura montante porta post. SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "11WA",
    "description": "Passaruota/Parasassi Int. ANT, parte ANT/POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "12D",
    "description": "Modanatura montante porta post. DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "13L1",
    "description": "Luce antinebbia POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "13R1",
    "description": "Luce antinebbia POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "17B1",
    "description": "Sedile POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B2",
    "description": "Seduta POST centrale",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B3",
    "description": "Sedile POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F1",
    "description": "Sedile ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F2",
    "description": "Sedile ANT centrale",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F3",
    "description": "Sedile ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T7",
    "description": "Comandi climatizzatore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U5",
    "description": "Portachiavi",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U6",
    "description": "Specchio di cortesia",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U7",
    "description": "Cintura di sicurezza ANT DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U9",
    "description": "Cintura di sicurezza ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W1",
    "description": "Rivestimento interno (battivaligia)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W2",
    "description": "Aletta parasole ANT SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W4",
    "description": "SD GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W5",
    "description": "Caricabatteria GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W6",
    "description": "Staffa supporto GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W7",
    "description": "Contenitore GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W8",
    "description": "Custodia GPS",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17X1",
    "description": "Cavo di ricarica veicolo elettrico",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "6B",
    "description": "Modanatura montante porta ant. SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "7B",
    "description": "Modanatura montante porta ant. DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "8C",
    "description": "Modanatura montante centrale SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "9C",
    "description": "Modanatura montante centrale DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "3A",
    "description": "Parete laterale esterna SX (truck)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4A",
    "description": "Parete laterale esterna DX (truck)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "81",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "82",
    "description": "Pannello superiore portello posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "83",
    "description": "Pannello inferiore portello posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "122",
    "description": "Pannello superiore porta carico POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "123",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "132",
    "description": "Pannello MONTANTE superiore POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "133",
    "description": "Pannello inferiore POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "134",
    "description": "Pannello MONTANTE superiore POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "135",
    "description": "Pannello inferiore POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "220",
    "description": "Luce di ingombro dx",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "302",
    "description": "Modanatura laterale SX (rifinitura lat.plastica)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "507",
    "description": "Parete divisoria vano di carico",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "709",
    "description": "Specchio retrovisore supplementare",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "711",
    "description": "Pedana di carico DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "803",
    "description": "Cilindretto serratura portello post",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "808",
    "description": "Fascia paracolpi  porta carico  POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "815",
    "description": "Protezione inferiore laterale SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "817",
    "description": "Barra di protezione lato guida",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "903",
    "description": "Serratura porta POST",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "915",
    "description": "Protezione inferiore laterale DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "917",
    "description": "Barra di protezione lato passeggero",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1004",
    "description": "Pedana posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1105",
    "description": "Vetro laterale POST SX truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1110",
    "description": "Pannello laterale interno SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1111",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1113",
    "description": "Pannello interno posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1205",
    "description": "Vetro laterale DX truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1209",
    "description": "Vetro laterale POST DX truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1210",
    "description": "Pannello laterale interno DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1220",
    "description": "Pannello superiore porta carico POST SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1221",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1315",
    "description": "Fascia paracolpi porta carico POST DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1316",
    "description": "Soglia acceso vano carico(Truck)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1501",
    "description": "Pannello interno DX superiore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1502",
    "description": "Pannello interno DX inferiore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1503",
    "description": "Pannello interno SX superiore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1504",
    "description": "Pannello interno SX inferiore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1520",
    "description": "Luce di ingombro sx",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1746",
    "description": "Piano di carico posteiore interno",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1753",
    "description": "Divisorio vano carico",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1799",
    "description": "Unità di controllo interna",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1809",
    "description": "Adesivo Europcar",
    "vehType": "suv",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1810",
    "description": "Deflettore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11CO",
    "description": "Angolare truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "12CO",
    "description": "Angolare truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13L",
    "description": "Angolo SX Paraurti POST solo truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13R",
    "description": "Angolo DX Paraurti POST solo truck",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T1",
    "description": "Terza fila di sedili SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T2",
    "description": "Terza fila di sedili centrale",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T3",
    "description": "Terza fila di sedili DX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U1",
    "description": "Rivestimento verticale coibentato",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U2",
    "description": "Pavimento coibentato",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U3",
    "description": "Rivestimento tetto coibentato",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U4",
    "description": "Comando controllo frigo",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U8",
    "description": "Panca sedile",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W3",
    "description": "Pianale di carico (copertura)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "139",
    "description": "Unità di controllo pedana posteriore",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "402",
    "description": "Modanatura laterale DX (rifinitura lat plastica)",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "611",
    "description": "Pedana di carico SX",
    "vehType": "suv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },



  ////// LCV ////


  {
    "code": "1",
    "description": "Paraurti ANT",
    "vehType": "lcv",
    "sostituzione": "674.1866667",
    "lieve": "38.43333333",
    "medio": "65.93333333",
    "grave": "93.93333333",
    "#3828db": "1"
  },
  {
    "code": "2",
    "description": "Cofano",
    "vehType": "lcv",
    "sostituzione": "713.0433333",
    "lieve": "63.5",
    "medio": "135.3",
    "grave": "138.5333333",
    "#3828db": "1"
  },
  { 
    "code": "3",
    "description": "Parafango ANT SX",
    "vehType": "lcv",
    "sostituzione": "454.8533333",
    "lieve": "65.86666667",
    "medio": "140.4333333",
    "grave": "196.9",
    "#ee1313": "1"
  },
  {
    "code": "4",
    "description": "Parafango ANT DX",
    "vehType": "lcv",
    "sostituzione": "454.8533333",
    "lieve": "65.86666667",
    "medio": "140.4333333",
    "grave": "196.9",
    "#26f719": "1"
  },
  {
    "code": "5",
    "description": "Tetto",
    "vehType": "lcv",
    "sostituzione": "1456.17",
    "lieve": "125.5666667",
    "medio": "297.0333333",
    "grave": "497.1666667",
    "#b1a558": "1"
  },
  {
    "code": "6",
    "description": "Porta ANT SX",
    "vehType": "lcv",
    "sostituzione": "955.1933333",
    "lieve": "81.63333333",
    "medio": "184.3666667",
    "grave": "266.0666667",
    "#db6928": "1"
  },
  {
    "code": "7",
    "description": "Porta ANT DX",
    "vehType": "lcv",
    "sostituzione": "955.1933333",
    "lieve": "81.63333333",
    "medio": "184.3666667",
    "grave": "266.0666667",
    "#3e6928": "1"
  },
  {
    "code": "8",
    "description": "Porta POST SX",
    "vehType": "lcv",
    "sostituzione": "1011.72",
    "lieve": "77.8",
    "medio": "174",
    "grave": "250.8333333",
    "#db6928": "1"
  },
  {
    "code": "9",
    "description": "Porta POST DX",
    "vehType": "lcv",
    "sostituzione": "1011.72",
    "lieve": "77.8",
    "medio": "174",
    "grave": "250.8333333",
    "#3e6928": "1"
  },
  {
    "code": "10",
    "description": "Lunotto",
    "vehType": "lcv",
    "sostituzione": "482.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "11",
    "description": "Parafango POST SX",
    "vehType": "lcv",
    "sostituzione": "1045.423333",
    "lieve": "104.5333333",
    "medio": "244.8666667",
    "grave": "414.5666667",
    "#af28db": "1"
  },
  {
    "code": "12",
    "description": "Parafango POST DX",
    "vehType": "lcv",
    "sostituzione": "1045.423333",
    "lieve": "104.5333333",
    "medio": "244.8666667",
    "grave": "414.5666667",
    "#87a5f0": "1"
  },
  {
    "code": "1010",
    "description": "Portellone POST",
    "vehType": "lcv",
    "sostituzione": "1313.45",
    "lieve": "75.73333333",
    "medio": "168.4333333",
    "grave": "239.4666667",
    "#eaf910": "1"
  },
  {
    "code": "13",
    "description": "Paraurti POST",
    "vehType": "lcv",
    "sostituzione": "599.87",
    "lieve": "18.1",
    "medio": "32.9",
    "grave": "50.56666667",
    "#eaf910": "1"
  },
  {
    "code": "14",
    "description": "Parabrezza",
    "vehType": "lcv",
    "sostituzione": "562.4826667",
    "lieve": "",
    "medio": "58",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "16",
    "description": "Porta POST laterale DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "31",
    "description": "Cerchione ANT SX in ferro",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "36",
    "description": "Pannelli porte (SX o DX)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "1320",
    "description": "Paraurti centrale posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1L",
    "description": "Paraurti ANT (lato sx)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1L01",
    "description": "Minigonna ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1R",
    "description": "Paraurti ANT (lato dx)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1R01",
    "description": "Minigonna ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "3WA",
    "description": "Arco passaruota ANT SX",
    "vehType": "lcv",
    "sostituzione": "105.3233333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "4WA",
    "description": "Arco passaruota ANT DX",
    "vehType": "lcv",
    "sostituzione": "105.18",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "78",
    "description": "Coppa ruota",
    "vehType": "lcv",
    "sostituzione": "65.05",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "80",
    "description": "Coprimozzo ",
    "vehType": "lcv",
    "sostituzione": "20.58666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "84",
    "description": "Portatarga",
    "vehType": "lcv",
    "sostituzione": "38.31",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1",
    "#eaf910": "1"
  },
  {
    "code": "100",
    "description": "Proiettore ANT DX",
    "vehType": "lcv",
    "sostituzione": "1365.58",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "101",
    "description": "Griglia paraurti ANT",
    "vehType": "lcv",
    "sostituzione": "219.72",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "102",
    "description": "Spoiler paraurti ANT",
    "vehType": "lcv",
    "sostituzione": "98.705",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "103",
    "description": "Proiettore ANT SX",
    "vehType": "lcv",
    "sostituzione": "1365.58",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "104",
    "description": "Targa ANT",
    "vehType": "lcv",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "105",
    "description": "Griglia ANT (nel cofano o separata)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "106",
    "description": "Stemma ANT",
    "vehType": "lcv",
    "sostituzione": "39.58666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "107",
    "description": "Freccia ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "108",
    "description": "Faro antinebbia SX",
    "vehType": "lcv",
    "sostituzione": "257.36",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "109",
    "description": "Modanatura paraurti ANT (DX o SX)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "110",
    "description": "Freccia ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "111",
    "description": "Faro antinebbia DX",
    "vehType": "lcv",
    "sostituzione": "254.26",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "118",
    "description": "Coppa ruota",
    "vehType": "lcv",
    "sostituzione": "65.05",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "126",
    "description": "Interruttore accensione luci",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "130",
    "description": "Luce diurna ANT DX Posizione",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "138",
    "description": "Spazzola tergicristallo posteriore",
    "vehType": "lcv",
    "sostituzione": "66.06",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "140",
    "description": "Luce diurna ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "141",
    "description": "Volante",
    "vehType": "lcv",
    "sostituzione": "1873.71",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "301",
    "description": "Indicatore di direzione SX (laterale parafango)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "306",
    "description": "Pneumatico ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "307",
    "description": "Cerchio in acciaio ANT SX",
    "vehType": "lcv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "308",
    "description": "Cerchio in lega ANT SX",
    "vehType": "lcv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "401",
    "description": "Indicatore di direzione DX (laterale parafango)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "406",
    "description": "Pneumatico ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "407",
    "description": "Cerchio in acciaio ANT DX",
    "vehType": "lcv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "408",
    "description": "Cerchio in lega ANT DX",
    "vehType": "lcv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "501",
    "description": "Antenna",
    "vehType": "lcv",
    "sostituzione": "65.14333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "502",
    "description": "Barre mancorrenti",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "503",
    "description": "Capote COMPLETA",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "504",
    "description": "Tetto apribile (lamierato)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "505",
    "description": "Copertura capote solo tela",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "506",
    "description": "Spoiler portello",
    "vehType": "lcv",
    "sostituzione": "570.36",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "510",
    "description": "Rivestimento anteriore esterno",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "511",
    "description": "Tetto retraibile (tettuccio senza meccanismo)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "560",
    "description": "Barra tetto SX Modan",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "570",
    "description": "Barra tetto DX Modan",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "601",
    "description": "Sottoporta SX",
    "vehType": "lcv",
    "sostituzione": "605.105",
    "lieve": "50",
    "medio": "85.8",
    "grave": "140.7",
    "#db6928": "1"
  },
  {
    "code": "602",
    "description": "Modanatura porta ANT SX",
    "vehType": "lcv",
    "sostituzione": "160.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "603",
    "description": "Serie cilindretti serrature",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "604",
    "description": "Maniglia porta esterna ANT SX",
    "vehType": "lcv",
    "sostituzione": "111.6166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "605",
    "description": "Vetro scendente ANT SX",
    "vehType": "lcv",
    "sostituzione": "169.92",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "606",
    "description": "Calotta retrovisore SX",
    "vehType": "lcv",
    "sostituzione": "93.98333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "607",
    "description": "Retrovisore lato guida",
    "vehType": "lcv",
    "sostituzione": "440.21",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "609",
    "description": "Vetro fisso ANT SX",
    "vehType": "lcv",
    "sostituzione": "122.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "612",
    "description": "Cristallo retrovisore SX",
    "vehType": "lcv",
    "sostituzione": "105.2166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "701",
    "description": "Sottoporta DX",
    "vehType": "lcv",
    "sostituzione": "605.105",
    "lieve": "50",
    "medio": "85.8",
    "grave": "140.7",
    "#3e6928": "1"
  },
  {
    "code": "702",
    "description": "Modanatura porta ANT DX",
    "vehType": "lcv",
    "sostituzione": "160.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "703",
    "description": "Cilindretto serratura porta ant.",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "704",
    "description": "Maniglia porta esterna ANT DX",
    "vehType": "lcv",
    "sostituzione": "111.6166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "705",
    "description": "Vetro scendente ANT DX",
    "vehType": "lcv",
    "sostituzione": "169.92",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "706",
    "description": "Calotta retrovisore DX",
    "vehType": "lcv",
    "sostituzione": "93.98333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "707",
    "description": "Retrovisore lato passggero",
    "vehType": "lcv",
    "sostituzione": "440.21",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "712",
    "description": "Cristallo retrovisore DX",
    "vehType": "lcv",
    "sostituzione": "105.2166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "713",
    "description": "Vetro fisso ANT DX",
    "vehType": "lcv",
    "sostituzione": "122.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "804",
    "description": "Maniglia porta esterna POST SX",
    "vehType": "lcv",
    "sostituzione": "106.1333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "805",
    "description": "Vetro scendente porta POST SX",
    "vehType": "lcv",
    "sostituzione": "134.04",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "806",
    "description": "Vetro fisso POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "807",
    "description": "Guarnizione porta POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "811",
    "description": "Pannello porta POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "904",
    "description": "Maniglia porta esterna POST DX",
    "vehType": "lcv",
    "sostituzione": "106.1333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "905",
    "description": "Vetro scendenteporta POST DX",
    "vehType": "lcv",
    "sostituzione": "134.04",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "906",
    "description": "Vetro fisso POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "907",
    "description": "Guarnizione porta POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "908",
    "description": "Modanatura paraurti POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1001",
    "description": "Spazzole tergicristallo POST",
    "vehType": "lcv",
    "sostituzione": "55.07",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1002",
    "description": "Braccio tergicristallo POST",
    "vehType": "lcv",
    "sostituzione": "49.505",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1003",
    "description": "Luce terzo stop",
    "vehType": "lcv",
    "sostituzione": "100.9",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1011",
    "description": "Maniglia apertura portello post",
    "vehType": "lcv",
    "sostituzione": "285.755",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1013",
    "description": "Meccanismo apertura/chiusura capote",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1014",
    "description": "Terminale di scarico (silenz di scarico)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1101",
    "description": "Tappo carburante lato SX",
    "vehType": "lcv",
    "sostituzione": "84.91333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1102",
    "description": "Sportello carburante lato SX",
    "vehType": "lcv",
    "sostituzione": "52.65333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1103",
    "description": "Modanatura parafango ANT ",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "1106",
    "description": "Pneumatico POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1107",
    "description": "Cerchio in acciaio POST SX",
    "vehType": "lcv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1108",
    "description": "Cerchio in lega POST SX",
    "vehType": "lcv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1112",
    "description": "Sostegno/supporto (ruota scorta)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1114",
    "description": "Cerchione POST SX (ferro)",
    "vehType": "lcv",
    "sostituzione": "98.23",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1201",
    "description": "Tappo carburante lato DX",
    "vehType": "lcv",
    "sostituzione": "84.91333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1202",
    "description": "Sportello carburante lato DX",
    "vehType": "lcv",
    "sostituzione": "52.65333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1203",
    "description": "Errato rifornimento",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1204",
    "description": "Modanatura parafango POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1206",
    "description": "Pneumatico POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1207",
    "description": "Cerchio in acciaio POST DX",
    "vehType": "lcv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1208",
    "description": "Cerchio in lega POST",
    "vehType": "lcv",
    "sostituzione": "393.7833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1222",
    "description": "Cerchione POST DX (ferro)",
    "vehType": "lcv",
    "sostituzione": "121.14",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1223",
    "description": "Arco passaruota POST DX",
    "vehType": "lcv",
    "sostituzione": "131.72",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1225",
    "description": "Sostegno/supporto",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1301",
    "description": "Griglia paraurti POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1302",
    "description": "Spoiler paraurti POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1303",
    "description": "Luce POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1304",
    "description": "Targa POST",
    "vehType": "lcv",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1305",
    "description": "Griglia paraurti ANT inf.",
    "vehType": "lcv",
    "sostituzione": "200.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1306",
    "description": "Stemma POST",
    "vehType": "lcv",
    "sostituzione": "44.24333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1307",
    "description": "Freccia POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1308",
    "description": "Luce retronebbia posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1309",
    "description": "Gancio di traino smontabile J",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1310",
    "description": "Luce POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1311",
    "description": "Fanale POST SX",
    "vehType": "lcv",
    "sostituzione": "304.0766667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1312",
    "description": "Fanale POST DX",
    "vehType": "lcv",
    "sostituzione": "281.61",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1313",
    "description": "Freccia POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1314",
    "description": "Luce targa singola",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1317",
    "description": "Gancio di traino",
    "vehType": "lcv",
    "sostituzione": "364.38",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1322",
    "description": "Serratura portello posteriore",
    "vehType": "lcv",
    "sostituzione": "143.0833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1325",
    "description": "Luce di posizione POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1326",
    "description": "Luce di posizione POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1401",
    "description": "Spazzole tergicristallo ANT",
    "vehType": "lcv",
    "sostituzione": "60.36333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1402",
    "description": "Braccio tergicristallo ANT",
    "vehType": "lcv",
    "sostituzione": "75.36333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1403",
    "description": "Braccio tergicristallo ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1404",
    "description": "Braccio tergicristallo ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1405",
    "description": "Spazzola tergicristallo ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1406",
    "description": "Spazzola tergicristallo ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1407",
    "description": "Spazzole tergicristallo",
    "vehType": "lcv",
    "sostituzione": "55.07",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1505",
    "description": "Tappo gancio traino paraurti (ANT e POST)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1508",
    "description": "Fisso parafango ant. SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1509",
    "description": "Fisso parafango ant. DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1510",
    "description": "Luce di posizione ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1511",
    "description": "Luce di posizione ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1512",
    "description": "Monobraccio tergicristallo anteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1700",
    "description": "Chiave (seconda chiave)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1702",
    "description": "Manuale d'uso e manutenzione",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1703",
    "description": "Radio (vedi allestimento)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1704",
    "description": "Accendisigari",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1705",
    "description": "Posacenere",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1706",
    "description": "Leva cambio (pomello)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1708",
    "description": "Retrovisore interno",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1709",
    "description": "Comando regolazione retrovisore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1710",
    "description": "Cruscotto (plancia)",
    "vehType": "lcv",
    "sostituzione": "2240.596667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1713",
    "description": "Poggiatesta sedile singolo",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1720",
    "description": "Cappelliera post.",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1721",
    "description": "Triangolo di emergenza",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1722",
    "description": "Chiave smontaruota",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1723",
    "description": "Crick ruota scorta (martinetto)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1725",
    "description": "Altoparlanti tweeter",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1726",
    "description": "Altoparlante posteriore/sub",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1727",
    "description": "Compressore d'aria",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1728",
    "description": "Maniglia porta ANT interna (SX o DX)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1729",
    "description": "Manovella alzacristalli",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1730",
    "description": "Estintore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1731",
    "description": "Rivestimento vano bagagli LAT",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1732",
    "description": "Ripiano interno copribagagliaio",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1733",
    "description": "Telecomando",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1734",
    "description": "Caricatore CD",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1735",
    "description": "Portabollo/assicurazione",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1736",
    "description": "Moquette",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1737",
    "description": "Tappetini",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1738",
    "description": "Cinture di Sicurezza ",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1739",
    "description": "Rivestimento interno tetto (cielo)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1740",
    "description": "Kit prontosoccorso",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1741",
    "description": "Bullone antifurto",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1742",
    "description": "Plafoniera interna",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1743",
    "description": "Rete fermabagagli",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1745",
    "description": "Cassetto portaoggetti (sportello)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1747",
    "description": "Riparo inferiore CARTER MOTORE",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1748",
    "description": "Airbag passeggero",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1749",
    "description": "Airbag lato guida",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1750",
    "description": "Pulizia interni extra",
    "vehType": "lcv",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1751",
    "description": "Poggiabraccia ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1752",
    "description": "Poggiabraccia POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1754",
    "description": "Tunnel centrale completo",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1755",
    "description": "Moquette ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1756",
    "description": "Moquette ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1757",
    "description": "Moquette POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1758",
    "description": "Moquette POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1759",
    "description": "Quadro strumenti",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1760",
    "description": "Pannello porta ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1761",
    "description": "Poggiatesta ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1763",
    "description": "Schienale sedile ANT SX (solo fodera)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1764",
    "description": "Seduta ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1765",
    "description": "Comando alzavetro ANT DX",
    "vehType": "lcv",
    "sostituzione": "313.1066667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1766",
    "description": "Comandoalzavetro ANT SX",
    "vehType": "lcv",
    "sostituzione": "313.1066667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1767",
    "description": "Comandoalzavetro POST DX",
    "vehType": "lcv",
    "sostituzione": "398.66",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1768",
    "description": "Comando alzavetro POST SX",
    "vehType": "lcv",
    "sostituzione": "398.66",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1770",
    "description": "Pannello porta ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1771",
    "description": "Poggiatesta ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1772",
    "description": "Schienale sedile ANT DX (solo fodera)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1773",
    "description": "Seduta ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1774",
    "description": "Sedile passeggero COMPLETO",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1776",
    "description": "Poggiatesta POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1777",
    "description": "Schienale sedile POST DX (solo fodera)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1778",
    "description": "Seduta sedile POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1779",
    "description": "Schienale sedile POST SX (solo fodera)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1780",
    "description": "Pannello interno portello posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1781",
    "description": "Seduta sedile POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1783",
    "description": "Cintura di sicurezza POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1784",
    "description": "Altoparlante ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1785",
    "description": "Altoparlante POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1786",
    "description": "Altoparlante ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1787",
    "description": "Altoparlante POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1788",
    "description": "Aletta parasole ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1790",
    "description": "Pannello porta POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1791",
    "description": "Impianto di navigazione (solo display)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1792",
    "description": "Poggiabraccia POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1793",
    "description": "Poggiabraccia ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1794",
    "description": "Airbag laterale SX tendina",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1795",
    "description": "Airbag laterale DX tendinta",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1796",
    "description": "Poggiatesta POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1797",
    "description": "Cintura di sicurezza POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1804",
    "description": "Coprimozzo ",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1811",
    "description": "Disco GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1812",
    "description": "Kit attrezzi",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1813",
    "description": "Giubbino di emergenza",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4001",
    "description": "Cerchione ANT DX in ferro",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "6061",
    "description": "Freccia retrovisore SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "6071",
    "description": "Freccia retrovisore DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "7301",
    "description": "Ruota di scorta in ferro ruotino",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "7302",
    "description": "Ruota di scorta in lega",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11D",
    "description": "Modanatura montante porta post. SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "11WA",
    "description": "Passaruota/Parasassi Int. ANT, parte ANT/POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "12D",
    "description": "Modanatura montante porta post. DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "13L1",
    "description": "Luce antinebbia POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "13R1",
    "description": "Luce antinebbia POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "17B1",
    "description": "Sedile POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B2",
    "description": "Seduta POST centrale",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B3",
    "description": "Sedile POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F1",
    "description": "Sedile ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F2",
    "description": "Sedile ANT centrale",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F3",
    "description": "Sedile ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T7",
    "description": "Comandi climatizzatore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U5",
    "description": "Portachiavi",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U6",
    "description": "Specchio di cortesia",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U7",
    "description": "Cintura di sicurezza ANT DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U9",
    "description": "Cintura di sicurezza ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W1",
    "description": "Rivestimento interno (battivaligia)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W2",
    "description": "Aletta parasole ANT SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W4",
    "description": "SD GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W5",
    "description": "Caricabatteria GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W6",
    "description": "Staffa supporto GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W7",
    "description": "Contenitore GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W8",
    "description": "Custodia GPS",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17X1",
    "description": "Cavo di ricarica veicolo elettrico",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "6B",
    "description": "Modanatura montante porta ant. SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "7B",
    "description": "Modanatura montante porta ant. DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "8C",
    "description": "Modanatura montante centrale SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "9C",
    "description": "Modanatura montante centrale DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "3A",
    "description": "Parete laterale esterna SX (truck)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4A",
    "description": "Parete laterale esterna DX (truck)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "81",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "82",
    "description": "Pannello superiore portello posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "83",
    "description": "Pannello inferiore portello posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "122",
    "description": "Pannello superiore porta carico POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "123",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "132",
    "description": "Pannello MONTANTE superiore POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "133",
    "description": "Pannello inferiore POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "134",
    "description": "Pannello MONTANTE superiore POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "135",
    "description": "Pannello inferiore POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "220",
    "description": "Luce di ingombro dx",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "302",
    "description": "Modanatura laterale SX (rifinitura lat.plastica)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "507",
    "description": "Parete divisoria vano di carico",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "709",
    "description": "Specchio retrovisore supplementare",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "711",
    "description": "Pedana di carico DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "803",
    "description": "Cilindretto serratura portello post",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "808",
    "description": "Fascia paracolpi  porta carico  POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "815",
    "description": "Protezione inferiore laterale SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "817",
    "description": "Barra di protezione lato guida",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "903",
    "description": "Serratura porta POST",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "915",
    "description": "Protezione inferiore laterale DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "917",
    "description": "Barra di protezione lato passeggero",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1004",
    "description": "Pedana posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1105",
    "description": "Vetro laterale POST SX truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1110",
    "description": "Pannello laterale interno SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1111",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1113",
    "description": "Pannello interno posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1205",
    "description": "Vetro laterale DX truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1209",
    "description": "Vetro laterale POST DX truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1210",
    "description": "Pannello laterale interno DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1220",
    "description": "Pannello superiore porta carico POST SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1221",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1315",
    "description": "Fascia paracolpi porta carico POST DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1316",
    "description": "Soglia acceso vano carico(Truck)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1501",
    "description": "Pannello interno DX superiore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1502",
    "description": "Pannello interno DX inferiore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1503",
    "description": "Pannello interno SX superiore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1504",
    "description": "Pannello interno SX inferiore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1520",
    "description": "Luce di ingombro sx",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1746",
    "description": "Piano di carico posteiore interno",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1753",
    "description": "Divisorio vano carico",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1799",
    "description": "Unità di controllo interna",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1809",
    "description": "Adesivo Europcar",
    "vehType": "lcv",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1810",
    "description": "Deflettore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11CO",
    "description": "Angolare truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "12CO",
    "description": "Angolare truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13L",
    "description": "Angolo SX Paraurti POST solo truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13R",
    "description": "Angolo DX Paraurti POST solo truck",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T1",
    "description": "Terza fila di sedili SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T2",
    "description": "Terza fila di sedili centrale",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T3",
    "description": "Terza fila di sedili DX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U1",
    "description": "Rivestimento verticale coibentato",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U2",
    "description": "Pavimento coibentato",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U3",
    "description": "Rivestimento tetto coibentato",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U4",
    "description": "Comando controllo frigo",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U8",
    "description": "Panca sedile",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W3",
    "description": "Pianale di carico (copertura)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "139",
    "description": "Unità di controllo pedana posteriore",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "402",
    "description": "Modanatura laterale DX (rifinitura lat plastica)",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "611",
    "description": "Pedana di carico SX",
    "vehType": "lcv",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },


  /////////////////// SW /////////////////


  {
    "code": "1",
    "description": "Paraurti ANT",
    "vehType": "sw",
    "sostituzione": "605.3666667",
    "lieve": "37",
    "medio": "61.23333333",
    "grave": "88.03333333",
    "#3828db": "1"
  },
  {
    "code": "2",
    "description": "Cofano",
    "vehType": "sw",
    "sostituzione": "653.8633333",
    "lieve": "64.53333333",
    "medio": "133.8666667",
    "grave": "231.9333333",
    "#3828db": "1"
  },
  {
    "code": "3",
    "description": "Parafango ANT SX",
    "vehType": "sw",
    "sostituzione": "411.8233333",
    "lieve": "60.2",
    "medio": "124.7333333",
    "grave": "210.2666667",
    "#ee1313": "1"
  },
  {
    "code": "4",
    "description": "Parafango ANT DX",
    "vehType": "sw",
    "sostituzione": "411.8233333",
    "lieve": "60.2",
    "medio": "124.7333333",
    "grave": "210.2666667",
    "#26f719": "1"
  },
  {
    "code": "5",
    "description": "Tetto",
    "vehType": "sw",
    "sostituzione": "1290.653333",
    "lieve": "120.7333333",
    "medio": "282.4333333",
    "grave": "474.8666667",
    "#b1a558": "1"
  },
  {
    "code": "6",
    "description": "Porta ANT SX",
    "vehType": "sw",
    "sostituzione": "849.3066667",
    "lieve": "76.93333333",
    "medio": "174.9666667",
    "grave": "299.1",
    "#db6928": "1"
  },
  {
    "code": "7",
    "description": "Porta ANT DX",
    "vehType": "sw",
    "sostituzione": "849.3066667",
    "lieve": "76.93333333",
    "medio": "174.9666667",
    "grave": "299.1",
    "#3e6928": "1"
  },
  {
    "code": "8",
    "description": "Porta POST SX",
    "vehType": "sw",
    "sostituzione": "797.8966667",
    "lieve": "76.36666667",
    "medio": "172.8",
    "grave": "295.3333333",
    "#db6928": "1"
  },
  {
    "code": "9",
    "description": "Porta POST DX",
    "vehType": "sw",
    "sostituzione": "797.8966667",
    "lieve": "76.36666667",
    "medio": "172.8",
    "grave": "295.3333333",
    "#3e6928": "1"
  },
  {
    "code": "10",
    "description": "Lunotto",
    "vehType": "sw",
    "sostituzione": "524.17",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "11",
    "description": "Parafango POST SX",
    "vehType": "sw",
    "sostituzione": "1254.163333",
    "lieve": "103.6",
    "medio": "238.2333333",
    "grave": "402.8",
    "#af28db": "1"
  },
  {
    "code": "12",
    "description": "Parafango POST DX",
    "vehType": "sw",
    "sostituzione": "1254.163333",
    "lieve": "103.6",
    "medio": "238.2333333",
    "grave": "402.8",
    "#87a5f0": "1"
  },
  {
    "code": "1010",
    "description": "Portellone POST",
    "vehType": "sw",
    "sostituzione": "954.2033333",
    "lieve": "73.26666667",
    "medio": "168.1333333",
    "grave": "295.7333333",
    "#eaf910": "1"
  },
  {
    "code": "13",
    "description": "Paraurti POST",
    "vehType": "sw",
    "sostituzione": "651.52",
    "lieve": "37.63333333",
    "medio": "64.33333333",
    "grave": "93.2",
    "#eaf910": "1"
  },
  {
    "code": "14",
    "description": "Parabrezza",
    "vehType": "sw",
    "sostituzione": "576.8326667",
    "lieve": "",
    "medio": "58",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "16",
    "description": "Porta POST laterale DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "31",
    "description": "Cerchione ANT SX in ferro",
    "vehType": "sw",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "36",
    "description": "Pannelli porte (SX o DX)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "1320",
    "description": "Paraurti centrale posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1L",
    "description": "Paraurti ANT (lato sx)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1L01",
    "description": "Minigonna ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1R",
    "description": "Paraurti ANT (lato dx)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1R01",
    "description": "Minigonna ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "3WA",
    "description": "Arco passaruota ANT SX",
    "vehType": "sw",
    "sostituzione": "91.55666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "4WA",
    "description": "Arco passaruota ANT DX",
    "vehType": "sw",
    "sostituzione": "91.55666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "78",
    "description": "Coppa ruota",
    "vehType": "sw",
    "sostituzione": "34.38",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "80",
    "description": "Coprimozzo ",
    "vehType": "sw",
    "sostituzione": "14.47",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "84",
    "description": "Portatarga",
    "vehType": "sw",
    "sostituzione": "7.19",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1",
    "#eaf910": "1"
  },
  {
    "code": "100",
    "description": "Proiettore ANT DX",
    "vehType": "sw",
    "sostituzione": "1501.8",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "101",
    "description": "Griglia paraurti ANT",
    "vehType": "sw",
    "sostituzione": "145.6",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "102",
    "description": "Spoiler paraurti ANT",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "103",
    "description": "Proiettore ANT SX",
    "vehType": "sw",
    "sostituzione": "1501.8",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "104",
    "description": "Targa ANT",
    "vehType": "sw",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "105",
    "description": "Griglia ANT (nel cofano o separata)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "106",
    "description": "Stemma ANT",
    "vehType": "sw",
    "sostituzione": "38.015",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "107",
    "description": "Freccia ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "108",
    "description": "Faro antinebbia SX",
    "vehType": "sw",
    "sostituzione": "225.9166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "109",
    "description": "Modanatura paraurti ANT (DX o SX)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "110",
    "description": "Freccia ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "111",
    "description": "Faro antinebbia DX",
    "vehType": "sw",
    "sostituzione": "225.9166667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "118",
    "description": "Coppa ruota",
    "vehType": "sw",
    "sostituzione": "34.38",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "126",
    "description": "Interruttore accensione luci",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "130",
    "description": "Luce diurna ANT DX Posizione",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "138",
    "description": "Spazzola tergicristallo posteriore",
    "vehType": "sw",
    "sostituzione": "35.54",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "140",
    "description": "Luce diurna ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "141",
    "description": "Volante",
    "vehType": "sw",
    "sostituzione": "601.03",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "301",
    "description": "Indicatore di direzione SX (laterale parafango)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "306",
    "description": "Pneumatico ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "307",
    "description": "Cerchio in acciaio ANT SX",
    "vehType": "sw",
    "sostituzione": "96.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "308",
    "description": "Cerchio in lega ANT SX",
    "vehType": "sw",
    "sostituzione": "369.2033333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "401",
    "description": "Indicatore di direzione DX (laterale parafango)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "406",
    "description": "Pneumatico ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "407",
    "description": "Cerchio in acciaio ANT DX",
    "vehType": "sw",
    "sostituzione": "213.3733333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "408",
    "description": "Cerchio in lega ANT DX",
    "vehType": "sw",
    "sostituzione": "329.895",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "501",
    "description": "Antenna",
    "vehType": "sw",
    "sostituzione": "113.825",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "502",
    "description": "Barre mancorrenti",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "503",
    "description": "Capote COMPLETA",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "504",
    "description": "Tetto apribile (lamierato)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "505",
    "description": "Copertura capote solo tela",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "506",
    "description": "Spoiler portello",
    "vehType": "sw",
    "sostituzione": "319.1366667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "510",
    "description": "Rivestimento anteriore esterno",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "511",
    "description": "Tetto retraibile (tettuccio senza meccanismo)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "560",
    "description": "Barra tetto SX Modan",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "570",
    "description": "Barra tetto DX Modan",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "601",
    "description": "Sottoporta SX",
    "vehType": "sw",
    "sostituzione": "504.775",
    "lieve": "48.8",
    "medio": "85.8",
    "grave": "140.7",
    "#db6928": "1"
  },
  {
    "code": "602",
    "description": "Modanatura porta ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "603",
    "description": "Serie cilindretti serrature",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "604",
    "description": "Maniglia porta esterna ANT SX",
    "vehType": "sw",
    "sostituzione": "104.2833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "605",
    "description": "Vetro scendente ANT SX",
    "vehType": "sw",
    "sostituzione": "195.8033333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "606",
    "description": "Calotta retrovisore SX",
    "vehType": "sw",
    "sostituzione": "143.01",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "607",
    "description": "Retrovisore lato guida",
    "vehType": "sw",
    "sostituzione": "424.1633333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "609",
    "description": "Vetro fisso ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "612",
    "description": "Cristallo retrovisore SX",
    "vehType": "sw",
    "sostituzione": "409.31",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "701",
    "description": "Sottoporta DX",
    "vehType": "sw",
    "sostituzione": "504.775",
    "lieve": "48.8",
    "medio": "85.8",
    "grave": "140.7",
    "#3e6928": "1"
  },
  {
    "code": "702",
    "description": "Modanatura porta ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "703",
    "description": "Cilindretto serratura porta ant.",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "704",
    "description": "Maniglia porta esterna ANT DX",
    "vehType": "sw",
    "sostituzione": "104.2833333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "705",
    "description": "Vetro scendente ANT DX",
    "vehType": "sw",
    "sostituzione": "195.8033333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "706",
    "description": "Calotta retrovisore DX",
    "vehType": "sw",
    "sostituzione": "143.01",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "707",
    "description": "Retrovisore lato passggero",
    "vehType": "sw",
    "sostituzione": "424.1633333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "712",
    "description": "Cristallo retrovisore DX",
    "vehType": "sw",
    "sostituzione": "409.31",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "713",
    "description": "Vetro fisso ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "804",
    "description": "Maniglia porta esterna POST SX",
    "vehType": "sw",
    "sostituzione": "102.89",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "805",
    "description": "Vetro scendente porta POST SX",
    "vehType": "sw",
    "sostituzione": "179.61",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "806",
    "description": "Vetro fisso POST SX",
    "vehType": "sw",
    "sostituzione": "231.935",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "807",
    "description": "Guarnizione porta POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "811",
    "description": "Pannello porta POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "904",
    "description": "Maniglia porta esterna POST DX",
    "vehType": "sw",
    "sostituzione": "102.89",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "905",
    "description": "Vetro scendenteporta POST DX",
    "vehType": "sw",
    "sostituzione": "179.61",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "906",
    "description": "Vetro fisso POST DX",
    "vehType": "sw",
    "sostituzione": "231.935",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "907",
    "description": "Guarnizione porta POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "908",
    "description": "Modanatura paraurti POST",
    "vehType": "sw",
    "sostituzione": "57.23",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1001",
    "description": "Spazzole tergicristallo POST",
    "vehType": "sw",
    "sostituzione": "31.665",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1002",
    "description": "Braccio tergicristallo POST",
    "vehType": "sw",
    "sostituzione": "44.85",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1003",
    "description": "Luce terzo stop",
    "vehType": "sw",
    "sostituzione": "120.99",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1011",
    "description": "Maniglia apertura portello post",
    "vehType": "sw",
    "sostituzione": "54.62333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1013",
    "description": "Meccanismo apertura/chiusura capote",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1014",
    "description": "Terminale di scarico (silenz di scarico)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1101",
    "description": "Tappo carburante lato SX",
    "vehType": "sw",
    "sostituzione": "47.635",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1102",
    "description": "Sportello carburante lato SX",
    "vehType": "sw",
    "sostituzione": "47.24333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1103",
    "description": "Modanatura parafango ANT ",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "1106",
    "description": "Pneumatico POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1107",
    "description": "Cerchio in acciaio POST SX",
    "vehType": "sw",
    "sostituzione": "96.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1108",
    "description": "Cerchio in lega POST SX",
    "vehType": "sw",
    "sostituzione": "477.46",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1112",
    "description": "Sostegno/supporto (ruota scorta)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1114",
    "description": "Cerchione POST SX (ferro)",
    "vehType": "sw",
    "sostituzione": "96.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1201",
    "description": "Tappo carburante lato DX",
    "vehType": "sw",
    "sostituzione": "47.635",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1202",
    "description": "Sportello carburante lato DX",
    "vehType": "sw",
    "sostituzione": "47.24333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1203",
    "description": "Errato rifornimento",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1204",
    "description": "Modanatura parafango POST",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1206",
    "description": "Pneumatico POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1207",
    "description": "Cerchio in acciaio POST DX",
    "vehType": "sw",
    "sostituzione": "96.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1208",
    "description": "Cerchio in lega POST",
    "vehType": "sw",
    "sostituzione": "477.46",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1222",
    "description": "Cerchione POST DX (ferro)",
    "vehType": "sw",
    "sostituzione": "96.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1223",
    "description": "Arco passaruota POST DX",
    "vehType": "sw",
    "sostituzione": "67.215",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1225",
    "description": "Sostegno/supporto",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1301",
    "description": "Griglia paraurti POST",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1302",
    "description": "Spoiler paraurti POST",
    "vehType": "sw",
    "sostituzione": "127.595",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1303",
    "description": "Luce POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1304",
    "description": "Targa POST",
    "vehType": "sw",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1305",
    "description": "Griglia paraurti ANT inf.",
    "vehType": "sw",
    "sostituzione": "1097.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1306",
    "description": "Stemma POST",
    "vehType": "sw",
    "sostituzione": "42.215",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1307",
    "description": "Freccia POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1308",
    "description": "Luce retronebbia posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1309",
    "description": "Gancio di traino smontabile J",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1310",
    "description": "Luce POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1311",
    "description": "Fanale POST SX",
    "vehType": "sw",
    "sostituzione": "369.33",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1312",
    "description": "Fanale POST DX",
    "vehType": "sw",
    "sostituzione": "433.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1313",
    "description": "Freccia POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1314",
    "description": "Luce targa singola",
    "vehType": "sw",
    "sostituzione": "40.94",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1317",
    "description": "Gancio di traino",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1322",
    "description": "Serratura portello posteriore",
    "vehType": "sw",
    "sostituzione": "150.7133333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1325",
    "description": "Luce di posizione POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1326",
    "description": "Luce di posizione POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1401",
    "description": "Spazzole tergicristallo ANT",
    "vehType": "sw",
    "sostituzione": "70.95",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1402",
    "description": "Braccio tergicristallo ANT",
    "vehType": "sw",
    "sostituzione": "63.685",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1403",
    "description": "Braccio tergicristallo ANT DX",
    "vehType": "sw",
    "sostituzione": "51.105",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1404",
    "description": "Braccio tergicristallo ANT SX",
    "vehType": "sw",
    "sostituzione": "51.105",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1405",
    "description": "Spazzola tergicristallo ANT DX",
    "vehType": "sw",
    "sostituzione": "34.45",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1406",
    "description": "Spazzola tergicristallo ANT SX",
    "vehType": "sw",
    "sostituzione": "34.45",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1407",
    "description": "Spazzole tergicristallo",
    "vehType": "sw",
    "sostituzione": "61.7",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1505",
    "description": "Tappo gancio traino paraurti (ANT e POST)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1508",
    "description": "Fisso parafango ant. SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1509",
    "description": "Fisso parafango ant. DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1510",
    "description": "Luce di posizione ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1511",
    "description": "Luce di posizione ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1512",
    "description": "Monobraccio tergicristallo anteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1700",
    "description": "Chiave (seconda chiave)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1702",
    "description": "Manuale d'uso e manutenzione",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1703",
    "description": "Radio (vedi allestimento)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1704",
    "description": "Accendisigari",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1705",
    "description": "Posacenere",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1706",
    "description": "Leva cambio (pomello)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1708",
    "description": "Retrovisore interno",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1709",
    "description": "Comando regolazione retrovisore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1710",
    "description": "Cruscotto (plancia)",
    "vehType": "sw",
    "sostituzione": "978.125",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1713",
    "description": "Poggiatesta sedile singolo",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1720",
    "description": "Cappelliera post.",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1721",
    "description": "Triangolo di emergenza",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1722",
    "description": "Chiave smontaruota",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1723",
    "description": "Crick ruota scorta (martinetto)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1725",
    "description": "Altoparlanti tweeter",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1726",
    "description": "Altoparlante posteriore/sub",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1727",
    "description": "Compressore d'aria",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1728",
    "description": "Maniglia porta ANT interna (SX o DX)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1729",
    "description": "Manovella alzacristalli",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1730",
    "description": "Estintore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1731",
    "description": "Rivestimento vano bagagli LAT",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1732",
    "description": "Ripiano interno copribagagliaio",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1733",
    "description": "Telecomando",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1734",
    "description": "Caricatore CD",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1735",
    "description": "Portabollo/assicurazione",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1736",
    "description": "Moquette",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1737",
    "description": "Tappetini",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1738",
    "description": "Cinture di Sicurezza ",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1739",
    "description": "Rivestimento interno tetto (cielo)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1740",
    "description": "Kit prontosoccorso",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1741",
    "description": "Bullone antifurto",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1742",
    "description": "Plafoniera interna",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1743",
    "description": "Rete fermabagagli",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1745",
    "description": "Cassetto portaoggetti (sportello)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1747",
    "description": "Riparo inferiore CARTER MOTORE",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1748",
    "description": "Airbag passeggero",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1749",
    "description": "Airbag lato guida",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1750",
    "description": "Pulizia interni extra",
    "vehType": "sw",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1751",
    "description": "Poggiabraccia ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1752",
    "description": "Poggiabraccia POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1754",
    "description": "Tunnel centrale completo",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1755",
    "description": "Moquette ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1756",
    "description": "Moquette ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1757",
    "description": "Moquette POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1758",
    "description": "Moquette POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1759",
    "description": "Quadro strumenti",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1760",
    "description": "Pannello porta ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1761",
    "description": "Poggiatesta ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1763",
    "description": "Schienale sedile ANT SX (solo fodera)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1764",
    "description": "Seduta ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1765",
    "description": "Comando alzavetro ANT DX",
    "vehType": "sw",
    "sostituzione": "165.8233333",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1766",
    "description": "Comandoalzavetro ANT SX",
    "vehType": "sw",
    "sostituzione": "165.8233333",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1767",
    "description": "Comandoalzavetro POST DX",
    "vehType": "sw",
    "sostituzione": "151.94",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1768",
    "description": "Comando alzavetro POST SX",
    "vehType": "sw",
    "sostituzione": "151.94",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1770",
    "description": "Pannello porta ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1771",
    "description": "Poggiatesta ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1772",
    "description": "Schienale sedile ANT DX (solo fodera)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1773",
    "description": "Seduta ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1774",
    "description": "Sedile passeggero COMPLETO",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1776",
    "description": "Poggiatesta POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1777",
    "description": "Schienale sedile POST DX (solo fodera)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1778",
    "description": "Seduta sedile POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1779",
    "description": "Schienale sedile POST SX (solo fodera)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1780",
    "description": "Pannello interno portello posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1781",
    "description": "Seduta sedile POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1783",
    "description": "Cintura di sicurezza POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1784",
    "description": "Altoparlante ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1785",
    "description": "Altoparlante POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1786",
    "description": "Altoparlante ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1787",
    "description": "Altoparlante POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1788",
    "description": "Aletta parasole ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1790",
    "description": "Pannello porta POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1791",
    "description": "Impianto di navigazione (solo display)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1792",
    "description": "Poggiabraccia POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1793",
    "description": "Poggiabraccia ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1794",
    "description": "Airbag laterale SX tendina",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1795",
    "description": "Airbag laterale DX tendinta",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1796",
    "description": "Poggiatesta POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1797",
    "description": "Cintura di sicurezza POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1804",
    "description": "Coprimozzo ",
    "vehType": "sw",
    "sostituzione": "10.8",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1811",
    "description": "Disco GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1812",
    "description": "Kit attrezzi",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1813",
    "description": "Giubbino di emergenza",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4001",
    "description": "Cerchione ANT DX in ferro",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "6061",
    "description": "Freccia retrovisore SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "6071",
    "description": "Freccia retrovisore DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "7301",
    "description": "Ruota di scorta in ferro ruotino",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "7302",
    "description": "Ruota di scorta in lega",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11D",
    "description": "Modanatura montante porta post. SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "11WA",
    "description": "Passaruota/Parasassi Int. ANT, parte ANT/POST",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "12D",
    "description": "Modanatura montante porta post. DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "13L1",
    "description": "Luce antinebbia POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "13R1",
    "description": "Luce antinebbia POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "17B1",
    "description": "Sedile POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B2",
    "description": "Seduta POST centrale",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B3",
    "description": "Sedile POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F1",
    "description": "Sedile ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F2",
    "description": "Sedile ANT centrale",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F3",
    "description": "Sedile ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T7",
    "description": "Comandi climatizzatore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U5",
    "description": "Portachiavi",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U6",
    "description": "Specchio di cortesia",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U7",
    "description": "Cintura di sicurezza ANT DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U9",
    "description": "Cintura di sicurezza ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W1",
    "description": "Rivestimento interno (battivaligia)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W2",
    "description": "Aletta parasole ANT SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W4",
    "description": "SD GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W5",
    "description": "Caricabatteria GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W6",
    "description": "Staffa supporto GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W7",
    "description": "Contenitore GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W8",
    "description": "Custodia GPS",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17X1",
    "description": "Cavo di ricarica veicolo elettrico",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "6B",
    "description": "Modanatura montante porta ant. SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "7B",
    "description": "Modanatura montante porta ant. DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "8C",
    "description": "Modanatura montante centrale SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "9C",
    "description": "Modanatura montante centrale DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "3A",
    "description": "Parete laterale esterna SX (truck)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4A",
    "description": "Parete laterale esterna DX (truck)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "81",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "82",
    "description": "Pannello superiore portello posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "83",
    "description": "Pannello inferiore portello posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "122",
    "description": "Pannello superiore porta carico POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "123",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "132",
    "description": "Pannello MONTANTE superiore POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "133",
    "description": "Pannello inferiore POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "134",
    "description": "Pannello MONTANTE superiore POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "135",
    "description": "Pannello inferiore POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "220",
    "description": "Luce di ingombro dx",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "302",
    "description": "Modanatura laterale SX (rifinitura lat.plastica)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "507",
    "description": "Parete divisoria vano di carico",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "709",
    "description": "Specchio retrovisore supplementare",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "711",
    "description": "Pedana di carico DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "803",
    "description": "Cilindretto serratura portello post",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "808",
    "description": "Fascia paracolpi  porta carico  POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "815",
    "description": "Protezione inferiore laterale SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "817",
    "description": "Barra di protezione lato guida",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "903",
    "description": "Serratura porta POST",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "915",
    "description": "Protezione inferiore laterale DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "917",
    "description": "Barra di protezione lato passeggero",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1004",
    "description": "Pedana posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1105",
    "description": "Vetro laterale POST SX truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1110",
    "description": "Pannello laterale interno SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1111",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1113",
    "description": "Pannello interno posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1205",
    "description": "Vetro laterale DX truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1209",
    "description": "Vetro laterale POST DX truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1210",
    "description": "Pannello laterale interno DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1220",
    "description": "Pannello superiore porta carico POST SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1221",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1315",
    "description": "Fascia paracolpi porta carico POST DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1316",
    "description": "Soglia acceso vano carico(Truck)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1501",
    "description": "Pannello interno DX superiore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1502",
    "description": "Pannello interno DX inferiore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1503",
    "description": "Pannello interno SX superiore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1504",
    "description": "Pannello interno SX inferiore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1520",
    "description": "Luce di ingombro sx",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1746",
    "description": "Piano di carico posteiore interno",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1753",
    "description": "Divisorio vano carico",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1799",
    "description": "Unità di controllo interna",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1809",
    "description": "Adesivo Europcar",
    "vehType": "sw",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1810",
    "description": "Deflettore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11CO",
    "description": "Angolare truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "12CO",
    "description": "Angolare truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13L",
    "description": "Angolo SX Paraurti POST solo truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13R",
    "description": "Angolo DX Paraurti POST solo truck",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T1",
    "description": "Terza fila di sedili SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T2",
    "description": "Terza fila di sedili centrale",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T3",
    "description": "Terza fila di sedili DX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U1",
    "description": "Rivestimento verticale coibentato",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U2",
    "description": "Pavimento coibentato",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U3",
    "description": "Rivestimento tetto coibentato",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U4",
    "description": "Comando controllo frigo",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U8",
    "description": "Panca sedile",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W3",
    "description": "Pianale di carico (copertura)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "139",
    "description": "Unità di controllo pedana posteriore",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "402",
    "description": "Modanatura laterale DX (rifinitura lat plastica)",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "611",
    "description": "Pedana di carico SX",
    "vehType": "sw",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1",
    "description": "Paraurti ANT",
    "vehType": "berlina",
    "sostituzione": "632.7266667",
    "lieve": "35.8",
    "medio": "58.2",
    "grave": "81.73333333",
    "#3828db": "1"
  },
  {
    "code": "2",
    "description": "Cofano",
    "vehType": "berlina",
    "sostituzione": "686.8933333",
    "lieve": "65.73333333",
    "medio": "134.3333333",
    "grave": "189.0333333",
    "#3828db": "1"
  },
  {
    "code": "3",
    "description": "Parafango ANT SX",
    "vehType": "berlina",
    "sostituzione": "407.78",
    "lieve": "60.76666667",
    "medio": "125.4666667",
    "grave": "172.3",
    "#ee1313": "1"
  },
  {
    "code": "4",
    "description": "Parafango ANT DX",
    "vehType": "berlina",
    "sostituzione": "407.78",
    "lieve": "60.76666667",
    "medio": "125.4666667",
    "grave": "172.3",
    "#26f719": "1"
  },
  {
    "code": "5",
    "description": "Tetto",
    "vehType": "berlina",
    "sostituzione": "1699.296667",
    "lieve": "135.5",
    "medio": "323.7333333",
    "grave": "542.3",
    "#b1a558": "1"
  },
  {
    "code": "6",
    "description": "Porta ANT SX",
    "vehType": "berlina",
    "sostituzione": "873.82",
    "lieve": "76.7",
    "medio": "160.0333333",
    "grave": "292.2",
    "#db6928": "1"
  },
  {
    "code": "7",
    "description": "Porta ANT DX",
    "vehType": "berlina",
    "sostituzione": "873.82",
    "lieve": "76.7",
    "medio": "160.0333333",
    "grave": "292.2",
    "#3e6928": "1"
  },
  {
    "code": "8",
    "description": "Porta POST SX",
    "vehType": "berlina",
    "sostituzione": "861.2166667",
    "lieve": "73.66666667",
    "medio": "166.3666667",
    "grave": "286.2",
    "#db6928": "1"
  },
  {
    "code": "9",
    "description": "Porta POST DX",
    "vehType": "berlina",
    "sostituzione": "861.2166667",
    "lieve": "73.66666667",
    "medio": "166.3666667",
    "grave": "286.2",
    "#3e6928": "1"
  },
  {
    "code": "10",
    "description": "Lunotto",
    "vehType": "berlina",
    "sostituzione": "527.52",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "11",
    "description": "Parafango POST SX",
    "vehType": "berlina",
    "sostituzione": "1414.44",
    "lieve": "104.4",
    "medio": "239.8333333",
    "grave": "405.4333333",
    "#af28db": "1"
  },
  {
    "code": "12",
    "description": "Parafango POST DX",
    "vehType": "berlina",
    "sostituzione": "1414.44",
    "lieve": "104.4",
    "medio": "239.8333333",
    "grave": "405.4333333",
    "#87a5f0": "1"
  },
  {
    "code": "1010",
    "description": "Portellone POST",
    "vehType": "berlina",
    "sostituzione": "944.9366667",
    "lieve": "72.3",
    "medio": "164.6666667",
    "grave": "281.7",
    "#eaf910": "1"
  },
  {
    "code": "13",
    "description": "Paraurti POST",
    "vehType": "berlina",
    "sostituzione": "550.3066667",
    "lieve": "38.36666667",
    "medio": "56.13333333",
    "grave": "78.23333333",
    "#eaf910": "1"
  },
  {
    "code": "14",
    "description": "Parabrezza",
    "vehType": "berlina",
    "sostituzione": "526.743",
    "lieve": "",
    "medio": "58",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "16",
    "description": "Porta POST laterale DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "31",
    "description": "Cerchione ANT SX in ferro",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "36",
    "description": "Pannelli porte (SX o DX)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "1320",
    "description": "Paraurti centrale posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1L",
    "description": "Paraurti ANT (lato sx)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1L01",
    "description": "Minigonna ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1R",
    "description": "Paraurti ANT (lato dx)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1R01",
    "description": "Minigonna ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "3WA",
    "description": "Arco passaruota ANT SX",
    "vehType": "berlina",
    "sostituzione": "68.9",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "4WA",
    "description": "Arco passaruota ANT DX",
    "vehType": "berlina",
    "sostituzione": "68.9",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "78",
    "description": "Coppa ruota",
    "vehType": "berlina",
    "sostituzione": "41.30666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "80",
    "description": "Coprimozzo ",
    "vehType": "berlina",
    "sostituzione": "23.29",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "84",
    "description": "Portatarga",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1",
    "#eaf910": "1"
  },
  {
    "code": "100",
    "description": "Proiettore ANT DX",
    "vehType": "berlina",
    "sostituzione": "1259.705",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "101",
    "description": "Griglia paraurti ANT",
    "vehType": "berlina",
    "sostituzione": "358.0233333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "102",
    "description": "Spoiler paraurti ANT",
    "vehType": "berlina",
    "sostituzione": "124.155",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "103",
    "description": "Proiettore ANT SX",
    "vehType": "berlina",
    "sostituzione": "1297.136667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "104",
    "description": "Targa ANT",
    "vehType": "berlina",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "105",
    "description": "Griglia ANT (nel cofano o separata)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "106",
    "description": "Stemma ANT",
    "vehType": "berlina",
    "sostituzione": "44.17",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "107",
    "description": "Freccia ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "108",
    "description": "Faro antinebbia SX",
    "vehType": "berlina",
    "sostituzione": "122.225",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "109",
    "description": "Modanatura paraurti ANT (DX o SX)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "110",
    "description": "Freccia ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "111",
    "description": "Faro antinebbia DX",
    "vehType": "berlina",
    "sostituzione": "122.225",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "118",
    "description": "Coppa ruota",
    "vehType": "berlina",
    "sostituzione": "41.30666667",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "126",
    "description": "Interruttore accensione luci",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "130",
    "description": "Luce diurna ANT DX Posizione",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "138",
    "description": "Spazzola tergicristallo posteriore",
    "vehType": "berlina",
    "sostituzione": "26.77333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "140",
    "description": "Luce diurna ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "141",
    "description": "Volante",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "301",
    "description": "Indicatore di direzione SX (laterale parafango)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "306",
    "description": "Pneumatico ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "307",
    "description": "Cerchio in acciaio ANT SX",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "308",
    "description": "Cerchio in lega ANT SX",
    "vehType": "berlina",
    "sostituzione": "591.98",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "401",
    "description": "Indicatore di direzione DX (laterale parafango)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "406",
    "description": "Pneumatico ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "407",
    "description": "Cerchio in acciaio ANT DX",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "408",
    "description": "Cerchio in lega ANT DX",
    "vehType": "berlina",
    "sostituzione": "764.755",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "501",
    "description": "Antenna",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "502",
    "description": "Barre mancorrenti",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "503",
    "description": "Capote COMPLETA",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "504",
    "description": "Tetto apribile (lamierato)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "505",
    "description": "Copertura capote solo tela",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "506",
    "description": "Spoiler portello",
    "vehType": "berlina",
    "sostituzione": "476.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "510",
    "description": "Rivestimento anteriore esterno",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "511",
    "description": "Tetto retraibile (tettuccio senza meccanismo)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "560",
    "description": "Barra tetto SX Modan",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "570",
    "description": "Barra tetto DX Modan",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "601",
    "description": "Sottoporta SX",
    "vehType": "berlina",
    "sostituzione": "572.1733333",
    "lieve": "48.8",
    "medio": "85.8",
    "grave": "140.7",
    "#db6928": "1"
  },
  {
    "code": "602",
    "description": "Modanatura porta ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "603",
    "description": "Serie cilindretti serrature",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "604",
    "description": "Maniglia porta esterna ANT SX",
    "vehType": "berlina",
    "sostituzione": "148.2966667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "605",
    "description": "Vetro scendente ANT SX",
    "vehType": "berlina",
    "sostituzione": "109.0866667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "606",
    "description": "Calotta retrovisore SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "607",
    "description": "Retrovisore lato guida",
    "vehType": "berlina",
    "sostituzione": "293.82",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "609",
    "description": "Vetro fisso ANT SX",
    "vehType": "berlina",
    "sostituzione": "111.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "612",
    "description": "Cristallo retrovisore SX",
    "vehType": "berlina",
    "sostituzione": "413.88",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "701",
    "description": "Sottoporta DX",
    "vehType": "berlina",
    "sostituzione": "572.1733333",
    "lieve": "48.8",
    "medio": "85.8",
    "grave": "140.7",
    "#3e6928": "1"
  },
  {
    "code": "702",
    "description": "Modanatura porta ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "703",
    "description": "Cilindretto serratura porta ant.",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "704",
    "description": "Maniglia porta esterna ANT DX",
    "vehType": "berlina",
    "sostituzione": "148.3766667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "705",
    "description": "Vetro scendente ANT DX",
    "vehType": "berlina",
    "sostituzione": "109.0866667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "706",
    "description": "Calotta retrovisore DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "707",
    "description": "Retrovisore lato passggero",
    "vehType": "berlina",
    "sostituzione": "293.82",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "712",
    "description": "Cristallo retrovisore DX",
    "vehType": "berlina",
    "sostituzione": "413.88",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "713",
    "description": "Vetro fisso ANT DX",
    "vehType": "berlina",
    "sostituzione": "111.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "804",
    "description": "Maniglia porta esterna POST SX",
    "vehType": "berlina",
    "sostituzione": "113.39",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "805",
    "description": "Vetro scendente porta POST SX",
    "vehType": "berlina",
    "sostituzione": "153.4066667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "806",
    "description": "Vetro fisso POST SX",
    "vehType": "berlina",
    "sostituzione": "164.75",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "807",
    "description": "Guarnizione porta POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "811",
    "description": "Pannello porta POST SX",
    "vehType": "berlina",
    "sostituzione": "926.18",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "904",
    "description": "Maniglia porta esterna POST DX",
    "vehType": "berlina",
    "sostituzione": "113.39",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "905",
    "description": "Vetro scendenteporta POST DX",
    "vehType": "berlina",
    "sostituzione": "153.4066667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "906",
    "description": "Vetro fisso POST DX",
    "vehType": "berlina",
    "sostituzione": "164.75",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "907",
    "description": "Guarnizione porta POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "908",
    "description": "Modanatura paraurti POST",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1001",
    "description": "Spazzole tergicristallo POST",
    "vehType": "berlina",
    "sostituzione": "21.135",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1002",
    "description": "Braccio tergicristallo POST",
    "vehType": "berlina",
    "sostituzione": "37.08",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1003",
    "description": "Luce terzo stop",
    "vehType": "berlina",
    "sostituzione": "89.11",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1011",
    "description": "Maniglia apertura portello post",
    "vehType": "berlina",
    "sostituzione": "282.12",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1013",
    "description": "Meccanismo apertura/chiusura capote",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1014",
    "description": "Terminale di scarico (silenz di scarico)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1101",
    "description": "Tappo carburante lato SX",
    "vehType": "berlina",
    "sostituzione": "71.71",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1102",
    "description": "Sportello carburante lato SX",
    "vehType": "berlina",
    "sostituzione": "56.07666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1103",
    "description": "Modanatura parafango ANT ",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "1106",
    "description": "Pneumatico POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1107",
    "description": "Cerchio in acciaio POST SX",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1108",
    "description": "Cerchio in lega POST SX",
    "vehType": "berlina",
    "sostituzione": "591.98",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1112",
    "description": "Sostegno/supporto (ruota scorta)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1114",
    "description": "Cerchione POST SX (ferro)",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1201",
    "description": "Tappo carburante lato DX",
    "vehType": "berlina",
    "sostituzione": "71.71",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1202",
    "description": "Sportello carburante lato DX",
    "vehType": "berlina",
    "sostituzione": "46.035",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1203",
    "description": "Errato rifornimento",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1204",
    "description": "Modanatura parafango POST",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1206",
    "description": "Pneumatico POST DX",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1207",
    "description": "Cerchio in acciaio POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1208",
    "description": "Cerchio in lega POST",
    "vehType": "berlina",
    "sostituzione": "591.98",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1222",
    "description": "Cerchione POST DX (ferro)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1223",
    "description": "Arco passaruota POST DX",
    "vehType": "berlina",
    "sostituzione": "68.245",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1225",
    "description": "Sostegno/supporto",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1301",
    "description": "Griglia paraurti POST",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1302",
    "description": "Spoiler paraurti POST",
    "vehType": "berlina",
    "sostituzione": "133.965",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1303",
    "description": "Luce POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1304",
    "description": "Targa POST",
    "vehType": "berlina",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1305",
    "description": "Griglia paraurti ANT inf.",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1306",
    "description": "Stemma POST",
    "vehType": "berlina",
    "sostituzione": "34.5",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1307",
    "description": "Freccia POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1308",
    "description": "Luce retronebbia posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1309",
    "description": "Gancio di traino smontabile J",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1310",
    "description": "Luce POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1311",
    "description": "Fanale POST SX",
    "vehType": "berlina",
    "sostituzione": "251.7233333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1312",
    "description": "Fanale POST DX",
    "vehType": "berlina",
    "sostituzione": "251.7233333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1313",
    "description": "Freccia POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1314",
    "description": "Luce targa singola",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1317",
    "description": "Gancio di traino",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1322",
    "description": "Serratura portello posteriore",
    "vehType": "berlina",
    "sostituzione": "81.005",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1325",
    "description": "Luce di posizione POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1326",
    "description": "Luce di posizione POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1401",
    "description": "Spazzole tergicristallo ANT",
    "vehType": "berlina",
    "sostituzione": "53.91666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1402",
    "description": "Braccio tergicristallo ANT",
    "vehType": "berlina",
    "sostituzione": "43.725",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1403",
    "description": "Braccio tergicristallo ANT DX",
    "vehType": "berlina",
    "sostituzione": "55.21333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1404",
    "description": "Braccio tergicristallo ANT SX",
    "vehType": "berlina",
    "sostituzione": "55.21333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1405",
    "description": "Spazzola tergicristallo ANT DX",
    "vehType": "berlina",
    "sostituzione": "35.975",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1406",
    "description": "Spazzola tergicristallo ANT SX",
    "vehType": "berlina",
    "sostituzione": "35.975",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1407",
    "description": "Spazzole tergicristallo",
    "vehType": "berlina",
    "sostituzione": "52.925",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1505",
    "description": "Tappo gancio traino paraurti (ANT e POST)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1508",
    "description": "Fisso parafango ant. SX",
    "vehType": "berlina",
    "sostituzione": "111.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1509",
    "description": "Fisso parafango ant. DX",
    "vehType": "berlina",
    "sostituzione": "111.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1510",
    "description": "Luce di posizione ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1511",
    "description": "Luce di posizione ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1512",
    "description": "Monobraccio tergicristallo anteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1700",
    "description": "Chiave (seconda chiave)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1702",
    "description": "Manuale d'uso e manutenzione",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1703",
    "description": "Radio (vedi allestimento)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1704",
    "description": "Accendisigari",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1705",
    "description": "Posacenere",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1706",
    "description": "Leva cambio (pomello)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1708",
    "description": "Retrovisore interno",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1709",
    "description": "Comando regolazione retrovisore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1710",
    "description": "Cruscotto (plancia)",
    "vehType": "berlina",
    "sostituzione": "1097.3",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1713",
    "description": "Poggiatesta sedile singolo",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1720",
    "description": "Cappelliera post.",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1721",
    "description": "Triangolo di emergenza",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1722",
    "description": "Chiave smontaruota",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1723",
    "description": "Crick ruota scorta (martinetto)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1725",
    "description": "Altoparlanti tweeter",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1726",
    "description": "Altoparlante posteriore/sub",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1727",
    "description": "Compressore d'aria",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1728",
    "description": "Maniglia porta ANT interna (SX o DX)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1729",
    "description": "Manovella alzacristalli",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1730",
    "description": "Estintore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1731",
    "description": "Rivestimento vano bagagli LAT",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1732",
    "description": "Ripiano interno copribagagliaio",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1733",
    "description": "Telecomando",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1734",
    "description": "Caricatore CD",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1735",
    "description": "Portabollo/assicurazione",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1736",
    "description": "Moquette",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1737",
    "description": "Tappetini",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1738",
    "description": "Cinture di Sicurezza ",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1739",
    "description": "Rivestimento interno tetto (cielo)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1740",
    "description": "Kit prontosoccorso",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1741",
    "description": "Bullone antifurto",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1742",
    "description": "Plafoniera interna",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1743",
    "description": "Rete fermabagagli",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1745",
    "description": "Cassetto portaoggetti (sportello)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1747",
    "description": "Riparo inferiore CARTER MOTORE",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1748",
    "description": "Airbag passeggero",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1749",
    "description": "Airbag lato guida",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1750",
    "description": "Pulizia interni extra",
    "vehType": "berlina",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1751",
    "description": "Poggiabraccia ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1752",
    "description": "Poggiabraccia POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1754",
    "description": "Tunnel centrale completo",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1755",
    "description": "Moquette ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1756",
    "description": "Moquette ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1757",
    "description": "Moquette POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1758",
    "description": "Moquette POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1759",
    "description": "Quadro strumenti",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1760",
    "description": "Pannello porta ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1761",
    "description": "Poggiatesta ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1763",
    "description": "Schienale sedile ANT SX (solo fodera)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1764",
    "description": "Seduta ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1765",
    "description": "Comando alzavetro ANT DX",
    "vehType": "berlina",
    "sostituzione": "101.73",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1766",
    "description": "Comandoalzavetro ANT SX",
    "vehType": "berlina",
    "sostituzione": "101.73",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1767",
    "description": "Comandoalzavetro POST DX",
    "vehType": "berlina",
    "sostituzione": "130.87",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1768",
    "description": "Comando alzavetro POST SX",
    "vehType": "berlina",
    "sostituzione": "130.87",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1770",
    "description": "Pannello porta ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1771",
    "description": "Poggiatesta ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1772",
    "description": "Schienale sedile ANT DX (solo fodera)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1773",
    "description": "Seduta ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1774",
    "description": "Sedile passeggero COMPLETO",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1776",
    "description": "Poggiatesta POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1777",
    "description": "Schienale sedile POST DX (solo fodera)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1778",
    "description": "Seduta sedile POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1779",
    "description": "Schienale sedile POST SX (solo fodera)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1780",
    "description": "Pannello interno portello posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1781",
    "description": "Seduta sedile POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1783",
    "description": "Cintura di sicurezza POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1784",
    "description": "Altoparlante ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1785",
    "description": "Altoparlante POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1786",
    "description": "Altoparlante ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1787",
    "description": "Altoparlante POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1788",
    "description": "Aletta parasole ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1790",
    "description": "Pannello porta POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1791",
    "description": "Impianto di navigazione (solo display)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1792",
    "description": "Poggiabraccia POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1793",
    "description": "Poggiabraccia ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1794",
    "description": "Airbag laterale SX tendina",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1795",
    "description": "Airbag laterale DX tendinta",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1796",
    "description": "Poggiatesta POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1797",
    "description": "Cintura di sicurezza POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1804",
    "description": "Coprimozzo ",
    "vehType": "berlina",
    "sostituzione": "33.56",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1811",
    "description": "Disco GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1812",
    "description": "Kit attrezzi",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1813",
    "description": "Giubbino di emergenza",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4001",
    "description": "Cerchione ANT DX in ferro",
    "vehType": "berlina",
    "sostituzione": "90.68",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "6061",
    "description": "Freccia retrovisore SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "6071",
    "description": "Freccia retrovisore DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "7301",
    "description": "Ruota di scorta in ferro ruotino",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "7302",
    "description": "Ruota di scorta in lega",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11D",
    "description": "Modanatura montante porta post. SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "11WA",
    "description": "Passaruota/Parasassi Int. ANT, parte ANT/POST",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "12D",
    "description": "Modanatura montante porta post. DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "13L1",
    "description": "Luce antinebbia POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "13R1",
    "description": "Luce antinebbia POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "17B1",
    "description": "Sedile POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B2",
    "description": "Seduta POST centrale",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B3",
    "description": "Sedile POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F1",
    "description": "Sedile ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F2",
    "description": "Sedile ANT centrale",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F3",
    "description": "Sedile ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T7",
    "description": "Comandi climatizzatore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U5",
    "description": "Portachiavi",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U6",
    "description": "Specchio di cortesia",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U7",
    "description": "Cintura di sicurezza ANT DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U9",
    "description": "Cintura di sicurezza ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W1",
    "description": "Rivestimento interno (battivaligia)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W2",
    "description": "Aletta parasole ANT SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W4",
    "description": "SD GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W5",
    "description": "Caricabatteria GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W6",
    "description": "Staffa supporto GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W7",
    "description": "Contenitore GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W8",
    "description": "Custodia GPS",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17X1",
    "description": "Cavo di ricarica veicolo elettrico",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "6B",
    "description": "Modanatura montante porta ant. SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "7B",
    "description": "Modanatura montante porta ant. DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "8C",
    "description": "Modanatura montante centrale SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "9C",
    "description": "Modanatura montante centrale DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "3A",
    "description": "Parete laterale esterna SX (truck)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4A",
    "description": "Parete laterale esterna DX (truck)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "81",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "82",
    "description": "Pannello superiore portello posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "83",
    "description": "Pannello inferiore portello posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "122",
    "description": "Pannello superiore porta carico POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "123",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "132",
    "description": "Pannello MONTANTE superiore POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "133",
    "description": "Pannello inferiore POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "134",
    "description": "Pannello MONTANTE superiore POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "135",
    "description": "Pannello inferiore POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "220",
    "description": "Luce di ingombro dx",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "302",
    "description": "Modanatura laterale SX (rifinitura lat.plastica)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "507",
    "description": "Parete divisoria vano di carico",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "709",
    "description": "Specchio retrovisore supplementare",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "711",
    "description": "Pedana di carico DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "803",
    "description": "Cilindretto serratura portello post",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "808",
    "description": "Fascia paracolpi  porta carico  POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "815",
    "description": "Protezione inferiore laterale SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "817",
    "description": "Barra di protezione lato guida",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "903",
    "description": "Serratura porta POST",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "915",
    "description": "Protezione inferiore laterale DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "917",
    "description": "Barra di protezione lato passeggero",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1004",
    "description": "Pedana posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1105",
    "description": "Vetro laterale POST SX truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1110",
    "description": "Pannello laterale interno SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1111",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1113",
    "description": "Pannello interno posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1205",
    "description": "Vetro laterale DX truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1209",
    "description": "Vetro laterale POST DX truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1210",
    "description": "Pannello laterale interno DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1220",
    "description": "Pannello superiore porta carico POST SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1221",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1315",
    "description": "Fascia paracolpi porta carico POST DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1316",
    "description": "Soglia acceso vano carico(Truck)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1501",
    "description": "Pannello interno DX superiore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1502",
    "description": "Pannello interno DX inferiore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1503",
    "description": "Pannello interno SX superiore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1504",
    "description": "Pannello interno SX inferiore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1520",
    "description": "Luce di ingombro sx",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1746",
    "description": "Piano di carico posteiore interno",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1753",
    "description": "Divisorio vano carico",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1799",
    "description": "Unità di controllo interna",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1809",
    "description": "Adesivo Europcar",
    "vehType": "berlina",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1810",
    "description": "Deflettore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11CO",
    "description": "Angolare truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "12CO",
    "description": "Angolare truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13L",
    "description": "Angolo SX Paraurti POST solo truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13R",
    "description": "Angolo DX Paraurti POST solo truck",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T1",
    "description": "Terza fila di sedili SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T2",
    "description": "Terza fila di sedili centrale",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T3",
    "description": "Terza fila di sedili DX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U1",
    "description": "Rivestimento verticale coibentato",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U2",
    "description": "Pavimento coibentato",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U3",
    "description": "Rivestimento tetto coibentato",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U4",
    "description": "Comando controllo frigo",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U8",
    "description": "Panca sedile",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W3",
    "description": "Pianale di carico (copertura)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "139",
    "description": "Unità di controllo pedana posteriore",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "402",
    "description": "Modanatura laterale DX (rifinitura lat plastica)",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "611",
    "description": "Pedana di carico SX",
    "vehType": "berlina",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1",
    "description": "Paraurti ANT",
    "vehType": "util",
    "sostituzione": "439.55",
    "lieve": "37.4",
    "medio": "62.4",
    "grave": "92.55",
    "#3828db": "1"
  },
  {
    "code": "2",
    "description": "Cofano",
    "vehType": "util",
    "sostituzione": "661.8166667",
    "lieve": "44.03333333",
    "medio": "95.86666667",
    "grave": "153.3",
    "#3828db": "1"
  },
  {
    "code": "3",
    "description": "Parafango ANT SX",
    "vehType": "util",
    "sostituzione": "319.8366667",
    "lieve": "45.13333333",
    "medio": "91.93333333",
    "grave": "151.8666667",
    "#ee1313": "1"
  },
  {
    "code": "4",
    "description": "Parafango ANT DX",
    "vehType": "util",
    "sostituzione": "319.8366667",
    "lieve": "45.13333333",
    "medio": "91.93333333",
    "grave": "151.8666667",
    "#26f719": "1"
  },
  {
    "code": "5",
    "description": "Tetto",
    "vehType": "util",
    "sostituzione": "1407.79",
    "lieve": "103.3666667",
    "medio": "260.3666667",
    "grave": "433.4333333",
    "#b1a558": "1"
  },
  {
    "code": "6",
    "description": "Porta ANT SX",
    "vehType": "util",
    "sostituzione": "722.89",
    "lieve": "60.83333333",
    "medio": "139.8666667",
    "grave": "239.2",
    "#db6928": "1"
  },
  {
    "code": "7",
    "description": "Porta ANT DX",
    "vehType": "util",
    "sostituzione": "722.89",
    "lieve": "60.83333333",
    "medio": "139.8666667",
    "grave": "239.2",
    "#3e6928": "1"
  },
  {
    "code": "8",
    "description": "Porta POST SX",
    "vehType": "util",
    "sostituzione": "799.515",
    "lieve": "70.8",
    "medio": "160.2",
    "grave": "275.5",
    "#db6928": "1"
  },
  {
    "code": "9",
    "description": "Porta POST DX",
    "vehType": "util",
    "sostituzione": "799.515",
    "lieve": "70.8",
    "medio": "160.2",
    "grave": "275.5",
    "#3e6928": "1"
  },
  {
    "code": "10",
    "description": "Lunotto",
    "vehType": "util",
    "sostituzione": "438.49",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "11",
    "description": "Parafango POST SX",
    "vehType": "util",
    "sostituzione": "987.07",
    "lieve": "86.36666667",
    "medio": "209.5333333",
    "grave": "359.4333333",
    "#af28db": "1"
  },
  {
    "code": "12",
    "description": "Parafango POST DX",
    "vehType": "util",
    "sostituzione": "987.07",
    "lieve": "86.36666667",
    "medio": "209.5333333",
    "grave": "359.4333333",
    "#87a5f0": "1"
  },
  {
    "code": "1010",
    "description": "Portellone POST",
    "vehType": "util",
    "sostituzione": "720.3533333",
    "lieve": "49.03333333",
    "medio": "110.4666667",
    "grave": "190.2",
    "#eaf910": "1"
  },
  {
    "code": "13",
    "description": "Paraurti POST",
    "vehType": "util",
    "sostituzione": "447.725",
    "lieve": "43.4",
    "medio": "66.35",
    "grave": "88",
    "#eaf910": "1"
  },
  {
    "code": "14",
    "description": "Parabrezza",
    "vehType": "util",
    "sostituzione": "383.964",
    "lieve": "",
    "medio": "59.33333333",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "16",
    "description": "Porta POST laterale DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "31",
    "description": "Cerchione ANT SX in ferro",
    "vehType": "util",
    "sostituzione": "81.54",
    "lieve": "",
    "medio": "62",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "36",
    "description": "Pannelli porte (SX o DX)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "1320",
    "description": "Paraurti centrale posteriore",
    "vehType": "util",
    "sostituzione": "93.01",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1L",
    "description": "Paraurti ANT (lato sx)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1L01",
    "description": "Minigonna ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1R",
    "description": "Paraurti ANT (lato dx)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1R01",
    "description": "Minigonna ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "3WA",
    "description": "Arco passaruota ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "4WA",
    "description": "Arco passaruota ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "78",
    "description": "Coppa ruota",
    "vehType": "util",
    "sostituzione": "42.71333333",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "80",
    "description": "Coprimozzo ",
    "vehType": "util",
    "sostituzione": "9.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1",
    "#002529": "1",
    "#00254e": "1",
    "#004e4e": "1"
  },
  {
    "code": "84",
    "description": "Portatarga",
    "vehType": "util",
    "sostituzione": "39.3",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1",
    "#eaf910": "1"
  },
  {
    "code": "100",
    "description": "Proiettore ANT DX",
    "vehType": "util",
    "sostituzione": "520.3666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "101",
    "description": "Griglia paraurti ANT",
    "vehType": "util",
    "sostituzione": "144.295",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "102",
    "description": "Spoiler paraurti ANT",
    "vehType": "util",
    "sostituzione": "69.175",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "103",
    "description": "Proiettore ANT SX",
    "vehType": "util",
    "sostituzione": "520.3666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "104",
    "description": "Targa ANT",
    "vehType": "util",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "105",
    "description": "Griglia ANT (nel cofano o separata)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "106",
    "description": "Stemma ANT",
    "vehType": "util",
    "sostituzione": "48.56",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "107",
    "description": "Freccia ANT SX",
    "vehType": "util",
    "sostituzione": "36.95",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "108",
    "description": "Faro antinebbia SX",
    "vehType": "util",
    "sostituzione": "92.28666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "109",
    "description": "Modanatura paraurti ANT (DX o SX)",
    "vehType": "util",
    "sostituzione": "431.5",
    "lieve": "24.4",
    "medio": "65",
    "grave": "100.4",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "110",
    "description": "Freccia ANT DX",
    "vehType": "util",
    "sostituzione": "36.95",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "111",
    "description": "Faro antinebbia DX",
    "vehType": "util",
    "sostituzione": "92.28666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "118",
    "description": "Coppa ruota",
    "vehType": "util",
    "sostituzione": "42.34",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "126",
    "description": "Interruttore accensione luci",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "130",
    "description": "Luce diurna ANT DX Posizione",
    "vehType": "util",
    "sostituzione": "77.22666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "138",
    "description": "Spazzola tergicristallo posteriore",
    "vehType": "util",
    "sostituzione": "53.75",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "140",
    "description": "Luce diurna ANT SX",
    "vehType": "util",
    "sostituzione": "77.22666667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "141",
    "description": "Volante",
    "vehType": "util",
    "sostituzione": "285.34",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "301",
    "description": "Indicatore di direzione SX (laterale parafango)",
    "vehType": "util",
    "sostituzione": "36.95",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "306",
    "description": "Pneumatico ANT SX",
    "vehType": "util",
    "sostituzione": "110.5",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "307",
    "description": "Cerchio in acciaio ANT SX",
    "vehType": "util",
    "sostituzione": "104.6966667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "308",
    "description": "Cerchio in lega ANT SX",
    "vehType": "util",
    "sostituzione": "334.24",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "401",
    "description": "Indicatore di direzione DX (laterale parafango)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "406",
    "description": "Pneumatico ANT DX",
    "vehType": "util",
    "sostituzione": "110.5",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "407",
    "description": "Cerchio in acciaio ANT DX",
    "vehType": "util",
    "sostituzione": "104.6966667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "408",
    "description": "Cerchio in lega ANT DX",
    "vehType": "util",
    "sostituzione": "334.24",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#002529": "1"
  },
  {
    "code": "501",
    "description": "Antenna",
    "vehType": "util",
    "sostituzione": "53.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "502",
    "description": "Barre mancorrenti",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "503",
    "description": "Capote COMPLETA",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "504",
    "description": "Tetto apribile (lamierato)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "505",
    "description": "Copertura capote solo tela",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "506",
    "description": "Spoiler portello",
    "vehType": "util",
    "sostituzione": "110.86",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "510",
    "description": "Rivestimento anteriore esterno",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "511",
    "description": "Tetto retraibile (tettuccio senza meccanismo)",
    "vehType": "util",
    "sostituzione": "988",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "560",
    "description": "Barra tetto SX Modan",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "570",
    "description": "Barra tetto DX Modan",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#b1a558": "1"
  },
  {
    "code": "601",
    "description": "Sottoporta SX",
    "vehType": "util",
    "sostituzione": "145.94",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "602",
    "description": "Modanatura porta ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "603",
    "description": "Serie cilindretti serrature",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1",
    "#3e6928": "1"
  },
  {
    "code": "604",
    "description": "Maniglia porta esterna ANT SX",
    "vehType": "util",
    "sostituzione": "173.38",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "605",
    "description": "Vetro scendente ANT SX",
    "vehType": "util",
    "sostituzione": "144.79",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "606",
    "description": "Calotta retrovisore SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "607",
    "description": "Retrovisore lato guida",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "609",
    "description": "Vetro fisso ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "612",
    "description": "Cristallo retrovisore SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "701",
    "description": "Sottoporta DX",
    "vehType": "util",
    "sostituzione": "145.94",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "702",
    "description": "Modanatura porta ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "703",
    "description": "Cilindretto serratura porta ant.",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "704",
    "description": "Maniglia porta esterna ANT DX",
    "vehType": "util",
    "sostituzione": "173.38",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "705",
    "description": "Vetro scendente ANT DX",
    "vehType": "util",
    "sostituzione": "144.79",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "706",
    "description": "Calotta retrovisore DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "707",
    "description": "Retrovisore lato passggero",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "712",
    "description": "Cristallo retrovisore DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "713",
    "description": "Vetro fisso ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "804",
    "description": "Maniglia porta esterna POST SX",
    "vehType": "util",
    "sostituzione": "94.18",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "805",
    "description": "Vetro scendente porta POST SX",
    "vehType": "util",
    "sostituzione": "147.135",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "806",
    "description": "Vetro fisso POST SX",
    "vehType": "util",
    "sostituzione": "180.98",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "807",
    "description": "Guarnizione porta POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "811",
    "description": "Pannello porta POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "904",
    "description": "Maniglia porta esterna POST DX",
    "vehType": "util",
    "sostituzione": "94.18",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "905",
    "description": "Vetro scendenteporta POST DX",
    "vehType": "util",
    "sostituzione": "154.885",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "906",
    "description": "Vetro fisso POST DX",
    "vehType": "util",
    "sostituzione": "180.98",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "907",
    "description": "Guarnizione porta POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "908",
    "description": "Modanatura paraurti POST",
    "vehType": "util",
    "sostituzione": "265.665",
    "lieve": "24.4",
    "medio": "65",
    "grave": "100.4",
    "#eaf910": "1"
  },
  {
    "code": "1001",
    "description": "Spazzole tergicristallo POST",
    "vehType": "util",
    "sostituzione": "23.66",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1002",
    "description": "Braccio tergicristallo POST",
    "vehType": "util",
    "sostituzione": "34.9",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1003",
    "description": "Luce terzo stop",
    "vehType": "util",
    "sostituzione": "77.51",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1011",
    "description": "Maniglia apertura portello post",
    "vehType": "util",
    "sostituzione": "136.75",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1013",
    "description": "Meccanismo apertura/chiusura capote",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1014",
    "description": "Terminale di scarico (silenz di scarico)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1101",
    "description": "Tappo carburante lato SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1102",
    "description": "Sportello carburante lato SX",
    "vehType": "util",
    "sostituzione": "55.66",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "1103",
    "description": "Modanatura parafango ANT ",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "1106",
    "description": "Pneumatico POST SX",
    "vehType": "util",
    "sostituzione": "110.5",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1107",
    "description": "Cerchio in acciaio POST SX",
    "vehType": "util",
    "sostituzione": "104.6966667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1108",
    "description": "Cerchio in lega POST SX",
    "vehType": "util",
    "sostituzione": "334.24",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1112",
    "description": "Sostegno/supporto (ruota scorta)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1114",
    "description": "Cerchione POST SX (ferro)",
    "vehType": "util",
    "sostituzione": "81.54",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#00254e": "1"
  },
  {
    "code": "1201",
    "description": "Tappo carburante lato DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1202",
    "description": "Sportello carburante lato DX",
    "vehType": "util",
    "sostituzione": "28.15",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "1203",
    "description": "Errato rifornimento",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1204",
    "description": "Modanatura parafango POST",
    "vehType": "util",
    "sostituzione": "74.57",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1206",
    "description": "Pneumatico POST DX",
    "vehType": "util",
    "sostituzione": "110.5",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1207",
    "description": "Cerchio in acciaio POST DX",
    "vehType": "util",
    "sostituzione": "104.6966667",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1208",
    "description": "Cerchio in lega POST",
    "vehType": "util",
    "sostituzione": "334.24",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1222",
    "description": "Cerchione POST DX (ferro)",
    "vehType": "util",
    "sostituzione": "83.08",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#004e4e": "1"
  },
  {
    "code": "1223",
    "description": "Arco passaruota POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1225",
    "description": "Sostegno/supporto",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1301",
    "description": "Griglia paraurti POST",
    "vehType": "util",
    "sostituzione": "431.5",
    "lieve": "24.4",
    "medio": "65",
    "grave": "100.4",
    "#eaf910": "1"
  },
  {
    "code": "1302",
    "description": "Spoiler paraurti POST",
    "vehType": "util",
    "sostituzione": "293.89",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1303",
    "description": "Luce POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1304",
    "description": "Targa POST",
    "vehType": "util",
    "sostituzione": "340",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1305",
    "description": "Griglia paraurti ANT inf.",
    "vehType": "util",
    "sostituzione": "383.49",
    "lieve": "20.8",
    "medio": "56.6",
    "grave": "84.8",
    "#3828db": "1"
  },
  {
    "code": "1306",
    "description": "Stemma POST",
    "vehType": "util",
    "sostituzione": "45.83",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1307",
    "description": "Freccia POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1308",
    "description": "Luce retronebbia posteriore",
    "vehType": "util",
    "sostituzione": "36.87",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1309",
    "description": "Gancio di traino smontabile J",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1310",
    "description": "Luce POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1311",
    "description": "Fanale POST SX",
    "vehType": "util",
    "sostituzione": "547.845",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1312",
    "description": "Fanale POST DX",
    "vehType": "util",
    "sostituzione": "547.845",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1313",
    "description": "Freccia POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1314",
    "description": "Luce targa singola",
    "vehType": "util",
    "sostituzione": "22.69",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1317",
    "description": "Gancio di traino",
    "vehType": "util",
    "sostituzione": "119.615",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1322",
    "description": "Serratura portello posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#eaf910": "1"
  },
  {
    "code": "1325",
    "description": "Luce di posizione POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "1326",
    "description": "Luce di posizione POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "1401",
    "description": "Spazzole tergicristallo ANT",
    "vehType": "util",
    "sostituzione": "49.56",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1402",
    "description": "Braccio tergicristallo ANT",
    "vehType": "util",
    "sostituzione": "39.23",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1403",
    "description": "Braccio tergicristallo ANT DX",
    "vehType": "util",
    "sostituzione": "56.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1404",
    "description": "Braccio tergicristallo ANT SX",
    "vehType": "util",
    "sostituzione": "56.91",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1405",
    "description": "Spazzola tergicristallo ANT DX",
    "vehType": "util",
    "sostituzione": "24.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1406",
    "description": "Spazzola tergicristallo ANT SX",
    "vehType": "util",
    "sostituzione": "24.65",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1407",
    "description": "Spazzole tergicristallo",
    "vehType": "util",
    "sostituzione": "51.05",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1505",
    "description": "Tappo gancio traino paraurti (ANT e POST)",
    "vehType": "util",
    "sostituzione": "50.13",
    "lieve": "",
    "medio": "49",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1",
    "#af28db": "1",
    "#eaf910": "1",
    "#87a5f0": "1"
  },
  {
    "code": "1508",
    "description": "Fisso parafango ant. SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1509",
    "description": "Fisso parafango ant. DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1510",
    "description": "Luce di posizione ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "1511",
    "description": "Luce di posizione ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "1512",
    "description": "Monobraccio tergicristallo anteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3828db": "1"
  },
  {
    "code": "1700",
    "description": "Chiave (seconda chiave)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1702",
    "description": "Manuale d'uso e manutenzione",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1703",
    "description": "Radio (vedi allestimento)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1704",
    "description": "Accendisigari",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1705",
    "description": "Posacenere",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1706",
    "description": "Leva cambio (pomello)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1708",
    "description": "Retrovisore interno",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1709",
    "description": "Comando regolazione retrovisore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1710",
    "description": "Cruscotto (plancia)",
    "vehType": "util",
    "sostituzione": "1237.83",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1713",
    "description": "Poggiatesta sedile singolo",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1720",
    "description": "Cappelliera post.",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1721",
    "description": "Triangolo di emergenza",
    "vehType": "util",
    "sostituzione": "14.35",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1722",
    "description": "Chiave smontaruota",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1723",
    "description": "Crick ruota scorta (martinetto)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1725",
    "description": "Altoparlanti tweeter",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1726",
    "description": "Altoparlante posteriore/sub",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1727",
    "description": "Compressore d'aria",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1728",
    "description": "Maniglia porta ANT interna (SX o DX)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1729",
    "description": "Manovella alzacristalli",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1730",
    "description": "Estintore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1731",
    "description": "Rivestimento vano bagagli LAT",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1732",
    "description": "Ripiano interno copribagagliaio",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1733",
    "description": "Telecomando",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1734",
    "description": "Caricatore CD",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1735",
    "description": "Portabollo/assicurazione",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1736",
    "description": "Moquette",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1737",
    "description": "Tappetini",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1738",
    "description": "Cinture di Sicurezza ",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1739",
    "description": "Rivestimento interno tetto (cielo)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1740",
    "description": "Kit prontosoccorso",
    "vehType": "util",
    "sostituzione": "23.74",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1741",
    "description": "Bullone antifurto",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1742",
    "description": "Plafoniera interna",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1743",
    "description": "Rete fermabagagli",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1745",
    "description": "Cassetto portaoggetti (sportello)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1747",
    "description": "Riparo inferiore CARTER MOTORE",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1748",
    "description": "Airbag passeggero",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1749",
    "description": "Airbag lato guida",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1750",
    "description": "Pulizia interni extra",
    "vehType": "util",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1751",
    "description": "Poggiabraccia ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1752",
    "description": "Poggiabraccia POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1754",
    "description": "Tunnel centrale completo",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1755",
    "description": "Moquette ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1756",
    "description": "Moquette ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1757",
    "description": "Moquette POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1758",
    "description": "Moquette POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1759",
    "description": "Quadro strumenti",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1760",
    "description": "Pannello porta ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1761",
    "description": "Poggiatesta ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1763",
    "description": "Schienale sedile ANT SX (solo fodera)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1764",
    "description": "Seduta ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1765",
    "description": "Comando alzavetro ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1766",
    "description": "Comandoalzavetro ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1767",
    "description": "Comandoalzavetro POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1768",
    "description": "Comando alzavetro POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1770",
    "description": "Pannello porta ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1771",
    "description": "Poggiatesta ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1772",
    "description": "Schienale sedile ANT DX (solo fodera)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1773",
    "description": "Seduta ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1774",
    "description": "Sedile passeggero COMPLETO",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1776",
    "description": "Poggiatesta POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1777",
    "description": "Schienale sedile POST DX (solo fodera)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1778",
    "description": "Seduta sedile POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1779",
    "description": "Schienale sedile POST SX (solo fodera)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1780",
    "description": "Pannello interno portello posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1781",
    "description": "Seduta sedile POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1783",
    "description": "Cintura di sicurezza POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1784",
    "description": "Altoparlante ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1785",
    "description": "Altoparlante POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1786",
    "description": "Altoparlante ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1787",
    "description": "Altoparlante POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1788",
    "description": "Aletta parasole ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1790",
    "description": "Pannello porta POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1791",
    "description": "Impianto di navigazione (solo display)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1792",
    "description": "Poggiabraccia POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1793",
    "description": "Poggiabraccia ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1794",
    "description": "Airbag laterale SX tendina",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1795",
    "description": "Airbag laterale DX tendinta",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1796",
    "description": "Poggiatesta POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1797",
    "description": "Cintura di sicurezza POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1804",
    "description": "Coprimozzo ",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1811",
    "description": "Disco GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1812",
    "description": "Kit attrezzi",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1813",
    "description": "Giubbino di emergenza",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4001",
    "description": "Cerchione ANT DX in ferro",
    "vehType": "util",
    "sostituzione": "83.08",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#051616": "1"
  },
  {
    "code": "6061",
    "description": "Freccia retrovisore SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1"
  },
  {
    "code": "6071",
    "description": "Freccia retrovisore DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#26f719": "1"
  },
  {
    "code": "7301",
    "description": "Ruota di scorta in ferro ruotino",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "7302",
    "description": "Ruota di scorta in lega",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11D",
    "description": "Modanatura montante porta post. SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "11WA",
    "description": "Passaruota/Parasassi Int. ANT, parte ANT/POST",
    "vehType": "util",
    "sostituzione": "72.34",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#ee1313": "1",
    "#26f719": "1"
  },
  {
    "code": "12D",
    "description": "Modanatura montante porta post. DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "13L1",
    "description": "Luce antinebbia POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#af28db": "1"
  },
  {
    "code": "13R1",
    "description": "Luce antinebbia POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#87a5f0": "1"
  },
  {
    "code": "17B1",
    "description": "Sedile POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B2",
    "description": "Seduta POST centrale",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17B3",
    "description": "Sedile POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F1",
    "description": "Sedile ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F2",
    "description": "Sedile ANT centrale",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17F3",
    "description": "Sedile ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T7",
    "description": "Comandi climatizzatore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U5",
    "description": "Portachiavi",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U6",
    "description": "Specchio di cortesia",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U7",
    "description": "Cintura di sicurezza ANT DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U9",
    "description": "Cintura di sicurezza ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W1",
    "description": "Rivestimento interno (battivaligia)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W2",
    "description": "Aletta parasole ANT SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W4",
    "description": "SD GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W5",
    "description": "Caricabatteria GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W6",
    "description": "Staffa supporto GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W7",
    "description": "Contenitore GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W8",
    "description": "Custodia GPS",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17X1",
    "description": "Cavo di ricarica veicolo elettrico",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "6B",
    "description": "Modanatura montante porta ant. SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "7B",
    "description": "Modanatura montante porta ant. DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "8C",
    "description": "Modanatura montante centrale SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#db6928": "1"
  },
  {
    "code": "9C",
    "description": "Modanatura montante centrale DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": "",
    "#3e6928": "1"
  },
  {
    "code": "3A",
    "description": "Parete laterale esterna SX (truck)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "4A",
    "description": "Parete laterale esterna DX (truck)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "81",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "82",
    "description": "Pannello superiore portello posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "83",
    "description": "Pannello inferiore portello posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "122",
    "description": "Pannello superiore porta carico POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "123",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "132",
    "description": "Pannello MONTANTE superiore POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "133",
    "description": "Pannello inferiore POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "134",
    "description": "Pannello MONTANTE superiore POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "135",
    "description": "Pannello inferiore POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "220",
    "description": "Luce di ingombro dx",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "302",
    "description": "Modanatura laterale SX (rifinitura lat.plastica)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "507",
    "description": "Parete divisoria vano di carico",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "709",
    "description": "Specchio retrovisore supplementare",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "711",
    "description": "Pedana di carico DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "803",
    "description": "Cilindretto serratura portello post",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "808",
    "description": "Fascia paracolpi  porta carico  POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "815",
    "description": "Protezione inferiore laterale SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "817",
    "description": "Barra di protezione lato guida",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "903",
    "description": "Serratura porta POST",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "915",
    "description": "Protezione inferiore laterale DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "917",
    "description": "Barra di protezione lato passeggero",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1004",
    "description": "Pedana posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1105",
    "description": "Vetro laterale POST SX truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1110",
    "description": "Pannello laterale interno SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1111",
    "description": "Pannello inferiore porta carico POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1113",
    "description": "Pannello interno posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1205",
    "description": "Vetro laterale DX truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1209",
    "description": "Vetro laterale POST DX truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1210",
    "description": "Pannello laterale interno DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1220",
    "description": "Pannello superiore porta carico POST SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1221",
    "description": "Pannello inferiore porta carico POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1315",
    "description": "Fascia paracolpi porta carico POST DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1316",
    "description": "Soglia acceso vano carico(Truck)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1501",
    "description": "Pannello interno DX superiore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1502",
    "description": "Pannello interno DX inferiore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1503",
    "description": "Pannello interno SX superiore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1504",
    "description": "Pannello interno SX inferiore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1520",
    "description": "Luce di ingombro sx",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1746",
    "description": "Piano di carico posteiore interno",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1753",
    "description": "Divisorio vano carico",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1799",
    "description": "Unità di controllo interna",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1809",
    "description": "Adesivo Europcar",
    "vehType": "util",
    "sostituzione": "100",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "1810",
    "description": "Deflettore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "11CO",
    "description": "Angolare truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "12CO",
    "description": "Angolare truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13L",
    "description": "Angolo SX Paraurti POST solo truck",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "13R",
    "description": "Angolo DX Paraurti POST solo truck",
    "vehType": "util",
    "sostituzione": "7.63",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T1",
    "description": "Terza fila di sedili SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T2",
    "description": "Terza fila di sedili centrale",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17T3",
    "description": "Terza fila di sedili DX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U1",
    "description": "Rivestimento verticale coibentato",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U2",
    "description": "Pavimento coibentato",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U3",
    "description": "Rivestimento tetto coibentato",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U4",
    "description": "Comando controllo frigo",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17U8",
    "description": "Panca sedile",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "17W3",
    "description": "Pianale di carico (copertura)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "139",
    "description": "Unità di controllo pedana posteriore",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "402",
    "description": "Modanatura laterale DX (rifinitura lat plastica)",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  },
  {
    "code": "611",
    "description": "Pedana di carico SX",
    "vehType": "util",
    "sostituzione": "",
    "lieve": "",
    "medio": "",
    "grave": ""
  }
]