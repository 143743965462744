import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
//import { Facebook } from '@ionic-native/facebook/ngx';
import { FacebookService, InitParams, LoginOptions } from 'ngx-facebook';
import { LocalesService } from './locales.service';
import { MysqlService } from './mysql.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookServiceService {

  perms = [
    'email',
    'public_profile',
    'pages_show_list',
    'pages_manage_metadata',
    'pages_manage_posts',
    'pages_read_engagement',
    'user_birthday'
  ]

  URL_BASE = 'https://graph.facebook.com/v11.0'


  constructor(
    private fb: FacebookService,
    private db: AngularFirestore,
    private ls: LocalesService,
  ) {

    let initParams: InitParams = {
      appId: '517214065474477',
      // xfbml: true,
      version: 'v11.0',
    };

    fb.init(initParams);

  }





  async publish(item) {

    let token = await this.getToken()

    let body = {
      message: 'Ecco un veicolo ispezionato con UXpert!!!',
      link: 'https://xpert.ggroup.cloud/api/api.php?cmd=verycar_report_html&lang=' + this.ls.preferredLanguage + '&id=' + item.id,
      access_token: token
    }

    let q = `?message=${body.message}&access_token=${body.access_token}`

    this.fb.api('103128128716141/feed/', "post", body)
      .then(res => {

        this.db.collection('verycar').doc(item.id).set({
          post: {
            postId: res,
            data: new Date()
          },
          needSync: true
        }, { merge: true })

          .then(res => {
            alert('Annuncio Pubblicato su Facebook')
          })

          .catch(err => alert('ERRORE SALVATAGGIO ' + JSON.stringify(err)))

      })



      .catch(err => 'err' + alert(JSON.stringify(err)))






  }

  async publishHome(item) {

    let token = await this.getToken()

    let body = {
      message: 'Ecco un immobile in vendita su Uxpert!!!',
      link: 'https://xpert.ggroup.cloud/api/api.php?cmd=veryhome_report_html&lang=' + this.ls.preferredLanguage + '&id=' + item.id,
      access_token: token
    }

    let q = `?message=${body.message}&access_token=${body.access_token}`

    this.fb.api('103128128716141/feed/', "post", body)
      .then(res => {

        this.db.collection('veryhome').doc(item.id).set({
          post: {
            postId: res,
            data: new Date()
          },
          needSync: true
        }, { merge: true })

          .then(res => {
            alert('Annuncio Pubblicato su Facebook')
          })

          .catch(err => alert('ERRORE SALVATAGGIO ' + JSON.stringify(err)))

      })



      .catch(err => 'err' + alert(JSON.stringify(err)))






  }

  async getLikes(id) {

    let token = await this.getToken()
    return this.fb.api(`${id}/likes/?access_token=${token}&summary=total_count`, "get")

  }






  async getToken(): Promise<string> {
   // return 'EAAHWZA0ilO60BAOxUcnBPVm23Cqm1Kuoy9Sk88jVZAN7PcZCLWZASwAOcsiFCfvSZBHWzaoc4pvpeqHJusCxwtYDjW2ObMRB1dvrnPO6EZAi1WaxjHM2saK9rgEb2ZBOuznVXRKoJ1dwNKEiK7LScPHiAQZCoMMAKixjZCaXOCPmy9xO4bw5ovDnsZBhpLLXw0KLP8mPyl0J95gHUzhvg9mBto'
    return 'EAAHWZA0ilO60BAFXzh5kZAUN6gh4rrgvK88dR0eR7V65v1vhPL1pB3NgB4CLfE3rW4FUdjkHRZADnDmoMSpKz6XngmgBfQUmqSm0UEhwQZBY065O1IquNnf8fhigMe9TVLcUT2q0TAoZCCGQ4Xazb6VDzeTBzVc4RAy5BZBoDsEnQOnlCjkgB3e7aZCyZCslGXgZD'

    let s = await this.fb.getLoginStatus()

    //alert(JSON.stringify(s))
    if (s.status == 'connectedx') {

      let r = await this.fb.getAuthResponse()

      return r.accessToken

    } else {


      let opts: LoginOptions = {
        auth_type: 'rerequest',
        return_scopes: true,
        scope: this.perms.join(',')
      }
      let res = await this.fb.login(opts)

      return res.authResponse.accessToken

    }



  }

}
