import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthProviderService {

  authState;

  constructor(public afAuth: AngularFireAuth) {

    afAuth.authState.subscribe(res => {
      this.authState = res;
    })

  }

  

}
