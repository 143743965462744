import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { IVerycar, IOfferta } from '../verycar/verycar.page';
import { Chart } from 'chart.js';
import { ModalController, NavParams, NavController, Platform, AlertController } from '@ionic/angular';
import { MysqlService } from 'src/app/services/mysql.service';
import { IChat } from '../../chat-list/chat-list.page';
import { AngularFireAuth } from 'angularfire2/auth';
import { IProfile } from '../../profile/profile.page';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { HttpClient } from '@angular/common/http';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import * as moment from 'moment';
import { Instagram } from '@ionic-native/instagram/ngx';
import { dummy } from 'src/app/components/blueprint/blueprint.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FacebookServiceService } from 'src/app/services/facebook-service.service';
import { LocalesService } from 'src/app/services/locales.service';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  bookmarks = new Bookmarks()

  id
  me
  s
  item: IVerycar
  park
  likes = 0
  sez1 = [];
  sez2 = []
  sez3 = []
  sez4 = []
  sez5 = []
  sez7 = []
  chart

  values = []
  category
  categoryPhoto = '360'
  cost
  shwtel
  now: Date
  scad: Date

  ready = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private db: AngularFirestore,
    public modalController: ModalController,
    private navCtrl: NavController,
    private mysql: MysqlService,
    private instagram: Instagram,
    private userService: AngularFireAuth,
    private callNumber: CallNumber,
    private platform: Platform,
    private alertController: AlertController,
    private http: HttpClient,
    private iab: InAppBrowser,
    private socialSharing: SocialSharing,
    private facebook: FacebookServiceService,
    private ls: LocalesService,

  ) {

    userService.authState.subscribe(res => {
      this.me = res.email
    })

  }

  async publish() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Conferma',
      message: this.ls.getString('fb_share'),
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'SI',
          handler: () => {
            this.facebook.publish(this.item)
          }
        }
      ]
    });

    await alert.present();

  }



  ngOnInit() {
    this.category = "datas"
    this.now = new Date()


    this.loadUsers()
  }

  async segnalaAnnuncio() {

    const modal = await this.modalController.create({
      component: SegnalazioneAnniuncioModalComponent,
      componentProps: {

        id: this.item.id,
        plate: this.item.plate

      }
    });

    return await modal.present();

  }

  getFilteredDmg(pag) {
    return this.item.damages.filter(el => el.pag == pag)
  }

  loadLikes() {

    console.clear()
    console.log(this.item)


    this.facebook.getLikes(this.item.post.postId.id).then(res => {
      console.log(res)

      this.likes = res.summary.total_count
    })
  }


  instaShare() {
    console.log(this.item.img_car[0])
    this.instagram.share(this.getImg(this.item.img_car[0]), 'Verycar-' + this.item.plate)
      .then(() => console.log('Shared!'))
      .catch((error: any) => { });
  }

  loadUsers() {
    this.db.collection("users").get().subscribe(res => {

      res.forEach(element => {
        this.loadUserCache(element.id)
      })
    })



    /**   this.db.collection('users').valueChanges().subscribe(
            (res: any[]) => {
              console.log(res)
              res.forEach(c => {
                c.users.forEach(m => this.loadUserCache(m))
              })
      
            })*/




  }
  cacheImgs: any = {}
  getImg(img) {
    if (img && img.img && this.cacheImgs[img.img])
      return this.cacheImgs[img.img]

    if (!img || !img.img) {
      return 'data:image/jpeg;base64,' + dummy
    }

    this.db.doc(`verycar_attachs/${img.img}`).valueChanges().subscribe((res: any) => {

      try {
        this.cacheImgs[img.img] = 'data:image/jpeg;base64,' + res.content.replaceAll('data:image/jpeg;base64,', '')
      } catch (error) {

      }

      //console.log(this.cacheImgs)
    })
    let thumb = img.thumb.replace('data:image/jpeg;base64,data:image/jpeg;base64,', 'data:image/jpeg;base64,')

    return thumb

  }


  userCache: any = {}
  loadUserCache(email) {

    this.db.doc('users/' + email).get().subscribe(res => {

      try {

        let usr = res.data()
        let s = `${usr.nickname}`
        this.userCache[email] = s



      } catch (error) {

      }


    })
  }

  getUserName(email) {

    if (email == this.me) return 'Me'

    if (this.userCache[email]) return this.userCache[email]

    return 'N/A'


  }


  getOpts() {

    let x
    try {
      x = this.item.etax2.valutazione.accessori_serie
    } catch (error) {
      // console.warn(error)
    }

    try {
      if (!x)
        x = this.item.optionals.filter(e => e.present)
    } catch (error) {
      // console.error(error)
    }

    if (!x) x = []

    try {
      this.nopts = x.length
    } catch (error) {
      this.nopts = 0
    }
    //console.log(x)
    return x

  }

  nopts = 0

  hideEtaxStatus(bool) {
    this.item.hiddenEtax = bool
    this.db.collection('verycar').doc(this.id).set({ hiddenEtax: bool }, { merge: true }).then(res => { console.log(res) })
  }
  ionViewDidEnter() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.park = this.route.snapshot.paramMap.get('park')


    this.getDamageCost()

    this.s = this.db.collection('verycar').doc(this.id).valueChanges().subscribe(
      (res: IVerycar) => {

        console.log(res)
        this.item = res

        this.nopts = this.item.optionals && this.item.optionals.filter(e => e.present).length

        if (this.item.post)
          this.loadLikes()
        if (this.park == 'mypark') {
          this.item.new = false;
          this.db.collection('verycar').doc(this.item.id).set({
            new: false
          }, { merge: true });
        }
        if (this.park == 'verypark') {

          this.getTel(this.item.email)
          if (this.item.typeVendita == 'asta')
            this.scad = new Date(this.item.astaData.scadenzaData)
        }
        setTimeout(() => {

          //if (!this.item.marca_free) {

          try {
            this.loadChart()
          } catch (error) {

          }

          try {
            this.loadQuestions()
          } catch (error) {

          }

          try {
            this.loadTable()
          } catch (error) {

          }
          try {
            if (this.item.damages.length != 0)
              if (!this.item.blueprint_images[0].img.startsWith('data'))
                this.getBlueprints()
          } catch (error) {

          }
          if (this.item.tires && this.item.tires.tires.length != 0)
            this.tiresOk = true
          console.log(this.item)
          //}

        }, 200);

        this.ready = true
      })



  }

  getTiresInfo(type) {
    let data: any = this.item.tires

    switch (type) {
      case 'adx':
        return data.tires[0].l + ' / ' + data.tires[0].h + ' R' + data.tires[0].d
      case 'asx':
        return data.tires[1].l + ' / ' + data.tires[1].h + ' R' + data.tires[1].d
      case 'pdx':
        return data.tires[2].l + ' / ' + data.tires[2].h + ' R' + data.tires[2].d
      case 'psx':
        return data.tires[3].l + ' / ' + data.tires[3].h + ' R' + data.tires[3].d
      case 'scorta':
        return data.tires[4].l + ' / ' + data.tires[4].h + ' R' + data.tires[4].d
    }

  }
  steps = []
  nstep = 0
  years = 0
  tiresOk = false
  loadTable() {
    this.steps['B'] = [{ k: 15000, y: 12 }, { k: 30000, y: 24 }, { k: 45000, y: 36 }, { k: 60000, y: 48 }, { k: 75000, y: 60 }, { k: 90000, y: 72 }, { k: 105000, y: 84 }, { k: 120000, y: 96 }];
    this.steps['D'] = [{ k: 20000, y: 12 }, { k: 40000, y: 24 }, { k: 60000, y: 36 }, { k: 80000, y: 48 }, { k: 100000, y: 60 }, { k: 120000, y: 72 }, { k: 140000, y: 84 }, { k: 160000, y: 96 }]

    switch (this.item.etax3.modello.codice_alimentazione.toUpperCase()) {
      case 'B':
        this.steps = this.steps['B']
        this.nstep = Math.round(this.item.km / 15000)
        break;
      // case 'D':
      default:
        this.steps = this.steps['D']
        this.nstep = Math.round(this.item.km / 20000)
        break;
    }

    if (this.item.etax1.data_imm != '') {

      let dImm = moment(this.item.etax1.data_imm, 'DD/MM/YYYY')
      let dNow = moment(new Date())


      console.log('dImm', dImm)
      console.log('dNow', dNow)

      let dateDiff = dNow.diff(dImm, 'years');

      console.log(dateDiff)

      console.log(dateDiff)
      this.years = dateDiff
      //  $years = $dDiff->format("%y");
    }



  }

  loadChart() {
    if (this.category == 'stats')
      setTimeout(() => {
        this.raggruppaQuestions(this.item)
        console.log(this.values)
        this.chart = new Chart('chart', {
          type: 'horizontalBar',
          data: {
            labels: [
              this.ls.getString('detail_label_0'),
              this.ls.getString('detail_label_1'),
              this.ls.getString('detail_label_2'),
              this.ls.getString('detail_label_3'),
              this.ls.getString('detail_label_4'),
            ],
            datasets: [{
              label: this.ls.getString('rating') + ' (%)',
              data: this.values,
              backgroundColor: '#196199', // array should have same number of elements as number of dataset
              borderColor: '#196199',// array should have same number of elements as number of dataset
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  max: 100
                }
              }]
            }, onClick: res => this.showDetail(res)
          }
        });
      }, 500)

  }
  sez = []

  loadQuestions() {

    for (const q of this.item.questions) {

      let sI = this.sez.findIndex(e => e.sectionCode == q.sectionCode)
      if (sI < 0) {
        this.sez.push({ sectionCode: q.sectionCode, sectionDescription: q.sectionDescription, questions: [] })
        sI = this.sez.findIndex(e => e.sectionCode == q.sectionCode)
      }

      q['val'] = q['val' + q.response]
      q['text'] = q['text' + q.response]

      this.sez[sI].questions.push(q)

    }

    //console.clear()
    console.log(this.sez)

  }


  getDim() {
    return window.innerWidth
  }

  async offer() {
    const alerto = await this.alertController.create({
      header: this.ls.getString('auction_offer'),
      subHeader: this.ls.getString('auction_current_max') + ': ' + (this.item.astaData.offerta ? this.item.astaData.offerta[0].price : this.item.astaData.base),
      message: this.ls.getString('auction_min_increasing') + ': ' + this.item.astaData.rilancio,
      inputs: [

        {
          name: 'offerta',
          type: 'number',
          min: this.item.astaData.rilancio,
        },

      ],
      buttons: [
        {
          text: this.ls.getString('cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (res) => {
            console.log('Confirm Ok');

            if (+res.offerta < +this.item.astaData.rilancio + +(this.item.astaData.offerta ? this.item.astaData.offerta[0].price : this.item.astaData.base)) {
              alert(this.ls.getString('auction_offer_below_min'))

            } else {
              console.log('tento post', this.item.id)
              this.http.post('https://us-central1-verycar-4e643.cloudfunctions.net/astaOffer', { id: this.item.id, offerta: res.offerta, userGettin: this.me, scadenza: this.item.astaData.scadenzaData }).subscribe((reso: any) => {

                console.log(reso)

                if (reso.status == 'OK') {
                  setTimeout(() => {
                    alert(this.ls.getString('you_have_offred') + ' ' + res.offerta + "€")
                  }, 100);
                } else if (reso.stats == 'SCAD') {
                  setTimeout(() => {
                    alert(this.ls.getString('auction_expired'))
                  }, 100);
                } else alert(JSON.stringify(reso) + ' ' + this.ls.getString('auction_offer_error'))
              })
            }
          }
        }
      ]
    });

    await alerto.present();
  }

  home() {
    console.log(this.park)
    this.router.navigate([`/automotive/tabs/` + this.park])
  }

  raggruppaQuestions(item) {
    let sez1N = 0
    let sez2N = 0
    let sez3N = 0
    let sez4N = 0
    let sez5N = 0
    let sez1T = 0
    let sez2T = 0
    let sez3T = 0
    let sez4T = 0
    let sez5T = 0
    item.questions.forEach(element => {
      switch (element.sectionCode) {
        case 'Sez I': this.sez1.push(element)
          sez1T = sez1T + this.getresponse(element)

          sez1N++
          break;
        case 'Sez II': this.sez2.push(element)
          sez2T = sez2T + this.getresponse(element)
          sez2N++
          break;
        case 'Sez III': this.sez3.push(element)
          sez3T = sez3T + this.getresponse(element)
          sez3N++
          break;
        case 'Sez IV': this.sez4.push(element)
          sez4T = sez4T + this.getresponse(element)
          sez4N++
          break;
        case 'Sez V': this.sez5.push(element)
          sez5T = sez5T + this.getresponse(element)
          console.log(sez5T)
          sez5N++
          break;
        case 'Sez VII': this.sez7.push(element)
          //sez7?
          break;
      }


    });
    this.values.push(sez1T / sez1N)
    this.values.push(sez2T / sez2N)
    this.values.push(sez3T / sez3N)
    this.values.push(sez4T / sez4N)
    this.values.push(sez5N != 0 ? sez5T / sez5N : 0)
  }




  segmentChangedPhoto(event) {
    if (event = 'stats')

      this.loadChart()


  }


  getresponse(element) {
    if (element.response > 0)
      switch (element.response) {
        case 1:
          return element.val1
        case 2:
          return element.val2
        case 3:
          return element.val3
        case 4:
          return element.val4
        case 5:
          return element.val5
      }
    else return 0
  }


  downloadVisura() {

    console.log(this.item)

    this.db.doc(`verycar_attachs/${this.item.file_visura}`).valueChanges().subscribe((res: any) => {

      console.log(res)

      let data = res.content.replace('data:application/pdf;base64,', '')
      //console.log(this.cacheImgs)


      const linkSource = 'data:application/pdf;base64,' + data
      const downloadLink = document.createElement("a");
      const fileName = "visura.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    })

  }


  async presentModal(arr): Promise<any> {
    const modal = await this.modalController.create({
      component: PopupQuestions,
      cssClass: 'modal-class',
      componentProps: {
        array: arr,
      }
    });

    return await modal.present();
  }
  async showDetail(event) {
    var activeElement = this.chart.getElementAtEvent(event);
    var arr = []
    console.log(activeElement)
    switch (activeElement[0]._index) {
      case 0: arr = this.sez1
        break;
      case 1: arr = this.sez2
        break;
      case 2: arr = this.sez3
        break;
      case 3: arr = this.sez4
        break;
      case 4: arr = this.sez5
        break;
    }
    await this.presentModal(arr)
  }

  call(email) {
    let r = this.db.collection('users', ref1 => ref1
      .where('email', '==', email)
    ).valueChanges().subscribe((res1: IProfile[]) => {
      if (this.platform.is('capacitor')) {
        this.callNumber.callNumber(res1[0].tel, true)
          .then(res => console.log('Launched dialer!', res))
          .catch(err => console.log('Error launching dialer', err));
      } else {
        console.log(res1[0].tel)
      }
    })
  }

  download() {
    const browser = this.iab.create(URL_BASE + 'verycar_report&lang=' + this.ls.preferredLanguage + '&id=' + this.item.id, '_system')
    //browser.show()
  }

  getTel(email) {

    try {
      let result
      let r = this.db.collection('users', ref1 => ref1
        .where('email', '==', email)
      ).valueChanges().subscribe((res1: IProfile[]) => {
        console.log(res1)
        this.shwtel = res1[0].showTel
      })

    } catch (error) {

    }



  }

  isBookmarked
  toggleBookmark() {
    this.isBookmarked = !this.isBookmarked
  }

  getDamageCost() {

    console.log('getDamageCost', this.id)

    this.mysql.getDamageCost(this.id).then(res => {
      console.log('getDamageCost', res)
      this.cost = res

    }).catch(error => console.log(error))
  }

  startChat(item: IVerycar) {

    this.db.collection('chats', ref => ref.where('code', '==', this.id).where('users', 'array-contains', this.me)).valueChanges().subscribe((res: IChat[]) => {

      console.log(res)

      if (res.length > 0) {

        localStorage.setItem('openchat', res[0].id)
        this.router.navigate(['automotive/tabs/chat-list/'])

      } else {

        let tmp: IChat = {
          id: this.db.createId(),
          code: this.id,
          plate: this.item.plate,
          users: [this.me, this.item.email],
          messages: [],
          thumb: this.item.img_car[1].thumb
        }

        localStorage.setItem('startchat', JSON.stringify(tmp))
        this.router.navigate(['automotive/tabs/chat-list/'])

      }

    })


  }
  blueprints = []
  damages = []
  getBlueprints() {
    this.item.blueprint_images.forEach(elem => {
      this.db.doc(`verycar_attachs/${elem.img}`).valueChanges().subscribe((res: any) => {
        console.log(res)
        this.blueprints.push(res)
      })

    })
    this.item.damages.forEach(element => {

      this.db.collection('verycar_attachs', ref => (ref.where('id', '==', element.img1.img))).valueChanges().subscribe(res => {
        //console.log(res)
        this.damages.push(res)
      })
      this.db.collection('verycar_attachs', ref => (ref.where('id', '==', element.img2.img))).valueChanges().subscribe(res => {
        //console.log(res)
        this.damages.push(res)
      })

    });


  }

  getDamagePerPage(pag) {

    return this.item.damages.filter(d => d.pag == pag)
  }

  getDamageDescription(d) {

    let part = d.part.description
    let tipo = d.type

    let descr = tipo
    if (part && part != '') descr += ' - ' + part

    return descr 


  }

  getDamageContent(id) {

    let res = this.damages.filter(res => res.id == id)[0]
    // console.log(res)
    return res

  }
  getBlueprintContent(id) {

    let res = this.blueprints.filter(res => res.id == id)[0]
    // console.log(res)
    return res

  }



  share() {

    console.clear()
    console.log(this.item.img_car[1].img)

    this.db.doc(`verycar_attachs/${this.item.img_car[0].img}`).valueChanges().subscribe((res: any) => {

      this.socialSharing.share(this.ls.getString('fb_share_text'), '*** Verycar ***', undefined, 'https://xpert.ggroup.cloud/api/api.php?cmd=verycar_report_html&lang=' + this.ls.preferredLanguage + '&id=' + this.item.id)
        .then((res) => {

          alert(this.ls.getString('fb_shared') + JSON.stringify(res))

          // Success!
        }).catch((err) => {

        });


    })


  }


}

@Component({
  selector: 'app-popup-questions',
  template: ` 
  <ion-content>
    <ion-header style="    margin-top: 9%;
    margin-bottom: 16%;"><ion-title style="      
    height:100%;
    text-align: center;
    vertical-align: middle;
    margin: auto;"> {{array[0].sectionDescription|uppercase}}</ion-title>
    </ion-header>
      <ion-list>
        <ion-item *ngFor="let elem of array">
          
          <ion-grid>
            <ion-row>
              <ion-col size="9">
              <span >{{elem.questionDescr}} </span>
              </ion-col>
              <ion-col style="align-content:end!Important">
              <ion-img *ngIf="elem.response==1" style="width:8vh!important; padding-left:3vh;" [src]="'./assets/imgs/verycar_green.png'"></ion-img>
              <ion-img *ngIf="elem.response==2 || elem.response==3" style="width:8vh!important; padding-left:3vh;" [src]="'./assets/imgs/verycar_orange.png'"></ion-img>
              <ion-img *ngIf="elem.response==4 || elem.response<0 || !(elem.response)" style="width:8vh!important; padding-left:3vh;" [src]="'./assets/imgs/verycar_red.png'"></ion-img>
              </ion-col>
            </ion-row>
          </ion-grid>

        </ion-item>
      </ion-list>
      <ion-button expand="block" shape="round" (click)="cancel()">Ok</ion-button>
  </ion-content>

      `,



})

export class PopupQuestions {

  // Data passed in by componentProps
  @Input() array: any[];

  constructor(private modalCtrl: ModalController, navParams: NavParams) {
    // componentProps can also be accessed at construction time using NavParams
    //console.log(navParams.get('extra'));




  }



  cancel() {
    this.modalCtrl.dismiss();
  }

  ok() {
    this.modalCtrl.dismiss(this.array)
  }



}

@Component({
  selector: 'app-segnalazione',
  template: ` 
  <ion-content>
    <ion-header>
      <ion-title>Segnala un problema!</ion-title>
    </ion-header>
    
    <ion-list>
      <ion-list-header>
        <ion-label>
          Aiutaci a capire cosa non va:
        </ion-label>
      </ion-list-header>

      <ion-item>
        {{plate}}
      </ion-item>

      <ion-item>
        <ion-label>Motivazione * </ion-label>
        <ion-select [(ngModel)]="item.motivazione">
          <ion-select-option *ngFor="let item of motivazioni" [value]="item">{{item}}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Aggiungi informazioni dettagliate per risolvere il problema al più presto * </ion-label>
        <ion-textarea [(ngModel)]="item.note">
        </ion-textarea>
      </ion-item>
  
      </ion-list>

    <ion-footer>
      <ion-button expand="block" shape="round" (click)="ok()">Ok</ion-button>
      <ion-button expand="block" shape="round" (click)="cancel()">Annulla</ion-button>
    </ion-footer>
  </ion-content>

      `,



})

export class SegnalazioneAnniuncioModalComponent {



  motivazioni = [
  ]
  // Data passed in by componentProps
  @Input() id: string;
  @Input() plate: string;
  @Input() type: string = 'automotive';

  item

  constructor(
    private modalCtrl: ModalController,
    private db: AngularFirestore,

    private userService: AngularFireAuth,
  ) {

    switch (this.type) {
      case 'home':
        this.motivazioni = [
          'Questo annuncio sembra una truffa',
          'Questo annuncio è di mia proprietà',
          'Questo annuncio è falso',
          'Questo annuncio riporta informazioni che non dovrebbero essere visibili qui',
          'Questo annuncio si spaccia per un primato ma è un\'agenzia',
          'Altro',
        ]
        break

      default:
        this.motivazioni = [
          'Questo annuncio sembra una truffa',
          'Questo annuncio è di mia proprietà',
          'Questo annuncio è falso',
          'Questo annuncio riporta informazioni che non dovrebbero essere visibili qui',
          'Altro',
        ]
    }


    this.item = {
      id: this.db.createId(),
      motivazione: '',
      note: '',

      dta: new Date,

      idVerycar: '',
      plate: '',

      user: { uid: '', email: '' },

      type: this.type

    }


    this.userService.authState.subscribe(res => {
      console.log(res)
      this.item.user.uid = res.uid
      this.item.user.email = res.email
    })


  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  ok() {

    console.log(this.item)

    this.item.idVerycar = this.id
    this.item.plate = this.plate

    if (this.item.motivazione == '' || this.item.note == '') {
      alert('Attenzione, compila tutti i campi')
      return
    }

    this.db.doc('segnalazioni/' + this.item.id).set(this.item)

    alert("Grazie per aver collaborato, verificheremo il problema al più presto!")

    this.modalCtrl.dismiss()
  }



}



export const URL_BASE = 'https://xpert.ggroup.cloud/api/api.php?cmd='


export class Bookmarks {

  bookmarks: string[]

  constructor() {

    this.readStorage()


  }

  readStorage() {
    let tmp = localStorage.getItem('bookmarks')
    if (tmp) this.bookmarks = JSON.parse(tmp)
    else {
      this.bookmarks = []
    }
  }


  isBookmarked = (id: number): boolean => {
    return this.bookmarks.find(e => e == '' + id) != undefined
  }


  toggleBookmark = (id: number) => {
    this.readStorage()
    let b = this.isBookmarked(id)

    if (b) this.bookmarks.splice(this.bookmarks.findIndex(e => e == '' + id), 1)
    else this.bookmarks.push('' + id)


    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks))

  }




}

