import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthProviderService } from 'src/app/services/auth-provider.service';

@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss'],
})
export class ReactionsComponent implements OnInit {

  reactions = [/*'smile', 'love', */'happy', /*'wow', 'sad', /*'rage'*/]//['like', 'wow', 'sad']//

  _source: IReaction[]

  //@Input() source: IReaction[]

  @Input() set source(s) {
    //console.log('source: ', s)
    this._source = s
  }

  @Input() id: string


  me


  constructor(
    private db: AngularFirestore,
    private userService: AngularFireAuth,
  ) {

    this.me = undefined
    userService.authState.subscribe(res => {
      this.me = res ? res.email : undefined
    })
  }

  ngOnInit() { }

  toggle(reaction) {

   // console.log(this.me)

    if (!this.me || this.me == '') {
      alert("Attenzione, devi essere loggato per poter utilizzare questa funzione")
      return
    }

    if (!this._source) this._source = []

   // console.log(this._source)
    let i
    try {
      i = this._source.findIndex(e => e.user == this.me)
    } catch (error) {
      console.error(error)
      this._source = []
    }


    let isTheSame
    if (i >= 0) {

      isTheSame = this._source[i].reaction == reaction

      this._source.splice(i, 1)


    }

    if (!isTheSame) this._source.push({ datetime: new Date, user: this.me, reaction: reaction })

    this.db.doc('verycar/' + this.id).set({ reactions: this._source, needSync: true }, { merge: true })
      .then(res => console.log('verycar/' + this.id, res))
      .catch(err => console.error('verycar/' + this.id, err))


  }

  getCount(reaction) {
    try {
      return this._source.filter(e => e.reaction == reaction).length
    } catch (error) {
      return 0
    }
  }

  isMyReaction(reaction) {

    try {
      return this._source.findIndex(e => e.user == this.me && e.reaction == reaction) >= 0

    } catch (error) {
      return false
    }


  }


  getIcon(s,sel){

    let base='/assets/imgs/reactions/'

    switch(s){
      case 'happy': return `${base}like_${sel ? 'selected' : 'unselected'}.svg`
      //case 'sad': return `${base}dislike_${sel ? 'selected' : 'unselected'}.svg`
    }

  }

}

export interface IReaction {

  datetime: Date
  user: string
  reaction: string

}