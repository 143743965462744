import { Component, OnInit, Input } from '@angular/core';
import { dummy } from '../blueprint/blueprint.component';
import { AngularFirestore } from 'angularfire2/firestore';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-sliedeshow',
  templateUrl: './sliedeshow.component.html',
  styleUrls: ['./sliedeshow.component.scss']
})
export class SliedeshowComponent implements OnInit {


  fadeIn = false;
  fadeOut = false;

  i_img = 0;

  images = [];

  @Input() loop = true;
  @Input() park = 'verycar';
  @Input() hideNotPresent = false;

  @Input('images') set loadImages(images) {

    console.clear()
    console.log(images)

    this.images = images

    this.start()
  }

  ready = false

  timer = null;

  constructor(
    private db: AngularFirestore,
    private modalCtrl: ModalController,
  ) {

    console.log('app-slideshow')

  }

  ngOnInit() {


  }

  play = true

  next() {

    this.images = this.images.filter(e => e.img && e.img != '')


    this.ready = this.images != undefined
    this.fadeOut = true;

    setTimeout(() => {

      if (this.play) {

        this.fadeOut = false;
        this.fadeIn = true;

        if (this.i_img + 1 >= this.images.length) {
          this.i_img = 0;
          return;
        }

        this.i_img++

      }


    }, 100);


  }

  prev() {

    this.fadeOut = true;

    setTimeout(() => {

      if (this.play) {

        this.fadeOut = false;
        this.fadeIn = true;

        //console.log('next: ' + (this.i_img + 1))

        if (this.i_img - 1 < 0) {

          //console.log('return to 0')

          this.i_img = this.images.length - 1;
          return;
        }

        this.i_img--

      }


    }, 100);


  }

  start() {
    //console.log(this.images, this.park)
    if (this.loop)
      this.timer = setTimeout(() => {
        this.next();
        this.start();
      }, 2000);
  }

  getAttachs(img) {
    //  console.log(img)
    return img.content
  }
  cacheImgs: any = {}
  getImg(img) {
    if (img && img.img && this.cacheImgs[img.img])
      return this.cacheImgs[img.img]

    if (!img || !img.img) {
      return 'data:image/jpeg;base64,' + dummy
    }

    this.db.doc(`verycar_attachs/${img.img}`).valueChanges().subscribe((res: any) => {
      this.cacheImgs[img.img] = 'data:image/jpeg;base64,' + res.content.replaceAll('data:image/jpeg;base64,', '')
      //console.log(this.cacheImgs)
    })
    let thumb = img.thumb.replace('data:image/jpeg;base64,data:image/jpeg;base64,', 'data:image/jpeg;base64,')

    return thumb

  }

  getAttachsBoat(img) {
    // console.log(this.cacheImgs) 
    if (img && img.img && this.cacheImgs[img.img])
      return this.cacheImgs[img.img]

    if (!img || !img.img) {
      return 'data:image/jpeg;base64,' + dummy
    }

    this.db.doc(`veryboat_attachs/${img.img}`).valueChanges().subscribe((res: any) => {
      this.cacheImgs[img.img] = 'data:image/jpeg;base64,' + res.content.replaceAll('data:image/jpeg;base64,', '')
      // console.log(this.cacheImgs)
    })
    let thumb = img.thumb.replace('data:image/jpeg;base64,data:image/jpeg;base64,', 'data:image/jpeg;base64,')

    return thumb

  }






  async openZoomedImage() {

    const modal = await this.modalCtrl.create({
      component: ImageZoomPage,
      cssClass: 'product-zoom-modal',
      componentProps: {
        cacheImgs: this.cacheImgs,
        images: this.images,
        i: this.i_img,

      },
      presentingElement: await this.modalCtrl.getTop()
    })
    await modal.present();
    await modal.onWillDismiss().then((result) => {
      console.log('result :>> ', result);

    }).catch((err) => {
      console.log('err :>> ', err);
    });
  }


}






@Component({
  selector: 'app-product-zoom',
  template: `
  <ion-content>
  <ion-item lines="none">
    <ion-icon name="close" slot="end" (click)="dismiss()" style="font-size: 30px; color: #000"></ion-icon>
    <!-- <ion-button class="default-btn" slot="end" (click)="dismiss()" layout="icon-only">
      
    </ion-button> -->
  </ion-item>
  <div class="zoom-box">
    <ion-slides pager="true" [options]="slideOpts">
      <ion-slide *ngFor="let image of navParams.data.images">
        <div class="swiper-zoom-container">
          <img class="card-img" [src]="getImg(image)" />
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</ion-content>
  `,
  styles: [
    `
    ion-content {
      --background: none;
  }
  ion-item {
      --background: none;
  }
  ion-slides {
      width: 100%;
  }
  .swiper-slide img {
      width: 100%;
      max-width: 100%;
  }
  .zoom-box {
      position: absolute;
      height: 90%;
      display: flex;
      width: 100%;
  }
    `
  ],
})
export class ImageZoomPage implements OnInit {


  slideOpts = {
    initialSlide: this.navParams.data.i,
    speed: 400,
    loop: true,
    zoom: {
      minRatio: 1,
      maxRatio: 3,
      toggle: true,
      containerClass: 'swiper-zoom-container',
      zoomedSlideClass: 'swiper-slide-zoomed'
    }
  };

  images: any = [];

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private db: AngularFirestore,
  ) {
    console.log(this.navParams)
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getImg(img) {


    if (img && img.img && this.navParams.data.cacheImgs[img.img])
      return this.navParams.data.cacheImgs[img.img]

    if (!img || !img.img) {
      return 'data:image/jpeg;base64,' + dummy
    }

    this.db.doc(`verycar_attachs/${img.img}`).valueChanges().subscribe((res: any) => {
      this.navParams.data.cacheImgs[img.img] = 'data:image/jpeg;base64,' + res.content.replaceAll('data:image/jpeg;base64,', '')
      //console.log(this.cacheImgs)
    })
    let thumb = img.thumb.replace('data:image/jpeg;base64,data:image/jpeg;base64,', 'data:image/jpeg;base64,')

    return thumb

  }

}