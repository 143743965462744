import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'firebaseToDate'
})
export class FirbaseToDatePipe implements PipeTransform {

  constructor(private p: DatePipe){}

  transform(value: any, args?: any): any {

    try {
      value = value.toDate()
      value = this.p.transform(value, args)
      return value
    } catch (error) {
      return ''
    }

  }

}
