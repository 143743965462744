import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { timer } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MD5 } from './pages/_automotive/verycar/verycar.page';
import { AngularFirestore } from 'angularfire2/firestore';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

declare const NavigationBar: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  bgClass = 'bg-auto'
  showBg = true
  setBackground(bg: string) {


    bg = bg.substring(0, 7)
    
    if (bg != 'bg-whit')
      this.showBg = false

    console.log('setbg -> ' + bg)

    this.bgClass = bg

    setTimeout(() => {
      this.showBg = true
    }, 100);

    try {
      switch (bg) {

        case 'bg-auto':
          this.statusBar.backgroundColorByHexString('#b4e3c7');
          if (this.platform.is('android')) NavigationBar.backgroundColorByHexString('#6090bf');
          break
        case 'bg-home':
          this.statusBar.backgroundColorByHexString('#f5dcc1');
          if (this.platform.is('android')) NavigationBar.backgroundColorByHexString('#e5723a');
          break
        case 'bg-boat':
          this.statusBar.backgroundColorByHexString('#8bddf5');
          if (this.platform.is('android')) NavigationBar.backgroundColorByHexString('#3777e5');
          break
        default:
          this.statusBar.backgroundColorByHexString('#000000');
          if (this.platform.is('android')) NavigationBar.backgroundColorByHexString('#000000');


      }
    } catch (error) {
      console.log(error)
    }
  }

  showSplash = true; // <-- show animation

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private http: HttpClient,

    private db: AngularFirestore,
  ) {


    this.splashScreen.show();

    this.initializeApp();

    // this.loadCSV()
  }

  loadCSV() {

    let f = "/assets/QI_20212_VALORI.csv"

    this.http.get(f, { responseType: 'blob', observe: 'response' })
      .subscribe((value) => {


        const reader = new FileReader();
        reader.readAsText(value.body);

        reader.onload = (content) => {
          const txt = reader.result as string;


          let lines = txt.split("\r\n")

          let head = lines.splice(0, 1)[0].split(';')

          let r = []

          console.log(lines.length)

          for (let j = 0; j < lines.length; j++) {


            let line = lines[j]
            let values = line.split(';')


            if (!values[0] || values[0] == '') continue

            let tmp: any = {}
            for (let i = 0; i < head.length; i++) {

              tmp[head[i]] = values[i]

            }


            tmp.id = MD5(JSON.stringify(tmp))

            //console.log(tmp)

            this.db.doc(`valore_immobili/${tmp.id}`).set(tmp)
              //.then(res => console.log(res))
              .catch(err => console.error(err))

            if (j % 100 == 0) console.log(j)

          }

          alert('Importazione terminata')


        };
      });


  }

  initializeApp() {
    this.platform.ready().then(() => {


      this.splashScreen.show();

      // this.statusBar.styleDefault();

      this.statusBar.overlaysWebView(false);

      // set status bar to white
      this.statusBar.backgroundColorByHexString('#000000');

      timer(4000).subscribe(() => this.showSplash = false) // <-- hide animation after 3s




    });
  }


}

