import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginRouteGuard, SelectorGuard } from './route-guard';

const routes: Routes = [

  {
    path: 'selector',
    loadChildren: () => import('./pages/selector/selector.module').then(m => m.SelectorPageModule)
  },
  {
    path: 'automotive',
    loadChildren: () => import('./pages/_automotive/start/start.module').then(m => m.StartPageModule),
    canActivate: [SelectorGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/_home/start-home/start-home.module').then(m => m.StartHomePageModule),
    canActivate: [SelectorGuard]
  },
  {
    path: 'boat',
    loadChildren: () => import('./pages/_nautica/start-nautica/start-nautica.module').then(m => m.StartNauticaPageModule),
    canActivate: [SelectorGuard]
  },

  {
    path: 'inspector',
    redirectTo: '/start-inspector/home',
    pathMatch: 'full'
  },
  {
    path: 'inspector',
    redirectTo: 'start-inspector',
    pathMatch: 'full'
  },


  {
    path: '',
    redirectTo: 'selector',
    pathMatch: 'full'
  },





  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  },




  {
    path: 'admin',
    loadChildren: './pages/admin/admin.module#AdminPageModule',
    canActivate: [LoginRouteGuard]
  },



  {
    path: 'verycar/:type', loadChildren: './pages/_automotive/verycar/verycar.module#VerycarPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'verycar/:type/:id', loadChildren: './pages/_automotive/verycar/verycar.module#VerycarPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'verycar-list/:type', loadChildren: './pages/_automotive/verycar-list/verycar-list.module#VerycarListPageModule',
    canActivate: [LoginRouteGuard]
  },


  {
    path: 'home-perizie-list/:type', loadChildren: './pages/_home/home-perizie-list/home-perizie-list.module#HomePerizieListPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'veryhome/:type', loadChildren: './pages/_home/veryhome/veryhome.module#VeryhomePageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path: 'veryhome/:type/:id', loadChildren: './pages/_home/veryhome/veryhome.module#VeryhomePageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path: 'nautica-perizie-list/:type', loadChildren: './pages/_nautica/nautica-perizie-list/nautica-perizie-list.module#NauticaPerizieListPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'verynautica/:type', loadChildren: './pages/_nautica/verynautica/verynautica.module#VerynauticaPageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path: 'verynautica/:type/:id', loadChildren: './pages/_nautica/verynautica/verynautica.module#VerynauticaPageModule',
    canActivate: [LoginRouteGuard]
  },


  {
    path: 'preview/:type/:id',
    loadChildren: './pages/preview/preview.module#PreviewPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'preview-3d/:type/:id',
    loadChildren: './pages/preview3d/preview.module#Preview3dPageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path: 'chat-list',
    loadChildren: './pages/chat-list/chat-list.module#ChatListPageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path:
      'detail/:park/:id',
    loadChildren: './pages/_automotive/detail/detail.module#DetailPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path:
      'home-detail/:park/:id',
    loadChildren: './pages/_home/home-detail/home-detail.module#HomeDetailPageModule',
    canActivate: [LoginRouteGuard]
  },

  {
    path:
      'boat-detail/:park/:id',
    loadChildren: './pages/_nautica/nautica-detail/nautica-detail.module#NauticaDetailPageModule',
    canActivate: [LoginRouteGuard]
  },
  {
    path:
      'nautica-detail/:park/:id',
    loadChildren: './pages/_nautica/nautica-detail/nautica-detail.module#NauticaDetailPageModule',
    canActivate: [LoginRouteGuard]
  },
  { path: 'inspections', loadChildren: './pages/_automotive/inspections/inspections.module#InspectionsPageModule' },

  { path: 'start-inspector', loadChildren: './pages/_automotive/start-inspector/start-inspector.module#StartInspectorPageModule' },
  { path: 'calendar', loadChildren: './pages/calendar/calendar.module#CalendarPageModule' },
  {
    path: 'leed/:id',
    loadChildren: () => import('./pages/leed/leed.module').then(m => m.LeedPageModule)
  },
  {
    path: 'modal-business',
    loadChildren: () => import('./pages/modal-business/modal-business.module').then(m => m.ModalBusinessPageModule)
  },















];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
