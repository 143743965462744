import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MysqlService {
  public URL_BASE = '';
  constructor(private http: HttpClient, router: Router,) {


    this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
    let host = window.location.href.split('/')[2].split(':')[0];
    //this.URL_BASE = 'https://xpert.ggroup.cloud/';
    this.URL_BASE = 'https://xpert-test.i-expert.it/';
    this.URL_BASE += 'api/api.php?cmd=';
  }

  
  send_mail(msg): Promise<any> {

    let data = {
      data: msg
    }

    return this.http.post(this.URL_BASE + 'send_mail_anon', data).toPromise();
  }

  verycar_nautica_list(light = false, filters: IVeryboatFilters): Promise<any> {

    /*
                    

                        '' =>  isset($request->filters->nLocali_min) ? $request->filters->nLocali_min : 0 ,
                        '' =>  isset($request->filters->nLocali_max) ? $request->filters->nLocali_max : 0 ,

                        '' =>  isset($request->filters->nCamereLetto_min) ? $request->filters->nCamereLetto_min : 0 ,
                        '' =>  isset($request->filters->nCamereLetto_max) ? $request->filters->nCamereLetto_max : 0 ,

                        '' =>  isset($request->filters->nBagni_min) ? $request->filters->nBagni_min : 0 ,
                        '' =>  isset($request->filters->nBagni_max) ? $request->filters->nBagni_max : 0 ,

                        '' =>  isset($request->filters->classeEnergetica) ? $request->filters->classeEnergetica : '' ,

                        '' => '%' . ( isset($request->filters->cucina) ? $request->filters->cucina : '' ) . '%',
                        
                        '' =>  isset($request->filters->position->lat) ? $request->filters->position->lat : null ,
                        '' =>  isset($request->filters->position->lon) ? $request->filters->position->lon : null ,
    */

    let data = {
      //auth: this.userService.getCurrentUser(),
      skip: true,
      light: light,
      filters: filters
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'verycar_nautica_list', data).toPromise();
  }

  verycar_home_list(light = false, filters: IVeryhomeFilters, tipoUtente): Promise<any> {

    /*
                    

                        '' =>  isset($request->filters->nLocali_min) ? $request->filters->nLocali_min : 0 ,
                        '' =>  isset($request->filters->nLocali_max) ? $request->filters->nLocali_max : 0 ,

                        '' =>  isset($request->filters->nCamereLetto_min) ? $request->filters->nCamereLetto_min : 0 ,
                        '' =>  isset($request->filters->nCamereLetto_max) ? $request->filters->nCamereLetto_max : 0 ,

                        '' =>  isset($request->filters->nBagni_min) ? $request->filters->nBagni_min : 0 ,
                        '' =>  isset($request->filters->nBagni_max) ? $request->filters->nBagni_max : 0 ,

                        '' =>  isset($request->filters->classeEnergetica) ? $request->filters->classeEnergetica : '' ,

                        '' => '%' . ( isset($request->filters->cucina) ? $request->filters->cucina : '' ) . '%',
                        
                        '' =>  isset($request->filters->position->lat) ? $request->filters->position->lat : null ,
                        '' =>  isset($request->filters->position->lon) ? $request->filters->position->lon : null ,
    */

    let data = {
      //auth: this.userService.getCurrentUser(),
      skip: true,
      light: light,
      filters: filters,
      tipoUtente : tipoUtente
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'verycar_home_list', data).toPromise();
  }

  verycar_list(light = false, filters: any = {}): Promise<any> {
    let data = {
      //auth: this.userService.getCurrentUser(),
      skip: true,
      light: light,
      filters: filters
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'verycar_list', data).toPromise();
  }



  getDamageCost(id): Promise<any> {
    let data = {
      id: id,
    }

    return this.http.post(this.URL_BASE + 'verycar_getDamageCost', data).toPromise();
  }
  retrieveCoupon(id) {
    let data = {
      id: id,
    }

    return this.http.post(this.URL_BASE + 'verycar_retrieve_coupon', data).toPromise();
  }
  getStripeCheckout(price, customerid) {
    let data = {
      price: price,
      customerid: customerid
    }
    return this.http.post(this.URL_BASE + 'verycar_get_stripe_checkout', data).toPromise();
  }
  getStripeCheckoutOneshot(price, customerid, suppl, coupon) {
    let data = {
      price: price,
      customerid: customerid,
      suppl: suppl,
      coupon: coupon
    }
    return this.http.post(this.URL_BASE + 'verycar_get_stripe_checkout_oneshot', data).toPromise();
  }

  getCheckoutSession(cs) {
    let data = {
      id: cs,
    }
    return this.http.post(this.URL_BASE + 'verycar_get_checkout_session', data).toPromise();
  }


  getStripeCustomerPortal(customerid) {
    let data = {
      customerid: customerid
    }
    return this.http.post(this.URL_BASE + 'verycar_get_stripe_customer_portal', data).toPromise();
  }
  updateStripeCustomer(id) {
    let data = {
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_update_stripe_customer', data).toPromise();
  }


  callPayment(amount, token, idprofile) {

    let data = {
      amount: amount * 100,
      token: token,
      profile: idprofile
    }
    return this.http.post(this.URL_BASE + 'verycar_callPayment', data).toPromise();
  }

  callPaymentSource(profile, pmid) {
    let data = {
      profile: profile,
      pmid: pmid,

    }
    return this.http.post(this.URL_BASE + 'verycar_callPaymentSource', data).toPromise();
  }

  createCustomer(profile) {
    let data = {
      profile: profile,
    }
    return this.http.post(this.URL_BASE + 'verycar_createCustomer', data).toPromise();
  }


  getCustomer(id) {
    let data = {
      id: id,
    }
    return this.http.post(this.URL_BASE + 'verycar_getCustomer', data).toPromise();
  }


  deleteSubscription(id) {
    let data = {
      id: id,
    }
    return this.http.post(this.URL_BASE + 'verycar_deleteSubscription', data).toPromise();
  }

  updateCustomer(id, pmid) {
    let data = {
      id: id,
      pmid: pmid
    }
    return this.http.post(this.URL_BASE + 'verycar_updateCustomer', data).toPromise();
  }

  searchPlace(type, place) {
    let data = {
      type: type,
      place: place
    }
    return this.http.post(this.URL_BASE + 'verycar_get_nearest_location', data).toPromise();
  }

  verycar_loc_search(query: string) {

    let data = {
      query: query
    }


    return this.http.post(this.URL_BASE + 'verycar_loc_search', data).toPromise()

  }

  verycar_photo_request(photo: any) {

    let data = {
      photo: photo
    }


    return this.http.post(this.URL_BASE + 'verycar_photo_request', data).toPromise()

  }


  verycar_loc_detail_search(id: any) {

    let data = {
      id: id
    }


    return this.http.post(this.URL_BASE + 'verycar_loc_detail_search', data).toPromise()

  }


  verycar_email_data(id: string, date) {

    let data = {
      id: id,
      date: date
    }

    return this.http.post(this.URL_BASE + 'verycar_email_data', data).toPromise()
  }



  etax_list_marche(tipo): Promise<any> {
    let data = {
      auth: 'skip',
      tipo_veicolo: tipo
    }

    return this.http.post(this.URL_BASE + 'etax_list_marche', data).toPromise();
  }

  etax_list_anni(marca: string, tipo_veicolo: string): Promise<any> {
    let data = {
      auth: 'skip',
      tipo_veicolo: tipo_veicolo,
      marca: marca
    }

    return this.http.post(this.URL_BASE + 'etax_list_anni', data).toPromise();
  }

  etax_list_modelli(marca: string, anno: string, tipo_veicolo: string): Promise<any> {
    let data = {
      auth: 'skip',
      marca: marca,
      anno: anno,
      tipo_veicolo: tipo_veicolo
    }

    return this.http.post(this.URL_BASE + 'etax_list_modelli', data).toPromise();
  }

}




export interface IVeryhomeFilters {

  typeVendita?: string

  indirizzo?: string,
  riscaldamento?: string,

  superficie_min?: number,
  superficie_max?: number,

  prezzo_min?: number,
  prezzo_max?: number,

  anno_min?: number,
  anno_max?: number,

  nLocali_min?: number,
  nLocali_max?: number,

  nCamereLetto_min?: number,
  nCamereLetto_max?: number,

  nBagni_min?: number,
  nBagni_max?: number,

  classeEnergetica?: string,
  cucina?: string,

  lat: number
  lon: number

}export interface IVeryboatFilters {

  typeVendita?: string


  lat: number
  lon: number

}