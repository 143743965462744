import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class AlprService {


  //private key: string = ''
  private keyI: IALPRKey[]
  private baseurl: string = 'https://api.openalpr.com/v2/recognize_bytes?'

  private getUrl(country: string, key: string): string {
    var s = this.baseurl + 'recognize_vehicle=1'
      + '&country=' + country
      + '&secret_key=' + key;

    return s;
  }

  constructor(public http: HttpClient, public db: AngularFirestore) {

    console.log('ALPR init');
    this.getBestKey();

  }

  async getBestKey() {
    console.log('trying to get alpr key')

    await this.getBestALPRKey().valueChanges().subscribe(
      data => {
        console.log(data);
        this.keyI = data;
        console.log('key selected -> credits: ',);
      }
    )

    console.log('here')
  }

  async recognizeVehicle(imageBase64: string, country: string): Promise<IALPRResponse> {

    console.log('keys', KeyUtil)
    /**  if(!this.keyI){
          alert("no alpr key");
          return
        } */


    console.log('recognizeVehicle')
    return new Promise<IALPRResponse>((resolve, reject) => {

      let url = this.getUrl(country, KeyUtil);

      console.log(url)

      return this.http.post(url, imageBase64)

        .subscribe((data: IALPRResponse) => {

          console.log(data)
          /** this.keyI[0].credits_monthly_total = data.credits_monthly_total
                    this.keyI[0].credits_monthly_used = data.credits_monthly_used
          
                    this.updateALPRKey(this.keyI[0])*/


          resolve(data)
        },

          err => reject(err)

        );
    });
  }



  //////////////////////////////// FIREBASE //////////////////////////////////////

  getBestALPRKey(): AngularFirestoreCollection<IALPRKey> {
    return this.db.collection<IALPRKey>(`alprkeys`,
      ref => ref.where('active', '==', true).orderBy('credits_monthly_available', 'desc'))

  }

  updateALPRKey(key: IALPRKey): Promise<void> {

    key.credits_monthly_available =
      key.credits_monthly_total - key.credits_monthly_used

    let x = this.db.doc<IALPRKey>(`alprkeys/${key.key}`).set(key, { merge: true })
    this.getBestKey();
    return x;

  }
}

///////////////////////////////////////////////////

export const KeyUtil = 'sk_01083172e5cf883c1b23e6d4'

export interface IALPRKey {
  key: string
  active: boolean
  credits_monthly_total: number
  credits_monthly_used: number
  credits_monthly_available?: number
}

export interface IALPRResponse {
  uuid: string;
  data_type: string;
  epoch_time: number;
  processing_time: ProcessingTime;
  img_height: number;
  img_width: number;
  results: Result[];
  credits_monthly_used: number;
  version: number;
  credits_monthly_total: number;
  error: boolean;
  regions_of_interest: RegionsOfInterest[];
  credit_cost: number;
}

interface ProcessingTime {
  total: number;
  plates: number;
  vehicles: number;
}

interface VehicleRegion {
  y: number;
  x: number;
  height: number;
  width: number;
}

interface Candidate {
  matches_template: number;
  plate: string;
  confidence: number;
}

interface Coordinate {
  y: number;
  x: number;
}

interface Orientation {
  confidence: number;
  name: string;
}

interface Color {
  confidence: number;
  name: string;
}

interface Make {
  confidence: number;
  name: string;
}

interface BodyType {
  confidence: number;
  name: string;
}

interface Year {
  confidence: number;
  name: string;
}

interface MakeModel {
  confidence: number;
  name: string;
}

interface Vehicle {
  orientation: Orientation[];
  color: Color[];
  make: Make[];
  body_type: BodyType[];
  year: Year[];
  make_model: MakeModel[];
}

interface Result {
  plate: string;
  confidence: number;
  region_confidence: number;
  vehicle_region: VehicleRegion;
  region: string;
  plate_index: number;
  processing_time_ms: number;
  candidates: Candidate[];
  coordinates: Coordinate[];
  vehicle: Vehicle;
  matches_template: number;
  requested_topn: number;
}

interface RegionsOfInterest {
  y: number;
  x: number;
  height: number;
  width: number;
}

