import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shield-result',
  templateUrl: './shield-result.component.html',
  styleUrls: ['./shield-result.component.scss'],
})
export class ShieldResultComponent implements OnInit {

  @Input() item
  @Input() img = '/assets/imgs/Logo.svg'

  constructor() { }

  ngOnInit() { }
  
}
