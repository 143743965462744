import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';

import { AngularFireStorage } from 'angularfire2/storage';

import MarkerClusterer from '@googlemaps/markerclustererplus'
import { platform } from 'os';

// import * as html2canvas from "html2canvas";
declare let html2canvas: any;

declare var google: any;
declare var geoXML3: any;
//declare var MarkerClusterer:any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  loading = true
  loading_types = false
  loading_states = false
  value: any = {}

  // filter_type = 'radius'
  filter_types = [
    { type: 'radius', descr: 'per raggio', icon: 'locate-outline' },
    { type: 'filter', descr: 'per località', icon: 'map-outline' },
  ]

  @Input() height = '40vh'

  @Input() mode = 'new'
  @Input() position: any
  @Output() onCompleted: EventEmitter<any> = new EventEmitter();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  @Output() onFilterRadiusChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('map', { static: true }) public mapRef: ElementRef;

  _list = []
  markers = []
  cluster

  @Input('list') set setList(list) {

    if (!this.map) return;

    this.clearMarkers()

    //console.clear()
    console.log('setList')
    this._list = JSON.parse(JSON.stringify(list))




    for (const item of this._list) {

      console.log(item)
      let price = this._decimalPipe.transform(item.price, '.0-0', 'IT_it');


      let img = '/assets/imgs/Logo.svg'
      try {
        img = item.immagini[0] && this.attachs != undefined ? this.getAttach(item.immagini[0]).content : '/assets/imgs/Logo.svg'

      } catch (error) {
        console.log(error)
      }

      let imgPresent = img != '/assets/imgs/Logo.svg'

      let infowindow = new google.maps.InfoWindow({
        content: `
        <p>
        <br>
          <span>${item.tipo}</span> - <span>${item.indirizzo.toUpperCase()}</span>
          <br>
          <span>${item.superficie} m&#178;</span>  <span style="float:right; font-weigth:bold;">${price} €</span>
        </p>
        <img  
          src="${img}" 
          style="width:100%; max-height:200px; margin: 0 auto 0  ;padding-bottom: 0;  padding-top: 0; object-fit: ${imgPresent ? 'cover' : 'contain'} ">
        `
      });

      console.log(infowindow)

      /*

    'Villetta a schiera',

      */

      let icon = getHomeIcon(item.tipologia)

      let marker = new google.maps.Marker({
        position: { lat: +item.lat, lng: +item.lon },
        map: this.map,
        title: ``,
        icon: {
          url: icon, scaledSize: new google.maps.Size(50, 50)
        },
      });

      marker.addListener('click', () => {
        console.log('marker click')
        infowindow.open(this.map, marker);
      });

      this.markers.push(marker)
    }

    this.cluster = new MarkerClusterer(this.map, this.markers, {
      styles: [{
        height: 53,
        url: "/assets/imgs/pointers/scudo.svg",
        width: 53,
        className: "custom-clustericon-1",
      }],
      gridSize: 30,
    });
  }

  clearMarkers() {
    if (this.cluster) this.cluster.clearMarkers();
    this.markers = []
    for (const marker of this.markers) {
      marker.setMap(null)
    }


  }

  clearAllZones() {

    if (this.mode == 'radius') {

      for (const e of this.filters) {
        console.log(e)
        this.removeFilter(e)
        //filter.zone.setMap(null)
      }

      this.filters = []
    }

    if (this.mode == 'filter')
      this.center = null
    this.clearMarkers()

    if (this.centerMarker) this.centerMarker.setMap(null)
    if (this.centerCircle) this.centerCircle.setMap(null)


  }

  filters = []

  geoXml = null;
  geocoder = null;
  toggleState = 1;
  infowindow = null;
  marker = null;
  map = null
  parser = null
  gpolygon = null
  zones = null
  address = ''

  types: string[]
  type = ''

  states: string[]
  state = ''

  result: any

  center
  centerMarker
  radius = 5000
  centerCircle


  constructor(
    private db: AngularFirestore,
    private toastCtrl: ToastController,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private _decimalPipe: DecimalPipe,
    public platform: Platform,
  ) { }

  ngOnInit() {


    setTimeout(() => {
      this.initialize(true)
    }, 200);

  }

  initialize(first = false) {

    this.loading = true

    this.map = null

    this.geocoder = new google.maps.Geocoder();
    this.infowindow = new google.maps.InfoWindow({ size: new google.maps.Size(150, 50) });
    // create the map
    console.clear()
    console.log(this.position)

    var myOptions = {
      zoom: 11,
      center: new google.maps.LatLng(
        this.position ? this.position.lat : 45,
        this.position ? this.position.lon : 9),

      mapTypeControl: false,
      mapTypeControlOptions: { style: google.maps.MapTypeControlStyle.DROPDOWN_MENU },
      navigationControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      streetViewControl: false,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }]


        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#F5DBC0" }]
        },
      ]

    }

    this.map = new google.maps.Map(this.mapRef.nativeElement, myOptions);
    this.geoXml = new geoXML3.parser({ map: this.map, singleInfoWindow: true, infoWindow: this.infowindow });

    if (first) this.loading = false



    this.map.addListener("click", async (res) => {

      console.log('map click')

      this.clearAllZones()

      if (this.mode == 'filter') {


        let url = `https://nominatim.openstreetmap.org/reverse?lat=${res.latLng.lat()}&lon=${res.latLng.lng()}&polygon_geojson=1&format=geojson&zoom=10`

        let x: any = await this.http.get(url).toPromise()
        let city = x.features[0].properties.name

        console.clear()

        if (this.map.getZoom() >= 12)
          if (bigcities.find(e => city.toLowerCase() == e.toLowerCase())) {

            url = `https://nominatim.openstreetmap.org/reverse?lat=${res.latLng.lat()}&lon=${res.latLng.lng()}&polygon_geojson=1&format=geojson&zoom=14`

            x = await this.http.get(url).toPromise()
            city += ` (${x.features[0].properties.name})`
          }

        console.log(x.features[0].properties.name)

        let data = new google.maps.Data();
        data.addGeoJson(x);
        data.setStyle({
          fillColor: "#7777ff",
          strokeWeight: 1,
          strokeColor: "#7777ff",
          strokeOpacity: 0.8,
          fillOpacity: 0.35,
        });

        console.log(data)

        let f = { city: city, data: data, zone: x }
        this.filters.push(f)


        data.addListener("click", (res) => {

          console.log('remove zone click')
          this.removeFilter(f)

        })

        // create some layer control logic for turning on data1
        data.setMap(this.map) // or restyle or whatever

        console.log(this.filters)

        this.onFilterChange.emit(this.filters)

        // turn off data1 and turn on data2
      }

      if (this.mode == 'radius') {

        this.clearAllZones()

        this.center = res.latLng
        this.map.setCenter(res.latLng)
        this.centerMarker = new google.maps.Marker({
          map: this.map,
          position: res.latLng,
        });

        this.refreshCircleFilter()

      }

    });

  }

  refreshCircleFilter() {


    console.log('refreshCircleFilter')

    if (this.centerCircle) this.centerCircle.setMap(null)

    let c = {
      strokeWeight: 1,
      strokeColor: "#7777ff",
      fillColor: "#7777ff",
      strokeOpacity: 0.8,
      fillOpacity: 0.35,
      map: this.map,
      center: this.center,
      radius: this.radius,
    }

    console.log(c)

    this.centerCircle = new google.maps.Circle(c);

    console.log(this.center)

    if (this.center)
      this.onFilterRadiusChange.emit({ center: { lat: this.center.lat(), lon: this.center.lng() }, radius: this.radius })

  }

  removeFilter(f) {
    f.data.setMap(null)
    this.filters = this.filters.filter(e => e.city != f.city)
    console.log(this.filters)
    this.onFilterChange.emit(this.filters)
  }

  features: any = {}

  prov
  showAddress(address = this.address) {

    this.types = undefined
    this.type = ''

    this.states = undefined
    this.state = ''



    this.initialize()


    this.value.address = address

    this.geocoder.geocode({ 'address': address }, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {

        console.log(results[0])

        this.value.geocode = results[0]

        let address1 = this.value.geocode.address_components.find(e => e.types.includes('administrative_area_level_3'))
        let address2 = this.value.geocode.address_components.find(e => e.types.includes('route'))
        let address3 = this.value.geocode.address_components.find(e => e.types.includes('number'))

        this.value.address = (address1 ? address1.long_name + '' : '') + (address1 && address2 ? ', ' : '') + (address2 ? address2.long_name + ' ' : '') + (address3 ? address3.long_name : '')
        this.address = this.value.address


        var point = results[0].geometry.location;

        this.map.setCenter(point);
        if (this.marker && this.marker.setMap) this.marker.setMap(null);
        this.marker = new google.maps.Marker({
          map: this.map,
          position: point,
        });



        let r = results[0].address_components.find(e => e.types.find(e1 => e1 == "administrative_area_level_3")).long_name.toUpperCase()
        this.prov = results[0].address_components.find(e => e.types.find(e1 => e1 == "administrative_area_level_2")).short_name.toUpperCase()


        console.log(r)

        let s = this.db.collection('valore_immobili', ref2 => ref2
          .where('Comune_descrizione', '>=', r)
          .where('Comune_descrizione', '<', r + 'Z')

        ).valueChanges().subscribe((res: any[]) => {

          res = res.filter(e => e.Prov == this.prov)


          this.loadKML(point, res[0] ? res[0].Comune_amm : undefined)

          s.unsubscribe()
        })



      } else {
        this.loading = false
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  loadKML(point, idComune) {

    if (!idComune) {
      this.loading = false
      alert('Attenzione, impossibile trovare il comune nel database, provare a digitarlo in modo diverso')
      return
    }

    this.value.idComune = idComune

    this.parser = null //clear all
    this.parser = new geoXML3.parser({
      suppressInfoWindows: true,
      map: this.map,
      afterParse: (docs) => {

        console.log(docs)

        this.findZone(docs, point)

      }
    });


    let s1 = this.storage.ref(`/kmls/${idComune}.kml`).getDownloadURL().subscribe(url => {
      console.log(url)
      try {
        this.http.get(url, { responseType: 'arraybuffer' }).toPromise()
          .then(res => {

            let s = new TextDecoder().decode(res);

            if (s && s != '') {
              //console.log(s)
              this.parser.parseKmlString(s)

            } else {
              alert("attenzione, file KML non trovato per il comune selezionato")
              this.loading = false
              return
            }

          })
          .catch(err => {
            alert("attenzione, file KML non trovato per il comune selezionato")
            console.error(err)
            this.loading = false
            return
          })
      } catch (error) {
        this.loading = false
      }
      s1.unsubscribe()
    })


  }


  findZone(docs, point) {


    var contentString = this.address + "<hr>";
    //contentString += "<br>" + point;

    this.gpolygon = null

    console.log(docs)
    for (var i = 0; i < docs[0].gpolygons.length; i++) {

      let b = docs[0].gpolygons[i].Contains(point)
      console.log(`${i} ${b}`)

      if (b) {
        contentString = this.address + "<br>" + docs[0].placemarks[i].name;

        this.gpolygon = docs[0].gpolygons[i]


        this.loadSelect()

        break;

        i = 999; // Jump out of loop
      }
    }

    if (!this.gpolygon) alert("Attenzione, nessun'area trovata per questo indirizzo")


    google.maps.event.addListener(this.marker, 'click', () => {
      this.infowindow.setContent(contentString);
      this.infowindow.open(this.map, this.marker);
    });
    google.maps.event.trigger(this.marker, "click");

    this.loading = false
  }


  cacheImgs: any = {}
  attachs = []
  getAttach(id) {

    try {
      return this.attachs.find(e => e.id == id)
    } catch (error) {

    }

    //  console.log(this.attachs)
  }



  city
  zone

  loadSelect() {

    let wh = this.gpolygon.title.split(' - Zona OMI ')

    console.log(wh)

    this.city = wh[0]
    this.zone = wh[1]




    this.value.city = this.city
    this.value.zone = this.zone

    console.log(wh)

    let s = this.db.collection('valore_immobili', ref2 => ref2
      .where('Comune_descrizione', '==', this.city)

    ).valueChanges().subscribe((res: any[]) => {

      console.log(res)
      res = res.filter(e => e.Prov == this.prov && e.Zona == this.zone)

      this.zones = res


      this.types = this.zones.map(e => e.Descr_Tipologia)
        .filter(e => ALLOWEED_TYPES.includes(e.toLowerCase()))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => { return a > b ? 1 : -1 })

      console.log(this.types)
      console.log(this.zones)

      s.unsubscribe()

    })

  }

  typeChange(event) {
    console.log(this.type)

    this.loading_types = true

    this.value.type = this.type

    let s = this.db.collection('valore_immobili', ref2 => ref2
      .where('Comune_descrizione', '>=', this.city)
      .where('Zona', '==', this.zone)
      .where('Descr_Tipologia', '==', this.type)

    ).valueChanges().subscribe((res: any[]) => {

      console.clear()
      console.log(res)

      res = res.filter(e => e.Prov == this.prov)

      //this.states = res.filter(e => e.Stato_prev == 'P').map(e => e.Stato).filter((v, i, a) => a.indexOf(v) === i)

      res = res.filter(e => e.Prov == this.prov)
      console.log(res)

      this.result = res[0]

      this.value.valore = this.result

      s.unsubscribe()
      this.loading_states = false

      if (this.result === undefined) {
        alert("Attenzione, zona OMI non trovata per questa ricerca")
        return
      }

      this.onCompleted.emit(this.value)



    })


  }




  /*
    stateChange(event) {
      console.log(this.state)
      this.value.state = this.state
   
      this.loading_states = true
   
      let s = this.db.collection('valore_immobili', ref2 => ref2
        .where('Comune_descrizione', '>=', this.city)
        .where('Zona', '==', this.zone)
        .where('Descr_Tipologia', '==', this.type)
        .where('Stato', '==', this.state)
   
      ).valueChanges().subscribe((res: any[]) => {
   
        res = res.filter(e => e.Prov == this.prov)
        console.log(res)
   
        this.result = res[0]
   
        this.value.valore = this.result
   
   
        this.onCompleted.emit(this.value)
        this.loading_states = false
   
        s.unsubscribe()
   
      })
   
   
    }
  */

  getSizeLg() {

    let ncol = 1 + (this.types ? 1 : 0) + (this.states ? 1 : 0)

    return 12 / ncol

  }

  pinFormatter() {

    let r = this.radius
    if (r >= 1000) {
      return r / 1000 + 'Km'
    }

    return r + 'm'
  }

}


const ALLOWEED_TYPES = [
  'ville e villini',
  'abitazioni civili'
]

const bigcities = [
  'Milano',
  'Roma',
  'Napoli',
  'Venezia',
]


export const getHomeIcon = (tipologia) => {
  let icon
  switch (tipologia.toLowerCase()) {
    case 'casa indipendente':
    case 'villa':
      icon = 'villa'
      break

    case 'loft':
    case 'attico':
      icon = 'attico'
      break

    case 'mansarda':
      icon = 'mansarda'
      break

    case 'rustico / casale':
      icon = 'casale'
      break

    case 'villetta a schiera':
      icon = 'villette_schiera'
      break

    case 'appartamento':
      icon = 'appartamento'
      break

    default:
      icon = 'villa'

  }


  return '/assets/imgs/pointers/' + icon + '.svg'
}