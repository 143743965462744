import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthProviderService } from './services/auth-provider.service';

@Injectable()
export class LoginRouteGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthProviderService) { }

    canActivate() {

        console.log('start LoginRouteGuard guard')
        let x = this.auth && this.auth.authState && this.auth.authState.email && this.auth.authState.uid
        console.log('x: ' + x)
        /*
                alert(JSON.stringify(this.auth))
                alert(JSON.stringify(this.auth.authState))
                alert(JSON.stringify(this.auth.authState.email))
                alert(JSON.stringify(this.auth.authState.uid))
        */

        console.log(window.location.href)

        if (x) {
            return true
        } else {
            console.log('Access denied, redirecting to login')
            this.router.navigate(['login'])
            return false
        }

    }


}
/////////////////////////////////////////////////////////////////////
@Injectable()
export class NotLoginRouteGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthProviderService) { }

    canActivate() {

        console.log('start NotLoginRouteGuard guard')
        let x = this.auth && this.auth.authState && this.auth.authState.email && this.auth.authState.uid && this.auth.authState.emailVerified

        if (!x) {
            return true
        } else {
            console.log('Access denied, redirecting to home')
            this.router.navigate([''])
            return false
        }

    }



}


@Injectable()
export class SelectorGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {

        let s = sessionStorage.getItem('selected')
        if (!s) {
            this.router.navigate(['selector'])
            return false
        }else {
            
            return true
        }

    }



}


