import { Component, OnInit, Input } from '@angular/core';
import { AuthProviderService } from 'src/app/services/auth-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from 'angularfire2/firestore';
import { AlertController, ActionSheetController, PopoverController, ModalController, NavParams, Platform } from '@ionic/angular';
import { IUser } from '../login/login.page';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { HttpClient } from '@angular/common/http';
import { MysqlService } from 'src/app/services/mysql.service';
import { AppVersion } from '@ionic-native/app-version/ngx'
import { LocalesService } from 'src/app/services/locales.service';
import { ModalBusinessPage } from '../modal-business/modal-business.page';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';



declare var Stripe;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  profile: IProfile;
  s
  tel
  subscriptions
  ver
  park

  constructor(private db: AngularFirestore,
    private auth: AuthProviderService,
    private route: ActivatedRoute,
    private router: Router,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public popoverController: PopoverController,
    public modalController: ModalController,
    private screen: ScreenOrientation,
    private appVersion: AppVersion,
    private platform: Platform,
    private ls: LocalesService,
    private mysql: MysqlService,
    private iab: InAppBrowser
  ) {

    if (platform.is('capacitor')) this.screen.lock(this.screen.ORIENTATIONS.PORTRAIT);
    console.log(this.router.url)
    switch (this.router.url) {
      case '/boat/nautica-tabs/profile':
        this.park = 'nautica';
        break;
      case '/home/home-tabs/profile':
        this.park = 'home';
        break;
      default:this.park ='auto'
    }
    this.loadVersion()
    this.loadSubscriptions()
  }

  loadSubscriptions() {
    this.db.collection('subscriptions').valueChanges().subscribe(res => {
      console.log(res)
      this.subscriptions = res
    })
  }

  async loadVersion() {
    if (this.platform.is('capacitor'))
      this.ver = await this.appVersion.getVersionNumber();
    else
      this.ver = 'serve'
  }

  ngOnInit() {
  }
  ionViewDidEnter() {

    this.loadProfile()

  }

  async verifyNick() {
    const alerta = await this.alertController.create({
      header: 'Modifica il Nickname',
      inputs: [
        {
          name: 'nickname',
          type: 'text',
          placeholder: this.ls.getString('nickname'),
          value: this.profile.nickname
        }
      ],
      buttons: [
        {
          text: this.ls.getString('cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          role: 'ok',
          handler: (nick: any) => {
            // console.log(res);
            let s = this.db.collection('users', ref => ref
              .where('nickname', '==', nick.nickname)).valueChanges().subscribe(res => {
                s.unsubscribe()
                if (res.length == 0) {
                  this.profile.nickname = nick.nickname
                  this.db.collection('users').doc(this.profile.email).set(this.profile, { merge: true })
                  alert('modificato con successo')
                }

                else {

                  alert(this.ls.getString('user_nickname'))
                  // return false
                }
              })
          }
        }
      ]
    });


    await alerta.present();

  }

  loadProfile() {
    this.s = this.db.collection('users', ref1 => ref1
      .where('email', '==', this.auth.authState.email)

      //result
    ).valueChanges().subscribe((res1: IProfile[]) => {
      console.log(res1)
      this.profile = res1[0]

    })
  }
  logout() {
    this.auth.afAuth.auth.signOut();
    this.auth.authState = undefined
    this.router.navigate(['login'])
  }

  async openMod() {
    const alert = await this.alertController.create({
      header: 'Modifica i dati',
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: this.ls.getString('first_name'),
          value: this.profile.firstname
        },
        {
          name: 'lastname',
          type: 'text',
          placeholder: this.ls.getString('last_name'),
          value: this.profile.lastname
        },
        {
          name: 'city',
          type: 'text',
          placeholder: this.ls.getString('city'),
          value: this.profile.city
        },
        {
          name: 'address',
          type: 'text',
          placeholder: this.ls.getString('address'),
          value: this.profile.address
        },

        {
          name: 'zipcode',
          type: 'text',
          placeholder: this.ls.getString('zip_code'),
          value: this.profile.zipcode
        },
        {
          name: 'prov',
          type: 'text',
          placeholder: this.ls.getString('prov'),
          value: this.profile.prov
        },

        {
          name: 'vatid',
          type: 'text',
          placeholder: this.ls.getString('piva'),
          value: this.profile.vatid
        },
        {
          name: 'fiscalcode',
          type: 'text',
          placeholder: this.ls.getString('fiscal_code'),
          value: this.profile.fiscalcode
        },
        {
          name: 'tel',
          type: 'text',
          placeholder: this.ls.getString('phone_number'),
          value: this.profile.tel
        },
        {
          name: 'pec',
          type: 'text',
          placeholder: this.ls.getString('pec'),
          value: this.profile.pec
        },
        {
          name: 'einv',
          type: 'text',
          placeholder: this.ls.getString('einv'),
          value: this.profile.einv
        },



      ],
      buttons: [
        {
          text: this.ls.getString('cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          role: 'ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });


    await alert.present();
    alert.onDidDismiss().then(res => {
      console.log(res)
      if (res.role == 'ok') {
        let val = res.data.values
        let t = {
          address: val.address,
          city: val.city,
          email: this.profile.email,
          firstname: val.name,
          fiscalcode: val.fiscalcode,
          lastname: val.lastname,
          tel: val.tel,
          vatid: val.vatid,
          zipcode: val.zipcode,
          einv: val.einv,
          pec: val.pec,
          prov: val.prov

        }
        this.db.collection('users').doc(this.profile.email).set(t, { merge: true })

      }

    }).catch(error => console.log(error))



  }

  //await this.auth.afAuth.auth.currentUser.
  async changePassword() {

    var at = this.auth.afAuth.auth
    console.log('Change Password Button Clicked');
    //Creating the promt alert with inputs
    const alerta = await this.alertController.create({
      header: this.ls.getString('change_password'),
      inputs: [
        {
          name: 'oldPassword',
          placeholder: this.ls.getString('old_password'),
          type: 'password'
        },
        {
          name: 'newPassword',
          placeholder: this.ls.getString('new_password'),
          type: 'password'
        },
        {
          name: 'newPasswordConfirm',
          placeholder: this.ls.getString('confirm_new_password'),
          type: 'password'
        }

      ],
      buttons: [
        {
          text: this.ls.getString('cancel'),
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: this.ls.getString('update_password'),
          handler: data => {
            if (data.newPassword == data.newPasswordConfirm && data.newPassword != data.oldPassword)
              // Prompt the user to re-provide their sign-in credentials
              at.signInWithEmailAndPassword(at.currentUser.email, data.oldPassword).then(res => {
                at.currentUser.updatePassword(data.newPassword).then(res => {
                  alert(this.ls.getString('update_password_success'));
                }).catch(error => {
                  alert(error + this.ls.getString('update_password_error'))
                })
              })
                .catch(error => {
                  alert(this.ls.getString('old_password_wrong'))
                })
            else
              alert(this.ls.getString('new_password_wrong'))
          }
        }
      ]
    });
    await alerta.present();
  }




  async presentModalPrivacy() {
    const modal = await this.modalController.create({
      component: ModalPrivacy,
      componentProps: {
        profile: this.profile,
      }
    });
    return await modal.present();
  }

  async deleteAccount() {

    if (this.profile.type == 'business') {
      alert(this.ls.getString('warning_cancel_subscription_required'))
      return
    }

    const alerta = await this.alertController.create({
      header: this.ls.getString('confirm_account_deletion'),
      subHeader: this.ls.getString('confirm_account_deletion_ext'),//,
      message: this.ls.getString('type_password_to_continue'),//,
      inputs: [{
        name: 'password',
        placeholder: 'Password',
        type: 'password'
      },],
      buttons: [{
        text: this.ls.getString('cancel'),
        role: 'cancel',
        handler: () => {
          console.log("niente")
        }
      }
        , {
        text: 'Ok',
        role: 'delete',
        handler: (res) => {
          this.auth.afAuth.auth.signInWithEmailAndPassword(this.auth.afAuth.auth.currentUser.email, res.password).then(res => {
            this.deleteAllVerycarFromUser(this.profile.email)

            this.db.doc('users/' + this.profile.email).delete()

            this.auth.afAuth.auth.currentUser.delete().then(res => {

              alert(this.ls.getString('user_deleted')) ///'Utente eliminato con successo'
              this.logout()

            }).catch(error => alert(this.ls.getString('error_try_later'))) //'Abbiamo riscontrato un errore, riprova più tardi'))
          }).catch(error => alert(this.ls.getString('wrong_password'))) //'La password inserita non è corretta'


        },
      }]
    });

    await alerta.present();
  }

  deleteAllVerycarFromUser(email) {
    this.db.collection('verycar', ref1 => ref1
      .where('email', '==', email)).valueChanges().subscribe((res1: any[]) => {
        console.log(res1)
        let ids = res1
        ids.forEach(element => {
          this.db.collection('verycar').doc(element.id).delete()
        });


      })
  }
  async showTel(type) {
    if (type) {
      const alert = await this.alertController.create({

        header: this.ls.getString('confirm'),
        message: this.ls.getString('confirm_show_phone'),//'Accettando dai il consenso a mostrare il tuo numero di telefono nei tuoi annunci al fine di essere contattato',
        buttons: [
          {
            text: this.ls.getString('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Ok',
            handler: () => {
              console.log("change")
              this.db.collection('users').doc(this.profile.email).set({
                showTel: true
              }, { merge: true })
            }
          }
        ]
      });
      alert.onDidDismiss().then(() => {
        this.router.navigate(['tabs/profile'])
      })
      await alert.present();
    } else {

      const alert = await this.alertController.create({

        header: this.ls.getString('confirm'),
        message: this.ls.getString('confirm_hide_phone'),// 'Vuoi nascondere il tuo numero di telefono?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Okay',
            handler: () => {
              console.log("change")
              this.db.collection('users').doc(this.profile.email).set({
                showTel: false
              }, { merge: true })
            }
          }
        ]
      });
      alert.onDidDismiss().then(() => {
        this.router.navigate(['tabs/profile'])
      })
      await alert.present();

    }



  }

  async strumentibusiness() {

    /*** 
    const modal = await this.modalController.create({
      component: AbbonamentoReportModal,
      componentProps: {
        profile: this.profile,
      }
    });
    return await modal.present();
*/

    this.mysql.getStripeCustomerPortal(this.profile.stripeid).then((res: any) => {
      console.log(res)
      const options = 'location=no,zoom=no,hideurlbar=yes,hidenavigationbuttons=yes,closebuttoncaption=Clicca qui per tornare alla app,closebuttoncolor=#f04141';
      const browser = this.iab.create(res.url.url, '_blank', options);
      browser.on('loaderror').subscribe(event => {
        browser.hide();
        this.mysql.updateStripeCustomer(this.profile.stripeid).then((res: any) => {
          console.log(res)
          if (res.status == 'ok') {
            if (res.res.data.length == 0) {
              this.profile.nVeh = 1
              this.profile.type = 'standard'
              this.profile.abbonamento = ''
              this.db.collection('users').doc(this.profile.email).set(this.profile, { merge: true })


            }


          } else {
            console.log(res)

          }

        })
      })
    })
    /** 
    
*/
  }

  async openBusinessAlert() {
    if ((this.profile.vatid == '' || !this.profile.vatid) && (this.profile.fiscalcode == '' || !this.profile.fiscalcode)) {
      alert(this.ls.getString('alert_not_vat'))
      return
    }
    const alerta = await this.alertController.create({
      header: this.ls.getString('get_subscription_business'),

      message: `<img src="assets/imgs/drawing.svg" alt="g-maps" style="border-radius: 2px"><br><br><p style="text-align: center!important;">` + this.ls.getString("get_subscription_business_descr") + `</p>`,
      buttons: [
        {
          text: this.ls.getString('get_plan'),
          handler: () => {
            this.openBusinessModal()
          }
        },
      ],
    });

    await alerta.present();
  }

  async openBusinessModal() {
    this.router.navigate(['modal-business'])
  }


  async openPrivacy() {
    const actionSheet = await this.actionSheetController.create({
      header: this.ls.getString('privacy_check'),
      buttons: [{
        text: this.ls.getString('conditions'),//'Trattamento dei dati personali',
        handler: () => {
          this.presentModalPrivacy()

        }

      }, {
        text: this.ls.getString('delete_Account'),
        role: 'destructive',
        cssClass: 'centered',
        handler: () => {
          this.deleteAccount()
        },

      },
      {
        text: this.ls.getString('back'),

        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }





}

export interface IProfile {
  address: string,
  city: string,
  email: string,
  firstname: string,
  fiscalcode: string,
  lastname: string,
  password: any,
  password1: any,
  vatid: string,
  zipcode: string,
  admin?: boolean,
  tel: string,
  showTel: boolean,
  type?: string,
  stripeid?: string;
  nVeh?: number,
  abbonamento?: string,
  nickname?: string,
  einv?: string,
  pec?: string,
  prov?: string
}

@Component({
  selector: 'app-privacy',
  template: ` 
  
  <ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <h2 style="text-align:center">
      {{'conditions'|traslation}}
</h2>
  
  </ion-toolbar>
</ion-header>
  <ion-content>

    <ion-list >

          
   

          <ion-item>
            <p>{{ 'ok_personal_data' | traslation }}</p> <!-- Acconsento al trattamento dei dati personali -->
            <ion-checkbox  [(ngModel)]='profile.trattamento' color="primary" checked disabled slot="start"></ion-checkbox>
          </ion-item>
          <ion-item>
            <p>{{ 'ok_privacy' | traslation }}</p> <!-- Acconsento al trattamento dei dati personali -->
            <ion-checkbox  [(ngModel)]='profile.privacy' color="primary" checked disabled slot="start"></ion-checkbox>
          </ion-item>
          <ion-item>
            <p>{{ 'no__offerts' | traslation }} <!-- Desidero non ricevere più comunicazioni che siano inerenti a servizi analoghi a quelli utilizzati da me--></p> 
            <ion-checkbox  [(ngModel)]='profileMod.comunicazioni' color="primary"  slot="start"></ion-checkbox>
          </ion-item>
          <ion-item>
            <p>{{ 'ok_offerts' | traslation }} <!-- Acconsento al trattamento dei miei dati personali al fine di ricevere offerte e cominicazioni commerciali da parte di Verycar --></p>
            <ion-checkbox [(ngModel)]='profileMod.offerte' color="primary"  slot="start"></ion-checkbox>
          </ion-item>
          <ion-item>
            <p>{{ 'ok_profiling' | traslation }} <!-- Acconsento alla profilazione dei miei dati personali --></p>
            <ion-checkbox [(ngModel)]='profileMod.profilazione' color="primary"  slot="start"></ion-checkbox>
          </ion-item>
          
         
        </ion-list>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button expand="block" shape="round" (click)="ok()">Ok</ion-button>
            </ion-col>
            <ion-col>
              <ion-button expand="block" shape="round" (click)="cancel()">{{ 'back' | traslation }}</ion-button>
            </ion-col>
</ion-row>
        </ion-grid>
 

  </ion-content>

      `,




})

export class ModalPrivacy {

  // Data passed in by componentProps


  @Input() profile: IUser;
  profileMod = {
    offerte: false,
    comunicazioni: false,
    profilazione: false
  }
  constructor(private modalCtrl: ModalController, navParams: NavParams, private auth: AuthProviderService, private db: AngularFirestore) {
    // componentProps can also be accessed at construction time using NavParams
    //console.log(navParams.get('extra'));




  }
  ngOnInit() {
    console.log(this.profile)
    this.profileMod.offerte = this.profile.offerte[this.profile.offerte.length - 1]
    this.profileMod.comunicazioni = this.profile.comunicazioni[this.profile.comunicazioni.length - 1]
    this.profileMod.profilazione = this.profile.profilazione[this.profile.profilazione.length - 1]
  }


  cancel() {
    this.modalCtrl.dismiss();
  }

  ok() {
    ///TEST
    if (!this.profile.trattamentoDate)
      this.profile.trattamentoDate = new Date()
    if (!this.profile.privacyDate)
      this.profile.privacyDate = new Date()


    //NON TEST
    if (this.profile.offerteDate.length != 0) {
      this.profile.offerteDate.push(new Date())
      this.profile.offerte.push(this.profileMod.offerte)
    } else {
      this.profile.offerteDate = [new Date()]
      this.profile.offerte = [this.profileMod.offerte]
    }

    if (this.profile.profilazioneDate.length != 0) {
      this.profile.profilazioneDate.push(new Date())
      this.profile.profilazione.push(this.profileMod.profilazione)
    } else {
      this.profile.profilazioneDate = [new Date()]
      this.profile.profilazione = [this.profileMod.profilazione]
    }

    if (this.profile.comunicazioniDate.length != 0) {
      this.profile.comunicazioniDate.push(new Date())
      this.profile.comunicazioni.push(this.profileMod.comunicazioni)
    } else {
      this.profile.comunicazioniDate = [new Date()]
      this.profile.comunicazioni = [this.profileMod.comunicazioni]
    }



    this.db.collection('users').doc(this.profile.email).set(this.profile, { merge: true })
    console.log("registrato")
    this.modalCtrl.dismiss(this.profile)
  }



}





@Component({
  selector: 'app-abbonamento',
  template: ` 
 <ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title *ngIf="mode == 'pay'" >
      {{'get_subscription_with_stripe'|traslation}}
    </ion-title>
    <ion-title *ngIf="mode == 'update'" >
    {{'change_credit_card'|traslation}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="welcome-card">
  
    <ion-card-header>
      <ion-card-title>  {{'insert_credit_card_data'|traslation}}</ion-card-title>
      <ion-card-subtitle *ngIf="mode == 'pay'" style="padding: 0;
    text-align: center;">  {{'total'|traslation}}: {{cost}}€ / {{'month'|traslation}}
  </ion-card-subtitle>
    </ion-card-header>
    
    <ion-card-content >
      <form action="/" method="post" id="payment-form">
        <div >
          <div id="card-element"> <!-- a Stripe Element will be inserted here. --> </div>
          <!-- Used to display Element errors -->
          <div id="card-errors" role="alert"></div>
        </div>
        <ion-button  *ngIf="mode == 'pay' && !wait" type="submit" color="success" expand="full">{{'pay'|traslation}}</ion-button>
        <ion-button *ngIf="mode == 'update && !wait'" type="submit" color="success" expand="full">{{'update'|traslation}}</ion-button>
        <ion-button  *ngIf="!wait" expand="full" (click)="cancel()">{{'back'|traslation}}</ion-button>
        <ion-spinner *ngIf="wait"></ion-spinner>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
      `,




})

export class StripeAbbonamentoModal {

  // Data passed in by componentProps
  stripe = Stripe('pk_test_O712DLrtAIxFGDhORm2ysJgx00u4Q5s5mH');
  card: any;
  profile: IUser;
  @Input() cost: any
  @Input() mode: any
  @Input() oldModal: ModalController
  wait = false
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    navParams: NavParams,
    private auth: AuthProviderService,
    private db: AngularFirestore,
    private http: HttpClient,
    private mysql: MysqlService,
    private alertCtrl: AlertController,
    private ls: LocalesService) {
    // componentProps can also be accessed at construction time using NavParams
    //console.log(navParams.get('extra'));




  }
  s
  ngOnInit() {
    this.setupStripe();
    this.loadProfile()
  }
  loadProfile() {
    this.s = this.db.collection('users', ref1 => ref1
      .where('email', '==', this.auth.authState.email)

      //result
    ).valueChanges().subscribe((res1: IProfile[]) => {
      console.log(res1)
      this.profile = res1[0]

    })
  }

  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style });
    console.log(this.card);
    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();
      console.log(event)
      this.wait = true
      this.stripe.createSource(this.card).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          console.log(result);
          let pm = this.stripe
            .createPaymentMethod({
              type: 'card',
              card: this.card,
            })
            .then((result) => {
              if (result.error) {
                console.log(result.error);
              } else {

                if (this.mode == 'pay')
                  this.makePayment(result.paymentMethod.id);
                if (this.mode == 'update')
                  this.updatePayment(result.paymentMethod.id)
              }
            });

        }
      });
    });
  }
  makePayment(pmid) {

    console.log(pmid)
    this.wait = true
    this.mysql.callPaymentSource(this.profile, pmid).then(res => {
      alert(this.ls.getString('subscription_success'))
      this.profile.type = 'business'
      this.db.collection('users').doc(this.profile.email).set(this.profile, { merge: true })
      this.wait = false
      this.modalCtrl.dismiss()

    }
    ).catch(err => {
      console.log(err)
      this.wait = false
      this.modalCtrl.dismiss();
    })
  }

  updatePayment(pmid) {
    this.wait = true
    this.mysql.updateCustomer(this.profile.stripeid, pmid).then(res => {
      alert(this.ls.getString('credit_card_changed_success'))
      this.modalCtrl.dismiss();
    }).catch(err => {
      console.log(err)
      this.wait = false
      this.modalCtrl.dismiss();
    })
  }



  cancel() {
    this.modalCtrl.dismiss();
  }



}


@Component({
  selector: 'app-abbonamento-report',
  template: ` 
 <ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title style="text-align:center">
      {{'subscription_details'|traslation}}
    </ion-title>
  
  </ion-toolbar>
</ion-header>

<ion-content>

  <h2 style="text-align:center"> {{'payment_methods'|traslation}}</h2>
    <ion-list>
    <span style="padding-left:35%">Carta pagamento</span>

      <ion-item> 
      <ion-icon name="card"></ion-icon> 
      <p>************{{card?.last4}}</p>
      <p> - scad. {{card?.exp_month}}/{{card?.exp_year}}</p>
    </ion-item>
    <ion-item>

    

      <ion-button style="width:100%" (click)="changeCard()" expand="block">{{'change_credit_card'|traslation}}</ion-button>
    </ion-item>
    <ion-item>
      <ion-button style="width:100%" (click)="cancel()" expand="block">{{'back'|traslation}}</ion-button>
    </ion-item>
    <p (click)="disiscrivi()"
      style="color: red; text-align: center; width: -webkit-fill-available; font-weight: bold;text-decoration: underline;">
      {{'cancel_subscription'|traslation}}
    </p>
</ion-list>
</ion-content>
      `,




})

export class AbbonamentoReportModal {

  // Data passed in by componentProps
  stripe = Stripe('pk_test_O712DLrtAIxFGDhORm2ysJgx00u4Q5s5mH');
  customer: any
  card: any
  @Input() profile: IUser;

  constructor(
    private modalCtrl: ModalController,
    navParams: NavParams,
    private auth: AuthProviderService,
    private db: AngularFirestore,
    private http: HttpClient,
    private mysql: MysqlService,
    private alertCtrl: AlertController,
    private ls: LocalesService
  ) {
    // componentProps can also be accessed at construction time using NavParams
    //console.log(navParams.get('extra'));




  }

  ngOnInit() {
    this.loadUser()
  }
  loadUser() {
    this.mysql.getCustomer(this.profile.stripeid).then((res: any) => {
      console.log(res)
      this.customer = res.data
      this.card = res.pm.card
    })
  }

  async disiscrivi() {
    const alerta = await this.alertCtrl.create({
      header: this.ls.getString('cancel_subscription_confirm'),//"Vuoi davvero eliminare l'abbonamento ad account business?",
      subHeader: this.ls.getString('cancel_subscription_confirm_text'),//'Le rate già pagate non verranno rimborsate',

      buttons: [{
        text: this.ls.getString('no'),//'No',
        role: 'cancel',
        handler: () => {
          console.log("niente")
        }
      }
        , {
        text: this.ls.getString('yes'),//'Si',
        role: 'delete',
        handler: (res) => {
          console.log(this.customer)
          this.mysql.deleteSubscription(this.customer.subscriptions.data[0].id).then(res => {
            console.log(res)
            this.profile.nVeh = 1
            this.profile.type = 'default'
            this.db.collection('users').doc(this.profile.email).set(this.profile, { merge: true })

            alert(this.ls.getString('cancel_subscription_success'),//"Iscrizione eliminata"
            )
            this.modalCtrl.dismiss();
          })

        },
      }]
    });

    await alerta.present();
  }










  async changeCard() {
    const modal = await this.modalCtrl.create({
      component: StripeAbbonamentoModal,
      componentProps: {
        profile: this.profile,
        mode: 'update'
      }
    });
    this.modalCtrl.dismiss();
    return await modal.present();
  }





  cancel() {
    this.modalCtrl.dismiss();
  }



}






